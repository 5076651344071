import { render, staticRenderFns } from "./Signature.vue?vue&type=template&id=628af2ac&"
import script from "./Signature.vue?vue&type=script&lang=js&"
export * from "./Signature.vue?vue&type=script&lang=js&"
import style0 from "./Signature.vue?vue&type=style&index=0&id=628af2ac&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VBtn,VCard,VCheckbox,VCol,VDialog,VDivider,VFlex,VIcon,VImg,VOverlay,VProgressCircular,VRow,VSnackbar})


/* hot reload */
if (module.hot) {
  var api = require("/home/fared/kowamas-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('628af2ac')) {
      api.createRecord('628af2ac', component.options)
    } else {
      api.reload('628af2ac', component.options)
    }
    module.hot.accept("./Signature.vue?vue&type=template&id=628af2ac&", function () {
      api.rerender('628af2ac', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/view/pages/form/new/Signature.vue"
export default component.exports