<template
>
  <div
    class="pb-6"
  >
    <v-card
      class="pa-2"
      height="auto"
      elevation="0"
    >
      <!--
      <div class="pa-3">
        <div class="alert alert-custom alert-light-primary fade show" role="alert">
          <div class="alert-icon">
            <span class="svg-icon svg-icon-4x svg-icon-primary">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24" />
                  <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                  <rect fill="#000000" x="11" y="10" width="2" height="7" rx="1" />
                  <rect fill="#000000" x="11" y="7" width="2" height="2" rx="1" />
                </g>
              </svg>
            </span>
          </div>

          <div class="alert-text primary--text font-weight-bold">
            SILA LENGKAPKAN BORANG INI DENGAN MAKLUMAT YANG BENAR
            <div v-if="this.currentUser.phone_verified != true">
              <br />
              Perkara penting sebelum meneruskan permohonan:
              <ol>
                <li>Pastikan No. Kad Pengenalan anda adalah betul DAN milik anda</li>
                <li>Masukkan No. Telefon bimbit anda yang aktif SERTA perlu disahkan</li>
                <li>Masukkan Alamat E-Mel anda yang aktif SERTA perlu disahkan</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      -->

      <AlertActionable
        v-model="alert1.show"
        :alertMessage="alert1.message"
        :alertHtml="alert1.html"
        :alertType="alert1.type"
        :outlined="alert1.outlined"
        :dismissable="alert1.dismissable"
        :button1Caption="alert1.button1Caption"
        :button2Caption="alert1.button2Caption"
        :button3Caption="alert1.button3Caption"
        @onAction1="alert1.onAction1"
        @onAction2="alert1.onAction2"
        @onAction3="alert1.onAction3"
      ></AlertActionable>

      <ValidationObserver
        ref="validateFormUserInfo"
      >
        <v-form
          class="font-weight-bold font-size-sm"
        >
          <v-container
            fluid
          >
            <v-row>
              <!-- <template v-if="true"> -->
                <v-col
                  cols="12"
                  lg="12"
                  md="12"
                  sm="12"
                >
                  <h5
                    class="primary--text font-weight-bold"
                  >
                    Maklumat Identifikasi Bagi Tujuan Pengesahan
                  </h5>
                  <v-divider
                    class="mt-0"
                  ></v-divider>
                </v-col>

                <v-col
                  cols="12"
                  lg="12"
                  md="12"
                  sm="12"
                >
                  <v-text-field
                    :value="currentUser.nric_display"
                    label="No. Kad Pengenalan :"
                    v-mask="mask_icNum"
                    required="1"
                    dense
                    filled
                    readonly
                    hide-details
                  >
                    <template
                      v-slot:label
                    >
                      <strong
                      >
                        No. Kad Pengenalan MyKAD :
                      </strong>
                    </template>
                  </v-text-field>
                  <!-- filled readonly outlined dense required="1"> -->
                </v-col>

                <!-- <v-col
                    v-if="!this.currentUser.phone_verified"
                    cols="12"
                    lg="6"
                    md="6"
                    sm="6"
                  >
                  <div
                    class="d-flex"
                  >
                    <v-text-field
                      :value="computedUserBirthDate"
                      label="Tarikh Lahir Anda"
                      hint="Tarikh Lahir diperoleh daripada Nombor Kad Pengenalan"
                      @blur="date = parseDate(computedUserBirthDate)"
                      readonly
                      disable
                      filled
                      solo
                    ></v-text-field>
                  </div>
                </v-col> -->

                <v-col
                  v-if="!this.currentUser.phone_verified"
                  cols="12"
                  lg="6"
                  md="6"
                  sm="6"
                >
                  <div
                    class="d-flex"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Telefon Bimbit"
                      rules="required"
                    >
                      <vue-tel-input-vuetify
                        v-model="user.phone_num"
                        label="Telefon Bimbit Utama"
                        placeholder="Telefon Bimbit"
                        hint="Contoh: 010-123 4567 / 011-1234 5678"
                        defaultCountry="MY"
                        :maxlength="13"
                        :background-color="inputColorPhoneNum"
                        :onlyCountries="['MY']"
                        :error-message="errors[0]"
                        :readonly="user.phone_num == null"
                        :autofocus="false"
                        :translations="{
                          countrySelectorLabel: '',
                          countrySelectorError: '',
                          phoneNumberLabel: 'Telefon Bimbit',
                          example: 'Contoh :',
                        }"
                        @input="onPhoneNumInput"
                        dense
                        outlined
                        required
                      ></vue-tel-input-vuetify>
                    </ValidationProvider>
                    <v-btn
                      class="ml-2"
                      color="primary"
                      :disabled="!inputValidPhoneNum"
                      :loading="isLoadingVerifyPhoneNum"
                      @click="verifyOTP()"
                    >
                      Sahkan
                    </v-btn>
                  </div>
                </v-col>

                <v-col
                  v-else
                  cols="12"
                  lg="6"
                  md="6"
                  sm="6"
                >
                  <v-text-field
                    v-model="currentUser.phone_num_display"
                    label="No. Telefon Bimbit :"
                    hint="Nombor telefon ini telah disahkan"
                    append-icon="mdi-check"
                    background-color="green accent-1"
                    :persistentHint="true"
                    readonly
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <!-- <v-col
                    cols="12"
                    lg="6"
                  md="6"
                  sm="12">
                  <span></span>
                </v-col> -->
                <v-col
                  v-if="!this.currentUser.email_verified"
                  cols="12"
                  lg="6"
                  md="6"
                  sm="6"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="E-mel"
                    rules="required"
                  >
                    <div
                      class="d-flex"
                    >
                      <v-text-field
                        v-model="user.email"
                        label="E-Mel :"
                        placeholder="nama@email.com"
                        hint="Contoh: nama@email.com"
                        class="lcase-text"
                        :background-color="inputColorEmailUser"
                        :error-messages="errors"
                        dense
                        outlined
                        required
                      >
                      </v-text-field>
                      <v-btn
                        class="ml-2"
                        color="primary"
                        :loading="isLoadingVerifyEmail"
                        :disabled="!inputValidEmailUser"
                        @click="verifyEmail()"
                      >Sahkan</v-btn>
                    </div>
                  </ValidationProvider>
                </v-col>

                <v-col
                  v-else
                  cols="12"
                  lg="6"
                  md="6"
                >
                  <v-text-field
                    v-model="this.currentUser.email"
                    label="E-Mel :"
                    hint="Alamat E-mel ini telah disahkan"
                    background-color="green accent-1"
                    append-icon="mdi-check"
                    :persistentHint=true
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
              <!-- </template> -->

              <template
                v-if="this.currentUser.phone_verified"
              >
                <v-col
                  cols="12"
                  md="12"
                >
                  <div>
                    <h6
                      class="font-weight-bold"
                    >No. Rujukan: {{ this.formSession.refno }}</h6>
                  </div>
                <!-- {{ json_encode(this.user) }} -->
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                >
                  <h5
                    class="primary--text font-weight-bold"
                  >
                    Maklumat Diri
                  </h5>
                  <v-divider
                    class="mt-0"
                  ></v-divider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="12"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Nama Pemohon"
                    rules="required"
                  >
                    <v-text-field
                      v-model="user.full_name"
                      hint="Nama Pemohon Seperti Tercatat dalam MyKad / Kad Pengenalan"
                      label="Nama Pemohon Seperti Tercatat dalam MyKad / Kad Pengenalan"
                      maxLength="40"
                      :error-messages="errors"
                      @focus="$event.target.select()"
                      class="ucase-text"
                      dense
                      outlined
                      required
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="3">
                  <ValidationProvider v-slot="{ errors }" name="Gelaran" rules="required">
                    <v-select v-model="user.salute_id"
                    :items="salute" item-text="nameMS" item-value="id"
                      :error-messages="errors"
                      label="Gelaran" outlined dense required>
                    </v-select>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="4">
                  <ValidationProvider v-slot="{ errors }" name="Tarikh Lahir" rules="required">
                    <v-text-field
                      :value="computedUserBirthDate"
                      label="Tarikh Lahir" filled outlined readonly disable dense
                      hint="Tarikh Lahir diperoleh daripada Nombor Kad Pengenalan"
                      :error-messages="errors"
                      prepend-icon="mdi-calendar" @blur="
                        date = parseDate(computedUserBirthDate)
                        ">
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="5">
                  <ValidationProvider v-slot="{ errors }" name="Jantina" rules="required">
                    <v-select v-model="user.gender_id"
                    :items="gender" item-text="nameMS" item-value="id"
                      hint="Jantina diperoleh daripada Nombor Kad Pengenalan"
                      :error-messages="errors"
                      label="Jantina" filled outlined readonly disable dense required>
                    </v-select>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="3">
                  <ValidationProvider v-slot="{ errors }" name="Bangsa" rules="required">
                    <v-select v-model="user.race_id"
                    :items="race" item-text="nameMS" item-value="id"
                      :error-messages="errors"
                      label="Bangsa" outlined dense required>
                    </v-select>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="3">
                  <ValidationProvider v-slot="{ errors }" name="Agama" rules="required">
                    <v-select v-model="user.religion_id"
                    :items="religion" item-text="nameMS" item-value="id"
                      :error-messages="errors"
                      label="Agama" outlined dense required>
                    </v-select>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="3">
                  <ValidationProvider v-slot="{ errors }" name="Status Perkahwinan" rules="required">
                    <v-select v-model="user.marital_status_id"
                    :items="maritalStatus" item-text="nameMS" item-value="id"
                      :error-messages="errors"
                      label="Status Perkahwinan" outlined dense required>
                    </v-select>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="3">
                  <ValidationProvider v-slot="{ errors }" name="Bilangan Anak">
                    <v-text-field v-model="user.children"
                    :error-messages="errors"
                    label="Bilangan Anak (jika ada)"
                      outlined dense>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="6">
                  <ValidationProvider v-slot="{ errors }" name="Tahap Pendidikan" rules="required">
                    <v-select v-model="user.education_level_id"
                    :items="educationLevel" item-text="nameMS" item-value="id"
                      :error-messages="errors"
                      label="Tahap Pendidikan" outlined dense required>
                    </v-select>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="6">
                  <ValidationProvider v-slot="{ errors }" name="Nama Ibu" rules="required">
                    <v-text-field v-model="user.mother_name"
                    :error-messages="errors"
                    label="Nama Ibu" class="ucase-text"
                      outlined dense required>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col
                  cols="12"
                  md="12">
                  <h5 class="primary--text font-weight-bold">
                    Maklumat Bank
                  </h5>
                  <v-divider class="mt-0"></v-divider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="6"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Nama Bank"
                    rules="required"
                  >
                    <v-select
                      v-model="user.bankInfo"
                      :value="getBank"
                      :items="banks"
                      :error-messages="errors"
                      item-text="name"
                      item-value="id"
                      label="Nama Bank"
                      return-object
                      v-on:change="onBankAccChange"
                      @input="onBankAccInput"
                      outlined
                      dense
                      required
                    ></v-select>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="6"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="No. Akaun Bank"
                    rules="required"
                  >
                    <v-text-field
                      v-model="user.bank_acc"
                      :counter="selectedBank.max"
                      :maxlength="selectedBank.max"
                      :error-messages="errors"
                      :readonly="isBankAccReadOnly"
                      :placeholder="isBankAccReadOnly ? 'Pilih Nama Bank Dahulu' : 'Masukkan No Akaun bank'"
                      @keydown="filterNonNumeric"
                      @keyup="filterNonNumeric"
                      label="No. Akaun Bank"
                      outlined
                      dense
                      required
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                >
                  <h5
                    class="primary--text font-weight-bold"
                  >
                    Alamat Rumah (Tempat Tinggal Sekarang)
                  </h5>
                  <v-divider
                    class="mt-0"
                  ></v-divider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="12"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Unit / Lot / No. / Blok"
                    rules="required"
                  >
                    <v-text-field
                      v-model="user.home_unit"
                      hint="Contoh: A1-2-3, Blok A, Apartment Villa"
                      label="Unit / Lot / No. / Blok"
                      class="ucase-text"
                      :error-messages="errors"
                      outlined
                      dense
                      required
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="12"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Taman / Apartment / Jalan / Lorong"
                    rules="required"
                  >
                    <v-text-field
                      v-model="user.home_street"
                      hint="Contoh: Jalan Bahagia 1, Kampung Sentosa"
                      label="Taman / Apartment / Jalan / Lorong / Seksyen / Presint / Kawasan"
                      class="ucase-text"
                      :error-messages="errors"
                      outlined
                      dense
                      required
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="12"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Butiran Tambahan 1 (R)"
                    rules=""
                  >
                    <v-text-field
                      v-model="user.home_line3"
                      hint="Masukkan butiran alamat sekiranya ada"
                      label="Butiran Tambahan Sekiranya Ada (tidak wajib)" class="ucase-text"
                      :error-messages="errors"
                      outlined
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="12"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Butiran Tambahan 2 (R)"
                    rules=""
                  >
                    <v-text-field
                      v-model="user.home_line4"
                      hint="Masukkan butiran alamat sekiranya ada"
                      :error-messages="errors"
                      label="Butiran Tambahan Sekiranya Ada (tidak wajib)"
                      class="ucase-text"
                      outlined
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Poskod"
                    rules="required"
                  >
                    <v-text-field
                      v-model="user.home_postcode"
                      v-mask="mask_postCode"
                      hint="55100"
                      label="Poskod"
                      :error-messages="errors"
                      outlined
                      dense
                      required
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="8">
                  <ValidationProvider v-slot="{ errors }" name="Bandar" rules="required|max:30">
                    <v-select v-model="user.home_city_id"
                    :items="cityHomeScoped" item-text="display" item-value="id"
                      item-color="primary"
                      :error-messages="errors" hint="Bandar (mengikut poskod)"
                      label="Bandar (mengikut poskod)" outlined dense required class="ucase-text"
                      v-on:change="onHomeCityChange">
                    </v-select>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="8">
                  <ValidationProvider v-slot="{ errors }" name="Negeri" rules="required">
                    <v-select v-model="user.home_state_id"
                    :items="states" item-text="name" item-value="id"
                      :error-messages="errors"
                      label="Negeri" outlined dense required class="ucase-text"
                      v-on:change="onHomeStateChange">
                    </v-select>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="8">
                  <ValidationProvider v-slot="{ errors }" name="Status Pemilikan Rumah (H)" rules="required">
                    <v-select v-model="user.home_ownership_id"
                    :items="houseOwnership" item-text="nameMS" item-value="id"
                      :error-messages="errors"
                      label="Status Pemilikan Rumah" outlined dense required>
                    </v-select>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="12"
                  style="padding-top: 0px"
                >
                  <v-checkbox
                    v-model="user.mailing_same_home"
                    class="checbox-decoration"
                    label="ALAMAT SURAT MENYURAT SAMA SEPERTI ALAMAT RUMAH DI ATAS"
                    @click="useMainAddress()"
                  ></v-checkbox>
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                  v-show="showMailingAddress"
                >
                  <h5
                    class="primary--text font-weight-bold"
                  >
                    Alamat Surat Menyurat
                  </h5>
                  <v-divider
                    class="mt-0"
                  ></v-divider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="12"
                  v-show="showMailingAddress"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Unit/Lot/No./Tingkat SM"
                    rules="required"
                  >
                    <v-text-field
                      v-model="user.mailing_unit"
                      class="ucase-text"
                      label="Unit/Lot/No./Tingkat"
                      hint="Contoh A1-2-3"
                      :error-messages="errors"
                      outlined
                      dense
                      required
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="12" v-show="showMailingAddress">
                  <ValidationProvider v-slot="{ errors }" name="Jalan/Apartment/Taman SM" rules="required">
                    <v-text-field hint="Jalan Bahagia, Kampung Sentosa" v-model="user.mailing_street"
                      :error-messages="errors" class="ucase-text"
                      label="Jalan / Apartment / Taman / Seksyen / Presint / Kawasan" outlined dense required>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="12" v-show="showMailingAddress">
                  <ValidationProvider v-slot="{ errors }" name="Butiran Tambahan 1 (S)" rules="">
                    <v-text-field hint="Masukkan butiran alamat sekiranya ada" v-model="user.mailing_line3"
                      :error-messages="errors"
                      label="Butiran Tambahan Sekiranya Ada (tidak wajib)" class="ucase-text"
                      outlined dense>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="12" v-show="showMailingAddress">
                  <ValidationProvider v-slot="{ errors }" name="Butiran Tambahan 2 (S)" rules="">
                    <v-text-field hint="Masukkan butiran alamat sekiranya ada" v-model="user.mailing_line4"
                      :error-messages="errors"
                      label="Butiran Tambahan Sekiranya Ada (tidak wajib)" class="ucase-text"
                      outlined dense>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="4" v-show="showMailingAddress">
                  <ValidationProvider v-slot="{ errors }" name="Poskod SM" rules="required">
                    <v-text-field hint="55100" v-model="user.mailing_postcode"
                    :error-messages="errors"
                      v-mask="mask_postCode"
                      label="Poskod" outlined dense required>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="8" v-show="showMailingAddress">
                  <ValidationProvider v-slot="{ errors }" name="Bandar SM" rules="required">
                    <v-select v-model="user.mailing_city_id"
                    :items="cityMailingScoped" item-text="display"
                      item-value="id"
                      :error-messages="errors" hint="Bandar mengikut poskod"
                      label="Bandar" outlined dense
                      required class="ucase-text" v-on:change="onMailingCityChange">
                    </v-select>
                  </ValidationProvider>
                </v-col>

                <v-col
                  v-show="showMailingAddress"
                  class="py-0"
                  cols="12"
                  md="8"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Negeri SM"
                    rules="required"
                  >
                    <v-select
                      v-model="user.mailing_state_id"
                      label="Negeri"
                      class="ucase-text"
                      item-text="name"
                      item-value="id"
                      :items="states"
                      :error-messages="errors"
                      v-on:change="onMailingStateChange"
                      dense
                      outlined
                      required
                    ></v-select>
                  </ValidationProvider>
                </v-col>

                <v-col
                  v-show="showMailingAddress"
                  class="py-0"
                  cols="12"
                  md="8"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Status Pemilikan Rumah (M)"
                    rules="required"
                  >
                    <v-select
                      v-model="user.mailing_ownership_id"
                      item-text="nameMS"
                      item-value="id"
                      :items="houseOwnership"
                      :error-messages="errors"
                      label="Status Pemilikan Rumah"
                      outlined
                      dense
                      required
                    >
                    </v-select>
                  </ValidationProvider>
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                >
                  <h5
                    class="primary--text font-weight-bold"
                  >
                    Telefon Untuk Dihubungi
                  </h5>
                  <v-divider
                    class="mt-0"
                  ></v-divider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="6"
                >
                  <vue-tel-input-vuetify
                    v-model="user.home_num"
                    label="Telefon Rumah"
                    placeholder="Telefon Rumah"
                    hint="Contoh: 03-1234 5678 / 04-123 4567"
                    defaultCountry="MY"
                    :maxlength="13"
                    :onlyCountries="['MY']"
                    :translations="{
                      countrySelectorLabel: '',
                      countrySelectorError: '',
                      phoneNumberLabel: 'Telefon Rumah',
                      example: 'Contoh :',
                    }"
                    :background-color="inputColorHomeNum"
                    @input="onHomeNumInput"
                    dense
                    outlined
                  >
                  </vue-tel-input-vuetify>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="6"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Telefon Pejabat"
                    rules=""
                  >
                    <vue-tel-input-vuetify
                      v-model="user.user_office_num"
                      label="Telefon Pejabat"
                      placeholder="Telefon Pejabat"
                      hint="Contoh: 03-1234 5678 / 04-123 4567"
                      v-mask="mask_phoneLand"
                      defaultCountry="MY"
                      :onlyCountries="['MY']"
                      :error-messages="errors"
                      :translations="{
                        countrySelectorLabel: '',
                        countrySelectorError: '',
                        phoneNumberLabel: 'Telefon Pejabat',
                        example: 'Contoh :',
                      }"
                      :background-color="inputColorUserOfficeNum"
                      @input="onUserOfficeNumInput"
                      dense
                      outlined
                      required
                    >
                    </vue-tel-input-vuetify>
                  </ValidationProvider>
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                >
                  <h5
                    class="primary--text font-weight-bold"
                  >
                    Pembiayaan Yang Ingin Dipohon
                  </h5>
                  <v-divider
                    class="mt-0"
                  ></v-divider>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="6"
                  sm="6"
                  xs="2"
                >
                  <div
                    class="d-flex"
                  >
                    <ValidationProvider
                      v-slot="{ errors, on, attrs }"
                      name="Jumlah Pembiayaan Dipohon"
                      rules="required"
                    >
                      <vuetify-money
                        v-model="user.financing_req_amount"
                        label="Jumlah Pembiayaan Dipohon"
                        v-bind:placeholder="loanAmountPlaceholder"
                        v-bind:valueWhenIsEmpty="reqMoneyWhenEmpty"
                        v-bind:properties="moneyProperties"
                        v-bind:options="loanOpt"
                        v-bind:error-messages="errors"
                        v-bind:disabled="moneyDisabled"
                        v-bind:outlined="moneyOutlined"
                        v-bind:required="moneyDense"
                        v-bind:readonly="true"
                        v-bind:dense="moneyDense"
                        :errorMessages="errors"
                      />
                        <!-- v-bind:rules="moneyRules" -->
                        <!-- :errorMessages="errors" -->
                    </ValidationProvider>
                    <v-btn
                      class="ml-2"
                      color="primary"
                      @click="openCalculator()"
                    >
                      Kalkulator
                    </v-btn>
                  </div>
                </v-col>

                <v-col
                  class="py-0"
                  cols="12"
                  md="12"
                  sm="12"
                  xs="12">
                  <h6
                    class="primary--text font-weight-bold"
                  >
                    <i
                    >
                      (Pengiraan Kasar - tertakluk kepada perubahan)
                    </i>
                  </h6>

                  <div
                    class="d-flex"
                  >
                    <vuetify-money
                      v-model="user.financing_req_inst"
                      class="mr-2"
                      :dense="moneyDense"
                      :error-messages="errors"
                      v-bind:label="loanInstLabel"
                      v-bind:placeholder="loanAmountPlaceholder"
                      v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                      v-bind:options="loanOpt2"
                      v-bind:properties="moneyProperties"
                      v-bind:disabled="moneyDisabled"
                      v-bind:outlined="moneyOutlined"
                      v-bind:readonly="true"
                    />

                    <vuetify-money
                      v-model="user.financing_req_tenure"
                      class="mr-2"
                      :dense="moneyDense"
                      :error-messages="errors"
                      v-bind:label="loanTenureLabel"
                      v-bind:placeholder="loanAmountPlaceholder"
                      v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                      v-bind:options="tenure"
                      v-bind:properties="moneyProperties"
                      v-bind:disabled="moneyDisabled"
                      v-bind:outlined="moneyOutlined"
                      v-bind:readonly="true"
                    />

                    <vuetify-money
                      v-model="user.financing_req_rate"
                      class="mr-2"
                      :dense="moneyDense"
                      :error-messages="errors"
                      v-bind:label="loanRateLabel"
                      v-bind:placeholder="loanAmountPlaceholder"
                      v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                      v-bind:options="percent"
                      v-bind:properties="moneyProperties"
                      v-bind:disabled="moneyDisabled"
                      v-bind:outlined="moneyOutlined"
                      v-bind:readonly="true"
                    />
                  </div>
                </v-col>
              </template>
            </v-row>
          </v-container>
        </v-form>
      </ValidationObserver>
    </v-card>

    <div
      align="right"
      class="pr-4 pl-2 pb-2 pt-10"
    >
      <v-btn
        v-if="this.currentUser.phone_verified"
        class="ml-4"
        color="primary"
        :loading="isLoading"
        @click="proceedStep()"
      >
        Teruskan
        <v-icon
          right
          dark
        >
          mdi-chevron-right
        </v-icon>
      </v-btn>

      <v-btn
        v-else
        class="ml-4"
        disabled
      >
        Teruskan
        <v-icon
          right
          dark
        >
          mdi-chevron-right
        </v-icon>
      </v-btn>

    </div>

    <v-snackbar
      v-model="snackbar.visible"
      :color="snackbar.color"
      top
    >
      <p
        class="snackbar-subtitle mb-0"
      >
        {{ snackbar.message }}
      </p>
    </v-snackbar>

    <OTPPrompt
      v-model="msgOTP.show"
      :params="msgOTP"
      @onFinishOTP="onFinishOTP"
    ></OTPPrompt>
    <!-- :notILMS="isTelnoNotILMS" -->

    <CalculatorDialog
      v-model="openCalculatorDialog"
      title="Kira Jumlah Layak Memohon"
      :calculatedResult="calculatedResult"
      :formId="calcParamFormID"
      :salary="calcParamSalary"
      :deductions="calcParamDeductions"
      :employerTypesId="calcParamEmpTypeId"
      :employerTypes="calcParamEmpType"
      :preCalc="calcParamPreCalc"
      :preData="financingData"
      @onGetResult="saveCalcResult"
      @calculate="calculate"
    ></CalculatorDialog>

    <InfoMsgDialog
      v-model="messageDialog.show"
      :title="messageDialog.title"
      :modal="messageDialog.modal"
      :content="messageDialog.content"
      :backButton="messageDialog.backButton"
      :proceedButton="messageDialog.proceedButton"
      :showBackButton="messageDialog.showBackButton"
      :showProceedButton="messageDialog.showProceedButton"
      :delayOnProceed=false
      @onBacking="messageDialog.cancel"
      @onProceed="messageDialog.proceed"
    ></InfoMsgDialog>

    <ConfirmationDialog
      v-model="confirmDialog.show"
      :title="confirmDialog.title"
      :content="confirmDialog.content"
      :backButton="confirmDialog.backButton"
      :proceedButton="confirmDialog.proceedButton"
      :showBackButton="confirmDialog.showBackButton"
      :showProceedButton="confirmDialog.showProceedButton"
      @onCancel="confirmDialog.cancel"
      @onProceed="confirmDialog.proceed"
    ></ConfirmationDialog>

    <ErrorMsgDialog
      v-model="flagErrMsgDlg"
      title="Ralat"
      proceedButton="OK"
      :content="msgERR.message"
      :showProceedButton="true"
      :showBackButton="false"
      :delayOnProceed="false"
      :isTypeError="true"
    ></ErrorMsgDialog>

    <v-overlay
      :value="overlay"
    >
      <v-progress-circular
        size="64"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

  </div>
</template>

<script>
import {
  mapGetters,
} from "vuex";

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

import {
  required,
  email,
  max,
  numeric,
} from "vee-validate/dist/rules";

import {
  // nric_dob,
  // nric_gender,
  sanitize_nric,
  extractInfoNRIC,
  blankUser_old,
  blankMember,
  convUpperCase,
  errorMessages,
  emailFormatCheck,
  filterObjects,
  isMapObject,
} from "@/core/services/helper.service";

import {
  GET_USER,
  PURGE_ALL_USER,
} from "@/core/services/store/user.module";

import {
  LOGOUT,
} from "@/core/services/store/auth.module";

import {
  // GET_LATEST_FORM_SESSION,
  // GET_LATEST_EMPLOYMENT_FORM_SESSION,
  DELETE_FORM_SESSION,
  CREATE_MEMBER_SESSION,
  UPDATE_MEMBER_SESSION2,
  UPDATE_FINANCING_SESSION,
  SET_EMPLOYMENT_FORM_SESSION,
  SET_FINANCING_FORM,
  GET_CHECKLIST_FULL,
  PURGE_ALL_FORM,
} from "@/core/services/store/form.module";

import {
  SEND_OTP,
  VERIFY_OTP_PHONE,
} from "@/core/services/store/otp.module";

import {
  CHECK_TELNO_AVAILABILITY,
  CHECK_EMAIL_AVAILABILITY,
} from "@/core/services/store/helper.module";

import {
  CALCULATE,
  SET_CALC_RESULT,
} from "@/core/services/store/calculator.module";

import {
  PURGE_ALL_CHECKLIST,
} from "@/core/services/store/mediaupload.module";

import {
  SEND_VERIFY_EMAIL,
} from "@/core/services/store/notification.module";

import dayjs from "dayjs";
import KTUtil from "@/assets/js/components/util";
import OTPPrompt from "@/view/pages/widgets/OTPPrompt.vue";
import VuetifyMoney from "@/view/pages/vuetify/VuetifyMoney.vue";
import CalculatorDialog from "@/view/pages/widgets/CalculatorDialog.vue";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} tidak boleh dibiarkan kosong",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("email", {
  ...email,
  message: "E-mel mestilah sah",
});

extend("numeric", {
  ...numeric,
  message: "Nombor sahaja dibenarkan",
});

export default {
  name: "PersonalDetails",

  components: {
    VuetifyMoney,
    ValidationObserver,
    ValidationProvider,
    CalculatorDialog,
    OTPPrompt,
  },

  watch: {
    propSignature: function (newValue, oldValue) {
      //nfh-console.log("[WATCH] propSignature");
      this.signature = newValue;
    },

    formSession: function (newValue, oldValue) {
      //nfh-console.log("[WATCH] formSession");
      if (newValue?.id) {
        this.formId = newValue.id;
        //nfh-console.log("[WATCH] formSession: newValue, this.formId: ", newValue, this.formId);
      }
    },

    'user.gender_id': function(newValue, oldValue) {
      //nfh-console.log("[WATCH] user.gender_id");
      this.salute = filterObjects(JSON.parse(localStorage.getItem("sal")), { gender_id: newValue });
      this.user.salute_id = (this.salute.length == 1)
        ? this.salute[0].id
        : ((!!this.salute.find((el) => el['gender_id'] == this.user.salute_id))
          ? this.user.salute_id
          : null);
    },

    'user.bankInfo': function(newValue, oldValue) {
      //nfh-console.log("[WATCH] user.bankInfo");
      if (String(this.user?.bank_acc).trim().length > newValue.max) {
        //nfh-console.log("[WATCH] user.bankInfo: " + String(this.user?.bank_acc).trim().length + " > " + newValue.max);
        this.user.bank_acc = "";
      }
    }

  },

  props: {
    propFormId: {
      type: Number,
      default: 0,
    },

    propSignature: {
      type: String,
      default: "",
    },

    errors: {
      type: [Array, String],
      default: () => []
    },
  },

  data() {
    return {
      formId: null,
      stepNo: 0,
      salary: 0,
      // errors: "",

      user: blankUser_old(),
      city: null,
      race: [],
      banks: [],
      gender: [],
      salute: [],
      states: [],
      religion: [],
      // relations: [],
      employerTypes: [],
      maritalStatus: [],
      houseOwnership: [],
      educationLevel: [],
      // occupationTypes: [],
      // cityHeirScoped: [],
      cityHomeScoped: [],
      // cityOfficeScoped: [],
      cityMailingScoped: [],

      overlay: true,
      isLoading: false,
      // isOTPNotILMS: false,
      proceedButton: false,
      flagErrMsgDlg: false,
      flagInfoMsgDlg: false,
      // showMailingAddress: true,
      isLoadingVerifyEmail: false,
      openCalculatorDialog: false,
      isLoadingVerifyPhoneNum: false,
      // useAddressAsMailingAddress: false,
      moneyDense: true,
      moneyReadOnly: false,
      moneyDisabled: false,
      moneyOutlined: true,

      signature: "",
      reqMoneyWhenEmpty: "",
      moneyWhenEmpty: "0.00",
      loanAmountLabel: "Jumlah Pembiayaan Dipohon",
      loanTenureLabel: "Tempoh (Bulan)",
      loanRateLabel: "Kadar (%)",
      loanInstLabel: "Ansuran Bulanan",
      loanAmountPlaceholder: "RM 1,000 ~ RM 100,000",
      loanAmountHint: "Minimum RM 1,000 & Maksima RM 100,000",

      forward: {
        next: null,
      },

      confirmDialog: {
        show: false,
        title: "",
        content: "",
        showBackButton: false,
        showProceedButton: false,
        backButton: "",
        proceedButton: "",
        cancel: () => { },
        proceed: () => { },
      },

      messageDialog: {
        show: false,
        title: "",
        content: "",
        modal: false,
        showBackButton: false,
        showProceedButton: false,
        backButton: "",
        proceedButton: "",
        isTypeError: false,
        cancel: () => { },
        proceed: () => { },
      },

      errorDialog: {
        show: false,
        title: "",
        content: "",
        showBackButton: false,
        showProceedButton: false,
        backButton: "",
        proceedButton: "",
        isTypeError: false,
        cancel: () => { },
        proceed: () => { },
      },

      msgERR: {
        run: 0,
        nric: "",
        message: "",
      },

      msgINFO: {
        run: 0,
        nric: "",
        message: "",
        proceed: () => { },
      },

      // moneyProperties: {
      //   error: true,
      //   rules: [
      //     "required"
      //   ],
      //   // "error-messages": this.errors,
      // },

      moneyRules: [
        "required",
      ],

      loanOpt: {
        locale: "ms-MY",
        prefix: "RM",
        suffix: "",
        length: 9,
        precision: 0,
        min: 1000,
        max: 1000000,
      },

      loanOpt2: {
        locale: "ms-MY",
        prefix: "RM",
        suffix: "",
        length: 9,
        precision: 2,
        min: 1000,
        max: 200000,
      },

      percent: {
        locale: "ms-MY",
        prefix: "",
        suffix: "%",
        length: 5,
        precision: 2,
        min: 0,
        max: 100,
      },

      tenure: {
        locale: "ms-MY",
        prefix: "",
        suffix: "BULAN",
        length: 2,
        precision: 0,
        min: 12,
        max: 240,
      },

      calculator: {
        total: 0,
      },

      selectedBank: {
      },

      phone: {
        phone_num: {
          number: "",
          valid: false,
          mobile: false,
          country: undefined,
          color: "white",
        },
        home_num: {
          number: "",
          valid: false,
          mobile: true,
          country: undefined,
          color: "white",
        },
        user_office_num: {
          number: "",
          valid: false,
          mobile: true,
          country: undefined,
          color: "white",
        },
      },

      msgOTP: {
        show: false,
        message: "",
        nric: "",
        // run: 0,
      },

      snackbarSignature: {
        visible: false,
        icon: "mdi-information",
        title: "Peringatan",
        text: "Pastikan tandatangan anda diisi sebelum meneruskan proses selajutnya",
      },

      snackbar: {
        visible: false,
        message: "",
        color: ""
      },

      mask_icNum: "######-##-####",
      mask_postCode: "#####",
      mask_phoneLand: "##-########",
      mask_phoneMobile: "###-########",

      inputColorOK: "cyan lighten-5",
      inputColorNG: "red lighten-4",
      exceptionList: ["email", "employer_email", "financing_calc", "signature"],

      timeoutId: null,
    };
  },

  computed: {
    ...mapGetters([
      "formSession",
      "currentUser",
      "currentUserRoles",
      "calculatedResult",
      "memberFormSession",
      "employmentFormSession",
    ]),

    calcParamFormID() {
      return this.formSession.id;
    },

    calcParamSalary() {
      return this.formSession?.emp?.salary;
    },

    calcParamDeductions() {
      return this.formSession?.financing_req_deds * 1;
    },

    calcParamEmpTypeId() {
      return this.formSession?.emp?.employer_type_id * 1;
    },

    calcParamEmpType() {
      return this.employerTypes;
    },

    calcParamPreCalc() {
      return this.formSession?.financing_calc;
    },

    moneyProperties() {
      return {
        error: true,
        rules: [
          "required"
        ],
        // "error-messages": this.errors,
        // errorMessages: this.errors,
      };
    },

    alert1() {
      return {
        show: true,
        type: "info",
        message: "<strong>SILA LENGKAPKAN BORANG INI DENGAN MAKLUMAT YANG BENAR DAN TERKINI</strong>",
        html: this.currentUser.phone_verified != true
          ? `
            <br/>
            Perkara penting sebelum meneruskan permohonan:
            <ol>
              <li>
                Pastikan No. Kad Pengenalan anda adalah betul DAN milik anda
              </li>
              <li>
                Masukkan No. Telefon bimbit milik anda yang aktif, SERTA perlu disahkan
              </li>
              <li>
                Masukkan Alamat E-Mel milik anda yang aktif, SERTA perlu disahkan
              </li>
            </ol>
          `
          : "",
        outlined: true,
        button1Caption: this.currentUser.phone_verified ? "Kosongkan Borang" : "",
        button2Caption: "Batal Permohonan",
        onAction1: this.currentUser.phone_verified ? this.clearForm : () => { },
        onAction2: this.cancelForm,
        onAction3: () => { },
        // dismissable: true,
      };
    },

    financingData() {
      return {
        deds: this.formSession?.financing_req_deds,
        inst: this.formSession?.financing_req_inst,
        rate: this.formSession?.financing_req_rate,
        amount: this.formSession?.financing_req_amount,
        tenure: this.formSession?.financing_req_tenure,
      };
    },

    getBank() {
      if (this.banks) {
        if (this.formSession && this.formSession.banks_id) {
          this.banks.find((_bank) => {
            if (_bank?.id == this?.formSession?.banks_id) {
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.selectedBank = _bank;
              //nfh-console.log("this.selectedBank: ", this.selectedBank);
              return true;
            }
          }, this);
          return this.selectedBank.id;
        } else if (this.user && this.user.banks_id) {
          var _found = this.banks.find((_bank) => {
            if (_bank.id == this?.user?.banks_id) {
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.selectedBank = _bank;
              //nfh-console.log("this.selectedBank: ", this.selectedBank);
              return true;
            }
          }, this);
          //nfh-console.log("this.selectedBank: found ", _found === undefined ? null : this.selectedBank.id);
          return _found === undefined ? null : this.selectedBank.id;
        }
      }
      //nfh-console.log("this.selectedBank: ", this.selectedBank, null);
      return null;
    },

    computedUserBirthDate() {
      return this.user.birth_date
        ? dayjs(this.user.birth_date).format("DD/MM/YYYY")
        : "";
    },

    inputValidEmailUser() {
      return emailFormatCheck(String(this.user.email).trim().toLowerCase());
    },

    inputColorEmailUser() {
      return this.inputValidEmailUser ? this.inputColorOK : this.inputColorNG;
    },

    inputValidPhoneNum() {
      return this.phone.phone_num.valid;
    },

    inputColorPhoneNum() {
      return this.phone.phone_num.color;
    },

    inputColorHomeNum() {
      return this.phone.home_num.color;
    },

    inputColorUserOfficeNum() {
      return this.phone.user_office_num.color;
    },

    appsExists() {
      return (this.currentUser?.stats?.apps ?? 0) > 0;
    },

    cancelReturnDestination() {
      return this.appsExists ? "/dashboard" : "/login";
    },

    isBankAccReadOnly() {
      return Object.keys(this.user?.bankInfo).length > 0 ? false : true;
    },

    showMailingAddress() {
      return !!!this.user.mailing_same_home;
    },

    validationErrorList() {
      var _ret = [];

      if (this.$refs.validateFormUserInfo.errors && isMapObject(this.$refs.validateFormUserInfo.errors)) {
        Object.values(this.$refs.validateFormUserInfo.errors).forEach(_el => {
          if (_el.length > 0) {
            _ret.push(_el[0]);
          }
        });
      }
      return _ret;
    },

    // isTelnoNotILMS() {
    //   return !!this.isOTPNotILMS;
    // },

    // otpOptions() {
    //   return !!this.isOTPNotILMS ? ['notilms'] : [];
    // },

  },

  methods: {
    async init() {
      //nfh-console.log(' IN: INIT');

      if (this.formSession.id) {
        let _copyHomeAddr = !!this.formSession.mailing_same_home;

        this.user.refno                = this.formSession.refno;
        this.user.nric                 = this.currentUser.nric;
        this.user.phone_num            = this.currentUser.phone_num;
        this.user.email                = this.currentUser.email;
        this.user.full_name            = this.currentUser.full_name;
        this.user.birth_date           = this.currentUser.birth_date;
        this.user.race_id              = this.currentUser.race_id;
        this.user.gender_id            = this.currentUser.gender_id;
        this.user.salute_id            = this.formSession.salute_id;
        this.user.religion_id          = this.currentUser.religion_id;
        this.user.marital_status_id    = this.currentUser.marital_status_id;
        this.user.education_level_id   = this.formSession.education_level_id;
        this.user.children             = this.formSession.children;
        this.user.mother_name          = this.currentUser.mother_name;
        this.user.home_unit            = String(this.formSession.home_unit ?? "").trim();
        this.user.home_street          = String(this.formSession.home_street ?? "").trim();
        this.user.home_line3           = String(this.formSession.home_line3 ?? "").trim();
        this.user.home_line4           = String(this.formSession.home_line4 ?? "").trim();
        this.user.home_postcode        = this.formSession.home_postcode;
        this.user.home_city_id         = this.formSession.home_city_id;
        this.user.home_state_id        = this.formSession.home_state_id;
        this.user.home_ownership_id    = this.formSession.home_ownership_id;
        this.user.banks_id             = this.formSession.banks_id;
        this.user.bank_acc             = this.formSession.bank_acc;
        this.user.bank_name            = this.formSession.bank_name;
        this.user.mailing_same_home    = _copyHomeAddr;
        this.user.mailing_unit         = _copyHomeAddr ? String(this.formSession.home_unit ?? "").trim() : String(this.formSession.mailing_unit ?? "").trim();
        this.user.mailing_street       = _copyHomeAddr ? String(this.formSession.home_street ?? "").trim() : String(this.formSession.mailing_street ?? "").trim();
        this.user.mailing_line3        = _copyHomeAddr ? String(this.formSession.home_line3 ?? "").trim() : String(this.formSession.mailing_line3 ?? "").trim();
        this.user.mailing_line4        = _copyHomeAddr ? String(this.formSession.home_line4 ?? "").trim() : String(this.formSession.mailing_line4 ?? "").trim();
        this.user.mailing_postcode     = _copyHomeAddr ? this.formSession.home_postcode : this.formSession.mailing_postcode;
        this.user.mailing_city_id      = _copyHomeAddr ? this.formSession.home_city_id : this.formSession.mailing_city_id;
        this.user.mailing_state_id     = _copyHomeAddr ? this.formSession.home_state_id : this.formSession.mailing_state_id;
        this.user.mailing_ownership_id = _copyHomeAddr ? this.formSession.home_ownership_id : this.formSession.mailing_ownership_id;
        this.user.home_num             = this.formSession.home_num;
        this.user.user_office_num      = this.formSession.user_office_num;
        this.user.financing_req_amount = this.formSession.financing_req_amount;
        this.user.financing_req_tenure = this.formSession.financing_req_tenure;
        this.user.financing_req_rate   = this.formSession.financing_req_rate;
        this.user.financing_req_inst   = this.formSession.financing_req_inst;
        this.user.financing_req_deds   = this.formSession.financing_req_deds;
        this.user.signature            = null;
        this.user.agree_tnc            = false;
        this.salary                    = this.formSession?.emp?.salary;
        this.agree_tnc                 = false; //this.formSession.agree_tnc;
        this.proceedButton             = this.formSession?.sign?.agree_tnc;
        this.applicationForm           = this.formSession.application_form;
      }
      else {
        this.user             = blankUser_old();
        this.formId           = null;
        this.user.nric        = this.currentUser.nric;
        this.user.email       = this.currentUser.email;
        this.user.phone_num   = this.currentUser.phone_num;
        this.user.full_name   = this.currentUser.full_name;
        this.user.race_id     = this.currentUser.race_id;
        this.user.gender_id   = this.currentUser.gender_id;
        this.user.salute_id   = this.currentUser.salute_id;
        this.user.religion_id = this.currentUser.religion_id;
      }

      this.getInitBank();
      this.computedInfoNRICUser();
      this.closeOverlay();

      //nfh-console.log('OUT: INIT');
    },

    onProceedCancelFormMessage(timed) {
      this.$store.dispatch(LOGOUT)
        .then(() => {
          clearTimeout(timed);
          //nfh-console.log("onProceedCancelFormMessage dispatch(LOGOUT).then clearTimeout(timed): ", timed);
          this.closeMessage();
          this.$router.push(this.cancelReturnDestination);
          //nfh-console.log("onProceedCancelFormMessage dispatch(LOGOUT).then pushed route to login");
        })
        .finally(() => {
          clearTimeout(timed);
          //nfh-console.log("onProceedCancelFormMessage dispatch(LOGOUT).finally clearTimeout(timed): ", timed);
          this.closeMessage();
        });
    },

    onProceedClearForm() {
      this.$store.dispatch(DELETE_FORM_SESSION, { id: this.formSession.id })
        .then((_resp) => {
          //nfh-console.log(_resp);

          this.$store.commit(PURGE_ALL_FORM);
          this.$store.commit(PURGE_ALL_CHECKLIST);

          // if (!this.appsExists) {
          //   this.timeoutId = setTimeout(() => {
          //     this.$store.dispatch(LOGOUT)
          //     .then(() => {
          //       this.closeMessage();
          //       this.$router.push(this.cancelReturnDestination);
          //       console.log("onProceedClearForm dispatch(LOGOUT).then pushed route to login");
          //     })
          //     .finally(() => {
          //       this.closeMessage();
          //     });
          //   }, 10000);
          // }

          this.closeConfirmation();

          this.promptMessage(
            `Borang permohonan telah dikosongkan kecuali maklumat tetap peribadi anda.
            <br/>
            ${(this.appsExists ? "Anda boleh mohon kembali pada bila-bila masa. " : "")}
            <br/>Terima kasih`,
            "Kosongkan Borang",
            true,
            () => {
              this.forward.next = "form";
              this.$router.replace({
                // path: this.cancelReturnDestination,
                path: "/fwd",
                query: { next: this.forward.next },
              });
            },
          );
        })
        .catch((err) => {
          //nfh-console.log(err);
        });
    },

    purgeAllStores() {
      this.$store.commit(PURGE_ALL_FORM);
      this.$store.commit(PURGE_ALL_CHECKLIST);

      if (!this.appsExists) {
        this.$store.commit(PURGE_ALL_USER);
      }
    },

    onProceedCancelForm() {
      this.$store.dispatch(DELETE_FORM_SESSION, { id: this.formSession.id })
        .then((_resp) => {
          //nfh-console.log(_resp);

          this.user = blankUser_old();
          this.purgeAllStores();

          this.timeoutId = setTimeout(() => {
            if (!this.appsExists) {
              this.$store.dispatch(LOGOUT)
                .then(() => {
                  this.closeMessage();
                  this.$router.push(this.cancelReturnDestination);
                  //nfh-console.log("onProceedCancelForm dispatch(LOGOUT).then pushed route to login");
                })
                .finally(() => {
                  this.closeMessage();
                });
            }
            else {
              this.closeMessage();
              this.$router.push(this.cancelReturnDestination);
            }
          }, 10000);

          this.closeConfirmation();

          this.promptMessage(
            "Permohonan anda telah dibatalkan. Butiran permohonan anda telah dipadam dari borang permohonan ini. "
            + (this.appsExists ? "" : "Sila log-masuk semula jika anda ingin memohon kembali. ")
            + "Terima kasih",
            "Batal Permohonan",
            true,
            () => {
              if (!this.appsExists) {
                this.$store.dispatch(LOGOUT)
                  .then(() => {
                    clearTimeout(this.timeoutId);
                    //nfh-console.log("onProceedCancelForm dispatch(LOGOUT).then clearTimeout(this.timeoutId): ", this.timeoutId);
                    this.closeMessage();
                    this.$router.push(this.cancelReturnDestination);
                    //nfh-console.log("onProceedCancelForm dispatch(LOGOUT).then pushed route to login");
                  })
                  .finally(() => {
                    clearTimeout(this.timeoutId);
                    //nfh-console.log("onProceedCancelForm dispatch(LOGOUT).finally clearTimeout(this.timeoutId): ", this.timeoutId);
                    this.closeMessage();
                  });
              }
              else {
                clearTimeout(this.timeoutId);
                this.closeMessage();
                this.$router.push(this.cancelReturnDestination);
              }
            },
          );
        })
        .catch((err) => {
          //nfh-console.log(err);
        });
    },

    cancelForm(event) {
      if (this.formSession.id) {
        this.promptConfirmation(
          `Pembatalan akan memadam semua maklumat yang telah diisi di dalam borang ini.

          Adakah anda pasti mahu membatalkan permohonan ini ?`,
          "Batal Permohonan",
          this.onProceedCancelForm,
          () => { },
        );
      }
      else {
        //nfh-console.log("cancelForm: failed check this.formSession.id", this.formSession.id);
      }
    },

    clearForm(event) {
      //nfh-console.log("clearForm(event): ", event);
      if (this.formSession.id) {
        this.promptConfirmation(
          `Anda mahu set-semula borang ini dengan kosongkan semua input.<br/>
          Adakah anda pasti ?`,
          "Kosongkan Borang",
          this.onProceedClearForm,
          () => { },
        );
      }
      else {
        //nfh-console.log("clearForm: failed check this.formSession.id", this.formSession.id);
      }
    },

    async verifyEmail() {
      if (this.user.email) {
        this.user.email = String(this.user.email).trim().toLowerCase();
        this.isLoadingVerifyEmail = true;

        setTimeout(async () => {
          const _context = {
            email: this.user.email,
          };

          await this.$store.dispatch(CHECK_EMAIL_AVAILABILITY, _context)
            .then(async (email) => {
              if (email.data.success == true) {
                const _verifyParam = {
                  email: this.user.email,
                  from: this.$route.path,
                  next: this.$route.path,
                };

                await this.$store.dispatch(SEND_VERIFY_EMAIL, _verifyParam)
                  .then((resp) => {
                    if (resp?.data?.success == true) {
                      this.promptMessage(resp?.data?.message, "Sahkan Emel Anda", true);
                    }
                    else {
                      this.promptError(resp?.data?.message);
                    }
                    this.isLoadingVerifyEmail = false;
                  })
                  .catch((err) => {
                    this.isLoadingVerifyEmail = false;
                    this.promptError(err?.message);
                  });
              }
              else {
                this.isLoadingVerifyEmail = false;
                this.snackbar.visible = true;
                this.snackbar.message = email.data.message;
                this.snackbar.color = "red accent-2";
              }
            });
        }, 500);
      }
    },

    async verifyOTP() {
      this.isLoadingVerifyPhoneNum = true;

      setTimeout(async () => {
        const _context = {
          telno: this.user.phone_num,
        };

        if (this.user.phone_num) {
          await this.$store.dispatch(CHECK_TELNO_AVAILABILITY, _context)
            .then(async (telco) => {
              if (telco.data.success == true) {
                const _context = {
                  nric: this.currentUser.nric,
                  telno: this.user.phone_num,
                  allow_own_no: true, //test
                  options: this.otpOptions,
                };

                await this.$store.dispatch(SEND_OTP, _context)
                  .then(async (resp) => {
                    if (resp.data.success == true) {
                      // await this.init();
                      this.displayOTPDialog(resp?.data?.message + (resp?.data?.speedms ? ` [${resp.data.speedms}]` : ""));
                      this.isLoadingVerifyPhoneNum = false;
                    }
                    else {
                      this.isLoadingVerifyPhoneNum = false;
                      this.promptError(resp?.data?.message ??
                        "Maaf, sistem tidak dapat mengesahkan nombor telefon buat masa ini. Sila cuba lagi beberapa minit kemudian.");
                    }
                  })
                  .catch((err) => {
                    this.isLoadingVerifyPhoneNum = false;
                    this.promptError(err?.message);
                  })
                  .finally(() => {
                    this.isLoadingVerifyPhoneNum = false;
                  });
              }
              else {
                this.isLoadingVerifyPhoneNum = false;
                this.snackbar.visible = true;
                this.snackbar.message = telco.data.message;
                this.snackbar.color = "red accent-2";
              }
            });
        }
        else {
          this.promptError("Sila masukkan nombor telefon untuk disahkan terlebih dahulu");
          this.isLoadingVerifyPhoneNum = false;
        }
      }, 1000);
    },

    async onFinishOTP(value) {
      //nfh-console.log("onFinishOTP this.currentUser: ", this.currentUser)
      //nfh-console.log("onFinishOTP this.user: ", this.user)
      const _context = {
        nric: this.currentUser.nric,
        telno: this.user.phone_num,
        otp: value,
      };

      await this.$store.dispatch(VERIFY_OTP_PHONE, _context)
        .then(async (resp) => {
          this.hideOTPDialog();

          if (resp?.data?.success == true) {
            await this.$store.dispatch(GET_USER);
            await this.createNewSession();
          }
          else {
            this.promptError(resp?.data?.message);
          }
        })
        .catch((err) => {
          this.hideOTPDialog();
          this.promptError(err?.message);
        })
        .finally(() => {
          this.hideOTPDialog();
        });
    },

    onPhoneNumInput(formattedNumber, { number, valid, country }) {
      //nfh-console.log("onPhoneNumInput: ", number);
      this.phone.phone_num.country = country;
      this.phone.phone_num.number = number.national;
      this.phone.phone_num.mobile = parseInt(number?.national?.split('-')[0]) > 9;
      this.phone.phone_num.valid = valid && this.phone.phone_num.mobile;
      this.phone.phone_num.color = (this.phone.phone_num.valid) ? this.inputColorOK : this.inputColorNG;
      // this.user.phone_num = number.national;
    },

    displayOTPDialog(message) {
      this.msgOTP.message = message;
      this.msgOTP.nric = this.currentUser.nric;
      this.msgOTP.show = true;
    },

    hideOTPDialog() {
      this.msgOTP.show = false;
      this.msgOTP.nric = "";
      // this.msgOTP.run = 0;
      this.msgOTP.message = "";
    },

    backStep() {
      this.$emit("onBack");
    },

    async proceedStep() {
      this.$refs.validateFormUserInfo.validate()
        .then(async (success) => {
          if (success) {
            //nfh-console.log("validateFormUserInfo SUCCESS");

            this.isLoading = true;
            this.openOverlay();

            if (this.formSession.id) {
              convUpperCase(this.user, this.exceptionList);
              const _context = Object.assign({ id: this.formSession.id, salary: this.salary }, this.user);
              _context.signature = null;
              _context.agree_tnc = false;
              delete _context.bankInfo;

              await this.$store.dispatch(UPDATE_MEMBER_SESSION2, _context)
                .then(async (resp) => {
                  if (resp) {
                    if (resp?.success == true) {
                      await this.$store.dispatch(GET_CHECKLIST_FULL);
                      setTimeout(() => {
                        KTUtil.scrollTop();
                        this.formId = resp.data.id;
                        this.isLoading = false;
                        this.applicationForm++;
                        this.formSession.application_form = resp.data.application_form;
                        this.$emit("successProceed");
                      }, 500);
                    }
                    else {
                      //nfh-console.log("ERROR: UPDATE_MEMBER_SESSION2 - resp.success == false", resp);
                      this.isLoading = false;
                      this.promptError(resp.message);
                    }
                  }
                  this.closeOverlay();
                })
                .catch((err) => {
                  this.isLoading = false;
                  this.promptError(err?.message);
                  this.closeOverlay();
                  //nfh-console.log("ERROR: UPDATE_MEMBER_SESSION2 - try-catch error", err);
                })
                .finally(() => {
                  this.closeOverlay();
                  this.isLoading = false;
                });
            }
            else {
              let onSuccess = function() {
                setTimeout(() => {
                  KTUtil.scrollTop();
                  this.applicationForm++;
                  this.formId = resp?.data?.id;               // resp?.data?.id;
                  this.formSession.application_form = resp?.data?.application_form; // resp?.data?.application_form;
                  this.$emit("successProceed");
                }, 500);
              }
              let onFail = function() {
                this.isLoading = false;
                this.msgERR.message = errorMessages(resp?.message);
              }

              this.createNewSession(onSuccess, onFail);
              // convUpperCase(this.user, this.exceptionList);

              // this.user.nric     = sanitize_nric(this.user.nric);
              // const _createParam = Object.assign({
              //   office_num: this.user.user_office_num,
              //   salary: this.salary,
              // }, this.user);

              // this.$store.dispatch(CREATE_MEMBER_SESSION, _createParam)
              //   .then(async (resp) => {
              //     if (resp?.data?.success == true) {
              //       await this.$store.dispatch(GET_CHECKLIST_FULL);

              //       setTimeout(() => {
              //         KTUtil.scrollTop();
              //         this.formId = resp.data.data.id;
              //         this.applicationForm++;
              //         this.formSession.application_form = resp.data.data.application_form;
              //         this.$emit("successProceed");
              //       }, 500);
              //     }
              //     else {
              //       this.isLoading = false;
              //       this.msgERR.message = errorMessages(resp.data.message);
              //     }

              //     this.closeOverlay();
              //     this.isLoading = false;
              //   })
              //   .catch((err) => {
              //     this.isLoading = false;
              //     this.promptError(err?.message);
              //     this.closeOverlay();
              //     console.log("ERROR: UPDATE_MEMBER_SESSION2 - try-catch error", err);
              //   })
              //   .finally(() => {
              //     this.isLoading = false;
              //     this.closeOverlay();
              //   });
            }
          }
          else {
            //nfh-console.log("validateFormUserInfo FAILED");
            this.$nextTick(() => {
              this.$el.querySelector(".v-messages.error--text:first-of-type")?.scrollIntoView(false);
              //nfh-console.log("validateFormUserInfo : ", success);
              //nfh-console.log("this.$refs.validateFormUserInfo : ", this.$refs.validateFormUserInfo);
              //nfh-console.log("this.$refs.validateFormUserInfo.errors : ", this.validationErrorList );
              return;
            });
          }
        })
        .catch((err) => {
          //nfh-console.log("validateFormUserInfo ERROR", err);
          this.isLoading = false;
          this.promptError(err?.message);
          this.closeOverlay();
        });

      this.$emit("onProceed");
    },

    async createNewSession(onSuccess, onFail) {
      convUpperCase(this.user, this.exceptionList);

      this.user.nric     = sanitize_nric(this.user.nric);
      const _createParam = Object.assign({
        office_num: this.user.user_office_num,
        salary: this.salary,
      }, this.user);

      await this.$store.dispatch(CREATE_MEMBER_SESSION, _createParam)
        .then(async (resp) => {
          if (resp?.success == true) {
          // if (resp?.success == true) {
            await this.$store.dispatch(GET_CHECKLIST_FULL);

            if (typeof onSuccess === 'function') {
              onSuccess();
            }
          }
          else {
            if (typeof onFail === 'function') {
              onFail();
            }
          }

          this.closeOverlay();
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.promptError(err?.message);
          this.closeOverlay();
          //nfh-console.log("ERROR: UPDATE_MEMBER_SESSION2 - try-catch error", err);
        })
        .finally(() => {
          this.isLoading = false;
          this.closeOverlay();
        });
    },

    computedInfoNRICUser() {
      const _resp = extractInfoNRIC(this.user.nric);

      this.user.birth_date = _resp.birth_date;
      this.user.gender_id = _resp.gender_id;
      if (_resp.salute_id) {
        this.user.salute_id = _resp.salute_id;
      }
      else {
        this.user.salute_id = (this.user.marital_status_id == 1) ? 2 : 3;
      }
      // if (this.user.nric && nric_dob(this.user.nric)) {
      //   this.user.birth_date = nric_dob(this.user.nric);
      //   const gdr = nric_gender(this.user.nric);

      //   this.gender.forEach((_) => {
      //     if (gdr == 1 && String(_.value)?.toUpperCase() == "M") {
      //       this.user.gender_id = _.id;
      //       this.user.salute_id = 1;
      //     }
      //     else if (gdr == 0 && String(_.value)?.toUpperCase() == "F") {
      //       this.user.gender_id = _.id;
      //     }
      //   });
      // }
    },

    useMainAddress() {
      // this.showMailingAddress = !this.showMailingAddress;
      // this.user.mailing_same_home = true;
      // this.showMailingAddress = !this.user.mailing_same_home;
      this.user.mailing_unit = this.user.home_unit;
      this.user.mailing_street = this.user.home_street;
      this.user.mailing_line3 = this.user.home_line3;
      this.user.mailing_line4 = this.user.home_line4;
      this.user.mailing_postcode = this.user.home_postcode;
      this.user.mailing_city_id = this.user.home_city_id;
      this.user.mailing_state_id = this.user.home_state_id;
      this.user.mailing_ownership_id = this.user.home_ownership_id;
    },

    onHomeNumInput(formattedNumber, { number, valid, country }) {
      this.phone.home_num.country = country;
      this.phone.home_num.number = number.national;
      this.phone.home_num.mobile = parseInt(this.phone?.home_num?.number?.split('-')[0]) > 9;
      this.phone.home_num.valid = valid && !this.phone?.home_num?.mobile;
      this.phone.home_num.color = (this.phone?.home_num?.valid) ? this.inputColorOK : this.inputColorNG;
    },

    onUserOfficeNumInput(formattedNumber, { number, valid, country }) {
      this.phone.user_office_num.country = country;
      this.phone.user_office_num.number = number.national;
      this.phone.user_office_num.mobile = parseInt(this.phone?.user_office_num?.number?.split('-')[0]) > 9;
      this.phone.user_office_num.valid = valid && !this.phone?.user_office_num?.mobile;
      this.phone.user_office_num.color = (this.phone?.user_office_num?.valid) ? this.inputColorOK : this.inputColorNG;
      // this.employment.office_num = number.national;
    },

    onHomeCityChange(value) {
      if (
        this.city.find((ct) => {
          if (ct.id == value) {
            this.user.home_state_id = ct.state_id;
            this.cityHomeScoped = this.city.slice().filter((ct) =>
              ct.state_id === this.city.find((c) => c.id === value).state_id
            );
          }
        })
      );
    },

    onHomeStateChange(value) {
      this.cityHomeScoped = value
        ? this.city.slice().filter((ct) => ct.state_id === value)
        : this.city.slice();
    },

    onMailingCityChange(value) {
      if (
        this.city.find(function (ct) {
          if (ct.id == value) {
            this.user.mailing_state_id = ct.state_id;
            this.cityMailingScoped = this.city.slice().filter((ct) =>
              ct.state_id === this.city.find((c) => c.id === value).state_id
            );
            return true;
          }
        }, this)
      );
    },

    onMailingStateChange(value) {
      this.cityMailingScoped = value
        ? this.city.slice().filter((ct) => ct.state_id === value)
        : this.city.slice();
    },

    getInitBank() {
      this.banks.find((_bank) => {
        if (_bank.id == this.user.banks_id) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.selectedBank = _bank;
          if (this.user) {
            this.user.bankInfo = _bank;
          }
        }
      });
    },

    onBankAccChange(value) {
      // this.mask_bankAcc = "#".repeat(value.max);
      // this.selectedBank = value;
      // this.user.bank_name = value.name;
      // this.user.banks_id = value.id;
    },

    onBankAccInput(value) {
      if (value) {
        this.selectedBank = value;
        this.mask_bankAcc = "#".repeat(this.selectedBank.max);

        if (this.formSession) {
          this.formSession.bank_name = this.selectedBank.name;
          this.formSession.banks_id = this.selectedBank.id;
        }

        if (this.user) {
          this.user.bank_name = this.selectedBank.name;
          this.user.banks_id = this.selectedBank.id;
        }
      }
    },

    openCalculator() {
      this.openCalculatorDialog = true;
    },

    async saveCalcResult(calcData) { //total, rate, tenure, inst, deds, salary, empTypeId) {
      //nfh-console.log(" IN: saveCalcResult");
      //nfh-console.log("saveCalcResult <calcData>: ", calcData);

      if (calcData?.success) {
        this.calculator.total          = calcData?.data?.financing_req_amount;
        this.user.financing_req_amount = calcData?.data?.financing_req_amount;
        this.user.financing_req_tenure = calcData?.data?.financing_req_tenure;
        this.user.financing_req_rate   = calcData?.data?.financing_req_rate;
        this.user.financing_req_inst   = calcData?.data?.financing_req_inst;
        this.user.financing_req_deds   = calcData?.data?.financing_req_deds;
        this.user.financing_calc       = calcData?.data?.financing_calc;
        this.user.employer_type_id     = calcData?.data?.employer_type_id;
        this.user.salary               = calcData?.data?.salary;

        const params = {
          salary: calcData?.data?.salary,
          financing_calc: calcData?.data?.financing_calc,
          employer_type_id: calcData?.data?.employer_type_id,
          financing_req_deds: calcData?.data?.financing_req_deds,
          financing_req_inst: calcData?.data?.financing_req_inst,
          financing_req_rate: calcData?.data?.financing_req_rate,
          financing_req_amount: calcData?.data?.financing_req_amount,
          financing_req_tenure: calcData?.data?.financing_req_tenure,
        };

        this.$store.commit(SET_FINANCING_FORM, params);

        const emp = this.employmentFormSession;
        emp.salary = params.salary;
        emp.employer_type_id = params.employer_type_id;
        this.$store.commit(SET_EMPLOYMENT_FORM_SESSION, emp);
      }
      this.openCalculatorDialog = false;

      //nfh-console.log("OUT: saveCalcResult");
    },

    async calculate(value) {
      // const _context = {
      //   salary: value.salary,
      //   employer: value.employerId,
      //   deductions: value.deductions,
      //   form_id: this.formSession.id,
      // };

      // await this.$store.dispatch(CALCULATE, _context)
      //   .then((resp) => {
      //     this.$store.commit(SET_CALC_RESULT, resp.data);
      //     this.salary = value.salary;
      //   });
    },

    filterNonNumeric() {
      // Replace non-numeric characters with an empty string
      this.user.bank_acc = this.user?.bank_acc?.replace(/[^0-9]/g, "");
    },

    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    promptConfirmation(message, title = "Pengesahan", cbProceed = () => { }, cbCancel = () => { }) {
      this.confirmDialog.title = title;
      this.confirmDialog.content = message;
      this.confirmDialog.cancel = cbCancel;
      this.confirmDialog.proceed = cbProceed;
      this.confirmDialog.backButton = "Tidak";
      this.confirmDialog.proceedButton = "Ya";
      this.confirmDialog.showBackButton = true;
      this.confirmDialog.showProceedButton = true;
      this.confirmDialog.show = true;
      //nfh-console.log("promptConfirmation() confirmDialog: ", this.confirmDialog);
    },

    closeConfirmation() {
      this.confirmDialog.cancel = () => { };
      this.confirmDialog.proceed = () => { };
      this.confirmDialog.title = "";
      this.confirmDialog.content = "";
      this.confirmDialog.backButton = "";
      this.confirmDialog.proceedButton = "";
      this.confirmDialog.showBackButton = false;
      this.confirmDialog.showProceedButton = false;
      this.confirmDialog.show = false;
      //nfh-console.log("closeConfirmation() confirmDialog: ", this.confirmDialog);
    },

    promptError(message) {
      //nfh-console.log("promptError: message ", message);
      this.msgERR.message = errorMessages(message);
      this.flagErrMsgDlg = true;
      //nfh-console.log("promptError: msgERR.message ", this.msgERR.message);
    },

    promptInfo(message) {
      this.msgINFO.message = message;
      this.flagInfoMsgDlg = true;
    },

    promptMessage(message, title = "Makluman", modal = false, cbProceed = () => { }) {
      this.messageDialog.title = title;
      this.messageDialog.modal = modal;
      this.messageDialog.content = message;
      this.messageDialog.cancel = () => { };
      this.messageDialog.proceed = cbProceed;
      this.messageDialog.backButton = "";
      this.messageDialog.proceedButton = "Ok";
      this.messageDialog.showBackButton = false;
      this.messageDialog.showProceedButton = true;
      this.messageDialog.show = true;
      //nfh-console.log("promptMessage() messageDialog: ", this.messageDialog);
    },

    closeMessage() {
      this.messageDialog.cancel = () => { };
      this.messageDialog.proceed = () => { };
      this.messageDialog.title = "";
      this.messageDialog.modal = false;
      this.messageDialog.content = "";
      this.messageDialog.backButton = "";
      this.messageDialog.proceedButton = "";
      this.messageDialog.showBackButton = false;
      this.messageDialog.showProceedButton = false;
      this.messageDialog.show = false;
      //nfh-console.log("closeMessage() messageDialog: ", this.messageDialog);
    },

    openOverlay() {
      this.overlay = true;
    },

    closeOverlay() {
      setTimeout(() => {
        this.overlay = false;
      }, 2000);
    },

    goToError(_ref) {
      setTimeout(() => {
        const errors = Object.entries(_ref.errors)
          .map(([key, value]) => ({ key, value }))
          .filter((error) => error["value"].length);

        _ref.refs[errors[0]["key"]].$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 200);
    },

  },

  async mounted() {
    //nfh-console.log(" IN:MOUNTED")
    this.city                = await JSON.parse(localStorage.getItem("cty")); //
    this.race                = await JSON.parse(localStorage.getItem("rce"));
    this.banks               = await JSON.parse(localStorage.getItem("bnk"));
    this.gender              = await JSON.parse(localStorage.getItem("gdr"));
    this.salute              = await JSON.parse(localStorage.getItem("sal"));
    this.states              = await JSON.parse(localStorage.getItem("stt")); //
    this.religion            = await JSON.parse(localStorage.getItem("rgn")); //
    // this.relations           = await JSON.parse(localStorage.getItem("rel"));
    this.employerTypes       = await JSON.parse(localStorage.getItem("emp")); //
    this.maritalStatus       = await JSON.parse(localStorage.getItem("mry"));
    this.houseOwnership      = await JSON.parse(localStorage.getItem("own"));
    this.educationLevel      = await JSON.parse(localStorage.getItem("edu"));
    // this.occupationTypes     = await JSON.parse(localStorage.getItem("occ")); //
    // this.cityHeirScoped      = this.city.slice();
    this.cityHomeScoped      = this.city.slice();
    // this.cityOfficeScoped    = this.city.slice();
    this.cityMailingScoped   = this.city.slice();
    await this.$store.dispatch(GET_USER);
    await this.init();

    this.snackbarSignature.visible = true;
    // this.isOTPNotILMS = !!!this.currentUser.phone_verified;
    //nfh-console.log("OUT:MOUNTED")
  },

  beforeUpdate() {
    //nfh-console.log(" IN:BEFOREUPDATE");
    //nfh-console.log("OUT:BEFOREUPDATE");
  },

  updated() {
    //nfh-console.log(" IN:UPDATED");
    //nfh-console.log("OUT:UPDATED");
  },

  activated() {
    //nfh-console.log(" IN:ACTIVATED");
    //nfh-console.log("OUT:ACTIVATED");
  },

  deactivated() {
    //nfh-console.log(" IN:DEACTIVATED");
    //nfh-console.log("OUT:DEACTIVATED");
  },

  destroyed() {
    //nfh-console.log(" IN:DESTROYED");
    //nfh-console.log("OUT:DESTROYED");
  },

  beforeDestroy() {
    //nfh-console.log(" IN:BEFOREDESTROY");
    //nfh-console.log("OUT:BEFOREDESTROY");
  },

  async beforeCreate() {
    //nfh-console.log(" IN:BEFORECREATE");
    //nfh-console.log("OUT:BEFORECREATE");
  },

  async beforeMounted() {
    //nfh-console.log(" IN:BEFOREMOUNTED");
    //nfh-console.log("OUT:BEFOREMOUNTED");
  },
}
</script>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";

.v-text-field--outlined {
  font-size: 11pt;
}

.v-text-field--outlined label {
  font-size: 11pt;
}

.ucase-text input {
  text-transform: uppercase;
}

.lcase-text input {
  text-transform: lowercase;
}

.checbox-decoration {
  margin: 0px;
}

.checbox-decoration .v-label {
  margin: 0px;
  font-size: 12px;
}

.snackbar-title {
  font-size: 14px;
  font-weight: 600;
}

.snackbar-subtitle {
  font-size: 12px;
}

.input-large {
  font-size: large;
}

#signaturePad {
  border: double 3px transparent;
  border-radius: 10px;
  border-width: 1;
  border-color: #000;
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>