var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "pa-2", attrs: { height: "auto", elevation: "0" } },
        [
          _c(
            "form",
            { attrs: { method: "post", enctype: "multipart/form-data" } },
            [
              _c(
                "div",
                [
                  _c("h5", { staticClass: "primary--text font-weight-bold" }, [
                    _vm._v("Pengakuan"),
                  ]),
                  _c("v-divider", { staticClass: "mt-0" }),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-flex",
                    { attrs: { xs2: "", xm2: "", md1: "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-1", attrs: { cols: "1" } },
                        [
                          _c("v-checkbox", {
                            attrs: { dense: "" },
                            on: {
                              click: function ($event) {
                                return _vm.proceedButtonEnabler()
                              },
                            },
                            model: {
                              value: _vm.agree_tnc,
                              callback: function ($$v) {
                                _vm.agree_tnc = $$v
                              },
                              expression: "agree_tnc",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs10: "", xm10: "", md11: "" } },
                    [
                      _c("v-col", { attrs: { cols: "11" } }, [
                        _c("p", { staticClass: "subtitle-1" }, [
                          _vm._v(
                            " Saya bersetuju untuk memberi kebenaran kepada KOWAMAS mengatur/mengurus dan menyelesaikan mana-mana hutang sedia ada termasuk baki tertunggak kad kredit untuk tujuan kelulusan permohonan ini dan mengaku: "
                          ),
                          _c("br"),
                          _c("ol", [
                            _c("li", [
                              _vm._v(
                                " Bahawa segala maklumat yang diberikan dalam permohonan ini adalah benar dan betul dan saya tidak menyembunyikan sebarang fakta penting. "
                              ),
                            ]),
                            _c("li", [
                              _vm._v(
                                " Bahawa saya bukan seorang yang bankrap (muflis) dan tiada sebarang tindakan undang-undang ke atas diri saya. "
                              ),
                            ]),
                            _c("li", [
                              _vm._v(
                                " Bahawa KOWAMAS mempunyai hak mutlak untuk tidak meluluskan permohonan ini tanpa sebarang sebab. "
                              ),
                            ]),
                            _c("li", [
                              _vm._v(
                                " Dan sekiranya didapati sebarang maklumat yang tidak betul di dalam permohonan ini setelah pembiayaan ini diluluskan, KOWAMAS mempunyai hak untuk mengambil sebarang tindakan yang difikirkan patut dan sesuai ke atas diri saya. "
                              ),
                            ]),
                          ]),
                        ]),
                        _c(
                          "div",
                          [
                            _c(
                              "v-card",
                              {
                                staticClass: "pa-4",
                                attrs: { height: "150", width: "200" },
                              },
                              [
                                _c("v-img", {
                                  staticClass: "ma-auto",
                                  attrs: { src: _vm.signature, contain: "" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "pt-4" },
                          [
                            _c(
                              "v-dialog",
                              {
                                attrs: { width: "500" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "subtitle-2 font-weight-bold",
                                                attrs: { color: "success" },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { left: "" } },
                                              [_vm._v(" mdi-pencil ")]
                                            ),
                                            _vm._v(" Tandatangan Pemohon "),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.signaturePadDlg,
                                  callback: function ($$v) {
                                    _vm.signaturePadDlg = $$v
                                  },
                                  expression: "signaturePadDlg",
                                },
                              },
                              [
                                _c("v-card", [
                                  _c(
                                    "div",
                                    { staticClass: "container" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "col-12 pa-2 mb-4" },
                                        [
                                          _c("VueSignaturePad", {
                                            ref: "signaturePad",
                                            attrs: {
                                              id: "signaturePad",
                                              width: "100%",
                                              height: "300px",
                                              options: {
                                                onBegin: function () {
                                                  _vm.$refs.signaturePad.resizeCanvas()
                                                },
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            align: "end",
                                            justify: "end",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mr-4 mb-4",
                                              attrs: { text: "" },
                                              on: { click: _vm.undo },
                                            },
                                            [_vm._v("Undur")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mr-4 mb-4",
                                              attrs: { color: "primary" },
                                              on: { click: _vm.save },
                                            },
                                            [_vm._v("Simpan")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-snackbar",
                {
                  attrs: { color: "red lighten-1", top: "" },
                  model: {
                    value: _vm.snackbarSignature.visible,
                    callback: function ($$v) {
                      _vm.$set(_vm.snackbarSignature, "visible", $$v)
                    },
                    expression: "snackbarSignature.visible",
                  },
                },
                [
                  _c(
                    "v-row",
                    {
                      attrs: {
                        "no-gutters": "",
                        align: "center",
                        justify: "center",
                      },
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "1" } },
                        [
                          _c("v-icon", [
                            _vm._v(_vm._s(_vm.snackbarSignature.icon)),
                          ]),
                        ],
                        1
                      ),
                      _c("v-col", { attrs: { cols: "10" } }, [
                        _c("div", { staticClass: "mx-3" }, [
                          _c("p", { staticClass: "snackbar-title" }, [
                            _vm._v(
                              " " + _vm._s(_vm.snackbarSignature.title) + " "
                            ),
                          ]),
                          _c("p", { staticClass: "snackbar-subtitle mb-0" }, [
                            _vm._v(
                              " " + _vm._s(_vm.snackbarSignature.text) + " "
                            ),
                          ]),
                        ]),
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "1" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  _vm.snackbarSignature.visible = false
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "pl-2 pb-2 pt-10", attrs: { align: "right" } },
        [
          _c(
            "v-btn",
            {
              attrs: { text: "" },
              on: {
                click: function ($event) {
                  return _vm.backStep()
                },
              },
            },
            [
              _c("v-icon", { attrs: { left: "", dark: "" } }, [
                _vm._v("mdi-chevron-left"),
              ]),
              _vm._v("Kembali "),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "ml-4",
              attrs: {
                color: "primary",
                loading: _vm.isLoading,
                disabled: _vm.stateProceed,
              },
              on: {
                click: function ($event) {
                  return _vm.saveSignature()
                },
              },
            },
            [
              _vm._v(" Teruskan "),
              _c("v-icon", { attrs: { right: "", dark: "" } }, [
                _vm._v("mdi-chevron-right"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("ErrorMsgDialog", {
        attrs: {
          title: "Ralat",
          proceedButton: "OK",
          content: _vm.msgERR.message,
          showProceedButton: true,
          showBackButton: false,
          delayOnProceed: false,
          isTypeError: true,
        },
        model: {
          value: _vm.flagErrMsgDlg,
          callback: function ($$v) {
            _vm.flagErrMsgDlg = $$v
          },
          expression: "flagErrMsgDlg",
        },
      }),
      _c("InfoMsgDialog", {
        attrs: {
          title: "Info",
          proceedButton: "OK",
          content: _vm.msgINFO.message,
          showProceedButton: true,
          showBackButton: false,
          delayOnProceed: false,
        },
        model: {
          value: _vm.flagInfoMsgDlg,
          callback: function ($$v) {
            _vm.flagInfoMsgDlg = $$v
          },
          expression: "flagInfoMsgDlg",
        },
      }),
      _c(
        "v-overlay",
        { attrs: { value: _vm.overlay } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }