var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "pa-2", attrs: { height: "auto", elevation: "0" } },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            _vm._l(_vm.summaryData, function (head) {
              return _c(
                "div",
                { key: head.id },
                [
                  _c("v-divider"),
                  _c("div", { staticClass: "mb-10" }, [
                    _c(
                      "h4",
                      { staticClass: "primary--text font-weight-bold" },
                      [_vm._v(" " + _vm._s(head.header) + " ")]
                    ),
                  ]),
                  _vm._l(head.data, function (content) {
                    return _c(
                      "v-row",
                      { key: content.id },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs4: "", sm4: "", md3: "" } },
                          [
                            _c("v-col", { staticClass: "col pt-0 pb-0" }, [
                              _c("p", { staticClass: "subtitle-1" }, [
                                _vm._v(" " + _vm._s(content.label) + " "),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs8: "", sm8: "", md9: "" } },
                          [
                            _c("v-col", { staticClass: "col pt-0 pb-0" }, [
                              String(content.value).substring(0, 10) ==
                              "data:image"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          attrs: {
                                            "max-width": "300",
                                            elevation: "0",
                                            tile: "",
                                          },
                                        },
                                        [
                                          _c("v-img", {
                                            attrs: {
                                              contain: "",
                                              src: content.value,
                                              height: "150",
                                              width: "180",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : Array.isArray(content.value)
                                ? _c("div", [
                                    _c(
                                      "ol",
                                      _vm._l(content.value, function (listing) {
                                        return _c(
                                          "li",
                                          { key: listing.value },
                                          [
                                            _vm._v(
                                              " " + _vm._s(listing.value) + " "
                                            ),
                                          ]
                                        )
                                      }),
                                      0
                                    ),
                                  ])
                                : _c("div", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "subtitle-1 font-weight-bold mb-0",
                                        staticStyle: {
                                          "white-space": "pre-line",
                                          "padding-bottom": "12px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(content.value) + " "
                                        ),
                                      ]
                                    ),
                                  ]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              )
            }),
            0
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pl-2 pb-2 pt-10", attrs: { align: "right" } },
        [
          _c(
            "v-btn",
            {
              attrs: { text: "" },
              on: {
                click: function ($event) {
                  return _vm.backStep()
                },
              },
            },
            [
              _c("v-icon", { attrs: { left: "", dark: "" } }, [
                _vm._v(" mdi-chevron-left "),
              ]),
              _vm._v(" Kembali "),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "ml-4",
              attrs: {
                color: "primary",
                loading: _vm.isLoading,
                disabled: _vm.proceedState,
              },
              on: {
                click: function ($event) {
                  return _vm.proceedStep()
                },
              },
            },
            [
              _vm._v(" Hantar "),
              _c("v-icon", { attrs: { right: "", dark: "" } }, [
                _vm._v(" mdi-chevron-right "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-overlay",
        { attrs: { value: _vm.overlay } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }