<template>
  <div>
    <v-card
      class="pa-2"
      height="auto"
      elevation="0"
    >
      <ValidationObserver
        ref="heirFormValidation"
      >
        <form
          class="font-weight-bold font-size-sm"
        >
          <v-container
            fluid
          >
            <v-row
            >
              <v-col
                cols="12" md="12"
              >
                <h5
                  class="primary--text font-weight-bold"
                >
                  Maklumat Waris
                </h5>
                <v-divider
                  class="mt-0"
                ></v-divider>
              </v-col>

              <v-col
                class="py-0"
                cols="12"
                md="12"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Nama Pewaris"
                  rules="required"
                >
                  <v-text-field
                    v-model="heir.heir_name"
                    hint="Nama seperti dalam Kad Pengenalan"
                    label="Nama Pewaris"
                    class="ucase-text"
                    maxLength="40"
                    :error-messages="errors"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col
                class="py-0"
                cols="12"
                md="6"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="No. Kad Pengenalan"
                  rules="required"
                >
                  <v-text-field
                    v-model="heir.heir_nric"
                    v-mask="mask_icNum"
                    label="No. Kad Pengenalan"
                    :error-messages="errors"
                    @keyup="computedInfoNRICHeir"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col
                class="py-0"
                cols="12"
                md="6"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Tarikh Lahir"
                  rules="required"
                >
                  <v-menu
                    v-model="heirBirthDateMenu"
                    :error-messages="errors"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template
                      v-slot:activator="{ on, attrs }"
                    >
                      <v-text-field
                        :value="computedHeirBirthDate"
                        v-bind="attrs"
                        v-on="on"
                        label="Tarikh Lahir"
                        hint="Tarikh Lahir diperoleh daripada Nombor Kad Pengenalan"
                        required
                        readonly
                        outlined
                        filled
                        dense
                      ></v-text-field>
                    </template>
                  </v-menu>
                </ValidationProvider>
              </v-col>

              <v-col
                class="py-0"
                cols="12"
                md="4"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Jantina (Waris)"
                  rules="required"
                >
                  <v-select
                    v-model="heir.heir_gender_id"
                    label="Jantina"
                    item-text="nameMS"
                    item-value="id"
                    :items="gender"
                    :error-messages="errors"
                    required
                    outlined
                    filled
                    dense
                  ></v-select>
                </ValidationProvider>
              </v-col>

              <v-col
                class="py-0"
                cols="12"
                md="4"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Bangsa"
                  rules="required"
                >
                  <v-select
                    v-model="heir.heir_race_id"
                    label="Bangsa"
                    item-text="nameMS"
                    item-value="id"
                    :items="race"
                    :error-messages="errors"
                    required
                    outlined
                    dense
                  ></v-select>
                </ValidationProvider>
              </v-col>

              <v-col
                class="py-0"
                cols="12"
                md="4"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Agama"
                  rules="required"
                >
                  <v-select
                    v-model="heir.heir_religion_id"
                    label="Agama"
                    item-text="nameMS"
                    item-value="id"
                    :items="religion"
                    :error-messages="errors"
                    required
                    outlined
                    dense
                  ></v-select>
                </ValidationProvider>
              </v-col>

              <v-col
                class="py-0"
                cols="12"
                md="6"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Hubungan"
                  rules="required"
                >
                  <v-select
                    v-model="heir.relationship_id"
                    label="Hubungan"
                    item-text="nameMS"
                    item-value="id"
                    :items="relations"
                    :error-messages="errors"
                    required
                    outlined
                    dense
                  ></v-select>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" md="12">
                <h5 class="primary--text font-weight-bold">
                  Alamat Rumah (Waris)
                </h5>
                <v-divider
                  class="mt-0"
                ></v-divider>
              </v-col>

              <v-col
                class="py-0"
                cols="12"
                md="12"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Unit/Lot/No./Tingkat"
                  rules="required"
                >
                  <v-text-field
                    v-model="heir.heir_unit"
                    hint="Contoh A1-2-3"
                    :error-messages="errors"
                    label="Unit/Lot/No./Tingkat"
                    class="ucase-text"
                    outlined
                    dense
                    required
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col
                class="py-0"
                cols="12"
                md="12"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Jalan/Apartment/Taman"
                  rules="required"
                >
                  <v-text-field
                    v-model="heir.heir_street"
                    hint="Jalan Bahagia Kampung Sentosa"
                    :error-messages="errors"
                    class="ucase-text"
                    label="Jalan/Apartment/Taman"
                    outlined
                    dense
                    required
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col
                class="py-0"
                cols="12"
                md="12"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="SButiran Tambahan 1 ()" rules=""
                >
                  <v-text-field
                    v-model="heir.heir_line3"
                    hint="Masukkan butiran alamat sekiranya ada"
                    label="Butiran Tambahan Sekiranya Ada (tidak wajib)"
                    class="ucase-text"
                    :error-messages="errors"
                    outlined
                    dense
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col
                class="py-0"
                cols="12"
                md="12"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Butiran Tambahan 2" rules=""
                >
                  <v-text-field
                    v-model="heir.heir_line4"
                    label="Butiran Tambahan Sekiranya Ada (tidak wajib)"
                    hint="Masukkan butiran alamat sekiranya ada"
                    class="ucase-text"
                    :error-messages="errors"
                    outlined
                    dense
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col
                class="py-0"
                cols="12"
                md="4"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Poskod"
                  rules="required"
                >
                  <v-text-field
                    v-model="heir.heir_postcode"
                    v-mask="mask_postCode"
                    label="Poskod"
                    :error-messages="errors"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col
                class="py-0"
                cols="12"
                md="8"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Bandar"
                  rules="required"
                >
                  <v-select
                    v-model="heir.heir_city_id"
                    v-on:change="onHeirCityChange"
                    label="Bandar"
                    hint="Bandar mengikut poskod"
                    class="ucase-text"
                    item-text="display"
                    item-value="id"
                    :items="cityHeirScoped"
                    :error-messages="errors"
                    required
                    outlined
                    dense
                  ></v-select>
                </ValidationProvider>
              </v-col>

              <v-col
                class="py-0"
                cols="12"
                md="8"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Negeri"
                  rules="required"
                >
                  <v-select
                    v-model="heir.heir_state_id"
                    v-on:change="onHeirStateChange"
                    label="Negeri"
                    class="ucase-text"
                    item-text="name"
                    item-value="id"
                    :items="states"
                    :error-messages="errors"
                    required
                    outlined
                    dense
                  ></v-select>
                </ValidationProvider>
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <h5
                  class="primary--text font-weight-bold"
                >
                  Telefon Untuk Dihubungi (Waris)
                </h5>
                <v-divider
                  class="mt-0"
                ></v-divider>
              </v-col>

              <v-col
                class="py-0"
                cols="12"
                md="4"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Telefon Bimbit"
                  rules="required"
                >
                  <vue-tel-input-vuetify
                    v-model="heir.heir_phone_num"
                    v-mask="mask_phoneMobile"
                    label="Telefon Bimbit"
                    placeholder="Telefon Bimbit"
                    hint="Contoh: 010-123 4567 / 011-1234 5678"
                    defaultCountry="MY"
                    :maxlength="13"
                    :onlyCountries="['MY']"
                    :error-messages="errors"
                    :translations="{
                      countrySelectorLabel: '',
                      countrySelectorError: '',
                      phoneNumberLabel: 'Telefon Bimbit',
                      example: 'Contoh :',
                    }"
                    :background-color="inputColorHeirPhoneNum"
                    @input="onHeirPhoneNumInput"
                    required
                    outlined
                    dense
                  ></vue-tel-input-vuetify>
                </ValidationProvider>
              </v-col>

              <v-col
                class="py-0"
                cols="12"
                md="4"
              >
                <!-- <ValidationProvider
                  v-slot="{ errors }"
                  name="Telefon Rumah"
                > -->
                  <vue-tel-input-vuetify
                    v-model="heir.heir_home_num"
                    v-mask="mask_phoneLand"
                    label="Telefon Rumah"
                    placeholder="Telefon Rumah"
                    hint="Contoh: 03-1234 5678 / 04-123 4567"
                    defaultCountry="MY"
                    :maxlength="13"
                    :onlyCountries="['MY']"
                    :error-messages="errors"
                    :translations="{
                      countrySelectorLabel: '',
                      countrySelectorError: '',
                      phoneNumberLabel: 'Telefon Rumah',
                      example: 'Contoh :',
                    }"
                    :background-color="inputColorHeirHomeNum"
                    @input="onHeirHomeNumInput"
                    outlined
                    dense
                  ></vue-tel-input-vuetify>
                <!-- </ValidationProvider> -->
              </v-col>

              <v-col
                class="py-0"
                cols="12"
                md="4"
              >
                <!-- <ValidationProvider
                  v-slot="{ errors }"
                  name="Telefon Pejabat"
                > -->
                  <vue-tel-input-vuetify
                    v-model="heir.heir_office_num"
                    v-mask="mask_phoneLand"
                    label="Telefon Pejabat"
                    placeholder="Telefon Pejabat"
                    hint="Contoh: 03-1234 5678 / 04-123 4567"
                    defaultCountry="MY"
                    :maxlength="13"
                    :onlyCountries="['MY']"
                    :error-messages="errors"
                    :translations="{
                      countrySelectorLabel: '',
                      countrySelectorError: '',
                      phoneNumberLabel: 'Telefon Pejabat',
                      example: 'Contoh :',
                    }"
                    :background-color="inputColorHeirOfficeNum"
                    @input="onHeirOfficeNumInput"
                    outlined
                    dense
                  ></vue-tel-input-vuetify>
                <!-- </ValidationProvider> -->
              </v-col>
            </v-row>
          </v-container>
        </form>
      </ValidationObserver>
    </v-card>

    <div
      align="right"
      class="pr-4 pl-2 pb-2 pt-10"
    >
      <v-btn
        color="grey darken-1"
        text
        @click="backStep"
      >
        <v-icon
          left dark
        >
          mdi-chevron-left
        </v-icon>
        Kembali
      </v-btn>
      <v-btn
        class="ml-4"
        color="primary"
        :loading="isLoading"
        @click="proceedStep()"
      >
        Teruskan
        <v-icon
          right
          dark
        >
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </div>

    <ErrorMsgDialog
      v-model="flagErrMsgDlg"
      title="Ralat"
      proceedButton="OK"
      :content="msgERR.message"
      :showProceedButton=true
      :showBackButton=false
      :delayOnProceed="false"
      :isTypeError="true"
    ></ErrorMsgDialog>

    <InfoMsgDialog
      v-model="flagInfoMsgDlg"
      title="Info"
      proceedButton="OK"
      :content="msgINFO.message"
      :showProceedButton=true
      :showBackButton=false
      :delayOnProceed="false"
    ></InfoMsgDialog>

    <v-overlay
      :value="overlay"
    >
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

  </div>
</template>

<script>
import {
  mapGetters,
} from "vuex";

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

import {
  required,
  email,
  max,
  numeric,
} from "vee-validate/dist/rules";

import {
  sanitize_nric,
  extractInfoNRIC,
  blankHeir,
  convUpperCase,
  errorMessages,
  formatAddress,
  filterObjects,
} from "@/core/services/helper.service";

import {
  CREATE_HEIR_SESSION,
  GET_LATEST_HEIR_FORM_SESSION,
} from "@/core/services/store/form.module";

import dayjs from "dayjs";
import KTUtil from "@/assets/js/components/util";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} tidak boleh dibiarkan kosong",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("email", {
  ...email,
  message: "E-mel mestilah sah",
});

extend("numeric", {
  ...numeric,
  message: "Nombor sahaja dibenarkan",
});

export default {
  name: "HeirDetails",

  components: {
    ValidationObserver,
    ValidationProvider,
  },

  watch: {
    propSignature: function (newValue, oldValue) {
      this.signature = newValue;
    },

    'heir.heir_gender_id': function (newValue, oldValue) {
      this.relations = filterObjects(JSON.parse(localStorage.getItem("rel")), {
        gender_id: newValue,
        principal_gender_id: this.currentUser.gender_id,
      });

      this.heir.relationship_id = (this.relations.length == 1)
        ? this.relations[0].id
        : ((!!this.relations.find((el) => el['id'] == this.heir.relationship_id))
          ? this.heir.relationship_id
          : null);
    },

  },

  props: {
    propFormId: {
      type: Number,
      default: 0,
    },

    propSignature: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      stepNo: 0,
      errors: "",
      formId: null,

      heir: blankHeir(),
      city: null,
      race: [],
      banks: [],
      gender: [],
      salute: [],
      states: [],
      religion: [],
      relations: [],
      employerTypes: [],
      maritalStatus: [],
      houseOwnership: [],
      educationLevel: [],
      occupationTypes: [],
      cityHeirScoped: [],

      overlay: true,
      isLoading: false,
      proceedButton: false,
      flagInfoMsgDlg: false,
      flagErrMsgDlg: false,
      heirBirthDateMenu: false,

      phone: {
        heir_phone_num: {
          number: "",
          valid: false,
          mobile: false,
          country: undefined,
          color: "white",
        },

        heir_home_num: {
          number: "",
          valid: false,
          mobile: false,
          country: undefined,
          color: "white",
        },

        heir_office_num: {
          number: "",
          valid: false,
          mobile: false,
          country: undefined,
          color: "white",
        },
      },

      msgERR: {
        run: 0,
        nric: "",
        message: "",
      },

      msgINFO: {
        run: 0,
        nric: "",
        message: "",
      },

      mask_icNum: "######-##-####",
      mask_postCode: "#####",
      mask_phoneLand: "##-########",
      mask_phoneMobile: "###-########",

      inputColorOK: "cyan lighten-5",
      inputColorNG: "red lighten-4",
      exceptionList: ["email", "employer_email", "financing_calc", "signature"],

    };
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "currentUserRoles",
      "heirFormSession",
      "formSession",
    ]),

    stateProceed() {
      return !(this.agree_tnc && this.signature);
    },

    computedHeirBirthDate() {
      return this.heir.heir_birth_date
        ? dayjs(this.heir.heir_birth_date).format("DD/MM/YYYY")
        : "";
    },

    inputColorHeirPhoneNum() {
      return this.phone.heir_phone_num.color;
    },

    inputColorHeirHomeNum() {
      return this.phone.heir_home_num.color;
    },

    inputColorHeirOfficeNum() {
      return this.phone.heir_office_num.color;
    },
  },

  methods: {
    async init() {
      //nfh-console.log(' IN: INIT');
      await this.$store.dispatch(GET_LATEST_HEIR_FORM_SESSION)
        .then((resp) => {
          if (this.heirFormSession.id) {
            this.formId                = this.heirFormSession.id;
            this.heir.heir_name        = this.heirFormSession.heir_name;
            this.heir.heir_nric        = this.heirFormSession.heir_nric;
            this.heir.heir_birth_date  = this.heirFormSession.heir_birth_date;
            this.heir.heir_gender_id   = this.heirFormSession.heir_gender_id;
            this.heir.heir_race_id     = this.heirFormSession.heir_race_id;
            this.heir.heir_religion_id = this.heirFormSession.heir_religion_id;
            this.heir.heir_unit        = this.heirFormSession.heir_unit;
            this.heir.heir_street      = this.heirFormSession.heir_street;
            this.heir.heir_line3       = this.heirFormSession.heir_line3;
            this.heir.heir_line4       = this.heirFormSession.heir_line4;
            this.heir.heir_postcode    = this.heirFormSession.heir_postcode;
            this.heir.heir_city_id     = this.heirFormSession.heir_city_id;
            this.heir.heir_state_id    = this.heirFormSession.heir_state_id;
            this.heir.heir_phone_num   = this.heirFormSession.heir_phone_num;
            this.heir.heir_home_num    = this.heirFormSession.heir_home_num;
            this.heir.heir_office_num  = this.heirFormSession.heir_office_num;
            this.heir.relationship_id  = this.heirFormSession.relationship_id;
          }
          else {
            this.heir = blankHeir();
          }

          this.closeOverlay();
        })
        .catch((err) => {
          this.closeOverlay();
          this.promptError(err?.message);
        })
        .finally(() => {
          this.closeOverlay();
        });
      //nfh-console.log('OUT: INIT');
    },

    backStep() {
      this.$emit("onBack");
    },

    async proceedStep() {
      this.$refs.heirFormValidation.validate().then((success) => {
        if (success) {
          this.openOverlay();
          this.isLoading = true;
          convUpperCase(this.heir, this.exceptionList);
          const _context = {
            id: this.heirFormSession.id,
            heir_name: this.heir.heir_name,
            heir_nric: sanitize_nric(this.heir.heir_nric),
            heir_birth_date: this.heir.heir_birth_date,
            heir_gender_id: this.heir.heir_gender_id,
            heir_race_id: this.heir.heir_race_id,
            heir_religion_id: this.heir.heir_religion_id,
            relationship_id: this.heir.relationship_id,
            heir_unit: this.heir.heir_unit,
            heir_street: this.heir.heir_street,
            heir_line3: this.heir.heir_line3,
            heir_line4: this.heir.heir_line4,
            heir_postcode: this.heir.heir_postcode,
            heir_city_id: this.heir.heir_city_id,
            heir_state_id: this.heir.heir_state_id,
            heir_phone_num: this.heir.heir_phone_num,
            heir_home_num: this.heir.heir_home_num,
            heir_office_num: this.heir.heir_office_num,
          };

          this.$store.dispatch(CREATE_HEIR_SESSION, _context)
            .then((resp) => {
              // if (resp?.data?.success == true) {
              if (resp?.success == true) {
                setTimeout(() => {
                  KTUtil.scrollTop();
                  // this.formId = resp?.data?.data?.id;
                  this.formId = resp?.data?.id;
                  this.$emit("successProceed");
                  this.isLoading = false;
                  this.closeOverlay();
                  // this.heirFormSession.application_form = resp.data.data.application_form;
                }, 500);
              }
              else {
                this.isLoading = false;
                this.closeOverlay();
                // this.promptError(resp?.data?.message);
                this.promptError(resp?.message);
              }
            })
            .catch((err) => {
              this.isLoading = false;
              this.closeOverlay();
              this.promptError(err.message);
            })
            .finally(() => {
              this.isLoading = false;
              this.closeOverlay();
            });
        }
        else {
          this.closeOverlay();
          this.$nextTick(() => {
            this.$el.querySelector(".v-messages.error--text:first-of-type")?.scrollIntoView(false);
            return;
          });
        }
      });

      this.$emit("onProceed");
    },

    computedInfoNRICHeir() {
      const _resp = extractInfoNRIC(this.heir.heir_nric);
      //nfh-console.log('computedInfoNRICHeir: ', _resp);

      this.heir.heir_birth_date = _resp.birth_date;
      this.heir.heir_gender_id = _resp.gender_id;
    },

    // computedInfoNRICHeir() {
    //   if (this.heir.heir_nric && nric_dob(this.heir.heir_nric)) {
    //     this.heir.heir_birth_date = nric_dob(this.heir.heir_nric);
    //     const gdr = nric_gender(this.heir.heir_nric);

    //     this.gender.forEach((_) => {
    //       if (gdr == 1 && String(_.value)?.toUpperCase() == "M") {
    //         this.heir.heir_gender_id = _.id;
    //         this.heir.heir_salute_id = 1;
    //       }
    //       else if (gdr == 0 && String(_.value)?.toUpperCase() == "F") {
    //         this.heir.heir_gender_id = _.id;
    //       }
    //     });
    //   }
    // },

    onHeirCityChange(value) {
      if (
        this.city.find((ct) => {
          if (ct.id == value) {
            this.heir.heir_state_id = ct.state_id;
            this.cityHeirScoped = this.city.slice().filter((ct) =>
              ct.state_id === this.city.find((c) => c.id === value).state_id
            );
          }
        })
      );
    },

    onHeirStateChange(value) {
      this.cityHeirScoped = value
        ? this.city.slice().filter((ct) => ct.state_id === value)
        : this.city.slice();
    },

    onHeirPhoneNumInput(formattedNumber, { number, valid, country }) {
      this.phone.heir_phone_num.country = country;
      this.phone.heir_phone_num.number  = number?.national;
      this.phone.heir_phone_num.mobile  = parseInt(this.phone?.heir_phone_num?.number?.split('-')[0]) > 9;
      this.phone.heir_phone_num.valid   = valid && this.phone?.heir_phone_num?.mobile;
      this.phone.heir_phone_num.color   = (this.phone?.heir_phone_num?.valid) ? this.inputColorOK : this.inputColorNG;
    },

    onHeirHomeNumInput(formattedNumber, { number, valid, country }) {
      this.phone.heir_home_num.country = country;
      this.phone.heir_home_num.number  = number?.national;
      this.phone.heir_home_num.mobile  = parseInt(this.phone?.heir_home_num?.number?.split('-')[0]) > 9;
      this.phone.heir_home_num.valid   = valid && !this.phone?.heir_home_num?.mobile;
      this.phone.heir_home_num.color   = (this.phone?.heir_home_num?.valid) ? this.inputColorOK : this.inputColorNG;
    },

    onHeirOfficeNumInput(formattedNumber, { number, valid, country }) {
      this.phone.heir_office_num.country = country;
      this.phone.heir_office_num.number  = number?.national;
      this.phone.heir_office_num.mobile  = parseInt(this.phone?.heir_office_num?.number?.split('-')[0]) > 9;
      this.phone.heir_office_num.valid   = valid && !this.phone?.heir_office_num?.mobile;
      this.phone.heir_office_num.color   = (this.phone?.heir_office_num?.valid) ? this.inputColorOK : this.inputColorNG;
    },

    promptError(message) {
      this.msgERR.message = errorMessages(message);
      this.openErrMsgDialog();
    },

    promptInfo(message) {
      this.msgINFO.message = message;
      this.openInfoMsgDialog();
    },

    openInfoMsgDialog() {
      this.flagInfoMsgDlg = true;
    },

    openErrMsgDialog() {
      this.flagErrMsgDlg = true;
    },

    openOverlay() {
      this.overlay = true;
    },

    closeOverlay() {
      setTimeout(() => {
        this.overlay = false;
      }, 2000);
    },
  },

  async mounted() {
    //nfh-console.log(" IN:MOUNTED")
    this.city            = await JSON.parse(localStorage.getItem("cty")); //
    this.race            = await JSON.parse(localStorage.getItem("rce"));
    this.gender          = await JSON.parse(localStorage.getItem("gdr"));
    this.salute          = await JSON.parse(localStorage.getItem("sal"));
    this.states          = await JSON.parse(localStorage.getItem("stt")); //
    this.religion        = await JSON.parse(localStorage.getItem("rgn")); //
    this.relations       = await JSON.parse(localStorage.getItem("rel"));
    this.employerTypes   = await JSON.parse(localStorage.getItem("emp")); //
    this.maritalStatus   = await JSON.parse(localStorage.getItem("mry"));
    this.houseOwnership  = await JSON.parse(localStorage.getItem("own"));
    this.occupationTypes = await JSON.parse(localStorage.getItem("occ")); //
    this.cityHeirScoped  = this.city.slice();
    await this.init();
    //nfh-console.log("OUT:MOUNTED")
  },
}
</script>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";

.v-text-field--outlined {
  font-size: 11pt;
}

.v-text-field--outlined label {
  font-size: 11pt;
}

.ucase-text input {
  text-transform: uppercase;
}

.lcase-text input {
  text-transform: lowercase;
}

.checbox-decoration {
  margin: 0px;
}

.checbox-decoration .v-label {
  margin: 0px;
  font-size: 12px;
}

.snackbar-title {
  font-size: 14px;
  font-weight: 600;
}

.snackbar-subtitle {
  font-size: 12px;
}

.input-large {
  font-size: large;
}

#signaturePad {
  border: double 3px transparent;
  border-radius: 10px;
  border-width: 1;
  border-color: #000;
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>