<template>
  <v-row
    justify="center"
  >
    <v-col
      class="py-0"
      cols="12"
      sm="12"
      md="12"
      lg="12"
      xl="12"
    >
      <div
        class="card card-custom"
      >
        <div
          class="stepper"
        >
          <v-stepper
            v-model="applicationForm"
            rounded="lg"
            elevation="2"
            alt-labels
            tile
          >
            <v-stepper-header
            >
              <v-stepper-step
                step="1"
                :complete="applicationForm > 1"
                :editable="stepsBypassable"
                :color="stepperColor1"
              >
                <p
                  class="font-weight-bold font-size-sm"
                >
                  PERIBADI
                </p>
              </v-stepper-step>

              <v-divider
                class="pt-4"
              ></v-divider>

              <v-stepper-step
                step="2"
                :complete="applicationForm > 2"
                :editable="stepsBypassable"
                :color="stepperColor2"
              >
                <p
                  class="font-weight-bold font-size-sm"
                >
                  PEKERJAAN
                </p>
              </v-stepper-step>

              <v-divider
                class="pt-4"
              ></v-divider>

              <v-stepper-step
                step="3"
                :complete="applicationForm > 3"
                :editable="stepsBypassable"
                :color="stepperColor3"
              >
                <p
                  class="font-weight-bold font-size-sm"
                >
                  WARIS
                </p>
              </v-stepper-step>

              <v-divider
                class="pt-4"
              ></v-divider>

              <v-stepper-step
                step="4"
                :complete="applicationForm > 4"
                :editable="stepsBypassable"
                :color="stepperColor4"
              >
                <p
                  class="font-weight-bold font-size-sm"
                >
                  DOKUMEN
                </p>
              </v-stepper-step>

              <v-divider
                class="pt-4"
              ></v-divider>

              <v-stepper-step
                step="5"
                :complete="applicationForm > 5"
                :editable="stepsBypassable"
                :color="stepperColor5"
              >
                <p
                  class="font-weight-bold font-size-sm"
                >
                  TANDATANGAN
                </p>
              </v-stepper-step>

              <v-divider
                class="pt-4"
              ></v-divider>

              <v-stepper-step
                step="6"
                :complete="applicationForm > 6"
                :color="stepperColor6"
              >
                <p
                  class="font-weight-bold font-size-sm"
                >
                  SEMAK
                </p>
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content
                step="1"
              >
                <PersonalDetails
                  @successProceed="toNextForm()"
                >
                </PersonalDetails>
              </v-stepper-content>

              <v-stepper-content
                step="2"
              >
                <EmploymentDetails
                  @onBack="toPrevForm()"
                  @successProceed="toNextForm()"
                >
                </EmploymentDetails>
              </v-stepper-content>

              <v-stepper-content
                step="3"
              >
                <HeirDetails
                  @onBack="toPrevForm()"
                  @successProceed="toNextForm()"
                >
                </HeirDetails>
              </v-stepper-content>

              <v-stepper-content
                step="4"
              >
                <DocumentForm
                  :propId="currentId"
                  :propForm="formSession"
                  :propRoles="currentUserRoles"
                  :propDocList="checklistFull"
                  :propUseForm="true"
                ></DocumentForm>

                <div
                  class="pr-4 pl-2 pb-2 pt-10"
                  align="right"
                >
                  <v-btn
                    text
                    color="grey darken-1"
                    @click="toPrevForm"
                  >
                    <v-icon
                      left
                      dark
                    >
                      mdi-chevron-left
                    </v-icon>
                    Kembali
                  </v-btn>
                  <v-btn
                    class="ml-4"
                    color="primary"
                    :loading="isLoadingStep4Session"
                    @click="onSubmitStep4()"
                  >
                    Teruskan
                    <v-icon
                      right dark
                    >
                      mdi-chevron-right
                    </v-icon>
                  </v-btn>
                </div>
              </v-stepper-content>

              <v-stepper-content
                step="5"
              >
                <Signature
                  :propFormId="formId"
                  :propSignatureOptional="false"
                  @onBack="toPrevForm()"
                  @onProceed="onSubmitStep5A()"
                ></Signature>
              </v-stepper-content>

              <v-stepper-content
                step="6"
              >
                <Summary
                  @onBack="toPrevForm()"
                  @onProceed="onSubmitAndRequestAssistance()"
                ></Summary>
              </v-stepper-content>

            </v-stepper-items>
          </v-stepper>

          <AssistanceDialog
            v-model="openAssistanceDialog"
            :title="assistDialog.title"
            :content="assistDialog.content"
            :backButton="assistDialog.backButton"
            :proceedButton="assistDialog.proceedButton"
            :showBackButton="assistDialog.showBackButton"
            :showProceedButton="assistDialog.showProceedButton"
            @onProceed="onSubmitAndRequestAssistance"
          ></AssistanceDialog>

          <AssistanceDialog
            v-model="openAssistanceDialog2"
            :title="assistDialog2.title"
            :content="assistDialog2.content"
            :backButton="assistDialog2.backButton"
            :proceedButton="assistDialog2.proceedButton"
            :showBackButton="assistDialog2.showBackButton"
            :showProceedButton="assistDialog2.showProceedButton"
            @onProceed="doneNotifySalesCS"
          ></AssistanceDialog>

          <!-- <ConfirmationDialog
            v-model="openConfirmationDialog"
            :title="confirmDialog.title"
            :content="confirmDialog.content"
            :proceedButton="confirmDialog.proceedButton"
            :showBackButton="confirmDialog.showBackButton"
            :showProceedButton="confirmDialog.showProceedButton"
          ></ConfirmationDialog> -->

          <ConfirmationDialog
            v-model="confirmDialog.show"
            :title="confirmDialog.title"
            :content="confirmDialog.content"
            :backButton="confirmDialog.backButton"
            :proceedButton="confirmDialog.proceedButton"
            :delayOnProceed="confirmDialog.delayOnProceed"
            :showBackButton="confirmDialog.showBackButton"
            :showProceedButton="confirmDialog.showProceedButton"
            @onCancel="confirmDialog.cancel"
            @onProceed="confirmDialog.proceed"
          ></ConfirmationDialog>

            <!-- <VerifyEmailDialog v-model="openEmailVerifyDialog" :title="verifyEmailDialog.title"
            :proceedButton="verifyEmailDialog.proceedButton" :content="verifyEmailDialog.content"
            :showProceedButton="verifyEmailDialog.showProceedButton" :showBackButton="verifyEmailDialog.showBackButton"
            @onProceed="openEmailVerifyDialog = false">
          </VerifyEmailDialog> -->

          <ErrorMsgDialog
            v-model="msgERR.show"
            title="Ralat"
            proceedButton="OK"
            :content="msgERR.message"
            :showProceedButton="true"
            :showBackButton="false"
            :delayOnProceed="false"
            :isTypeError="true"
            @onProceed="msgERR.show = false"
          ></ErrorMsgDialog>

          <InfoMsgDialog
            v-model="openInfoMsgDialog"
            title="Info"
            proceedButton="OK"
            :content="msgINFO.message"
            :showProceedButton="true"
            :showBackButton="false"
            :delayOnProceed="false"
          ></InfoMsgDialog>

          <NotificationDialog
            v-model="openNotificationDialog"
            title="Permohonan Baru"
            backButton="Tolak"
            proceedButton="Terima"
            :showBackButton="true"
          ></NotificationDialog>

        </div>
      </div>
      <v-overlay
        :value="overlay"
      >
        <v-progress-circular
          indeterminate size="64"
        ></v-progress-circular>
      </v-overlay>
    </v-col>
  </v-row>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";
</style>

<script>
import {
  SET_BREADCRUMB,
} from "@/core/services/store/breadcrumbs.module";

import {
  GET_CHECKLIST_FULL,
  GET_LATEST_FORM_SESSION,
  UPDATE_ASSIST,
  UPDATE_NO_ASSIST,
  PURGE_ALL_FORM,
  // DELETE_FORM_SESSION,
} from "@/core/services/store/form.module";

import {
  CREATE_APPLICATION,
} from "@/core/services/store/application.module";

import {
  NOTIFY_NEW_ASSISTANCE_REQUEST,
} from "@/core/services/store/notification.module";

import {
  SET_CHECKLIST_STAT,
  PURGE_ALL_CHECKLIST,
} from "@/core/services/store/mediaupload.module";

import {
  errorMessages,
  formatAddress,
} from "@/core/services/helper.service";

import {
  mapGetters,
} from "vuex";

import PersonalDetails from "@/view/pages/form/new/PersonalDetails.vue";
import EmploymentDetails from "@/view/pages/form/new/EmploymentDetails.vue";
import HeirDetails from "@/view/pages/form/new/HeirDetails.vue";
import DocumentForm from "@/view/pages/form/DocumentForm.vue";
import Signature from "@/view/pages/form/new/Signature.vue";
import Summary from "@/view/pages/form/new/Summary.vue";

import KTUtil from "@/assets/js/components/util";
import AssistanceDialog from "@/view/pages/widgets/ConfirmationDialog.vue";
import NotificationDialog from "@/view/pages/widgets/NotificationDialog.vue";

export default {
  name: "Form",

  components: {
    NotificationDialog,
    AssistanceDialog,
    PersonalDetails,
    EmploymentDetails,
    HeirDetails,
    DocumentForm,
    Signature,
    Summary,
  },

  data() {
    return {
      overlay: true,
      errors: "",
      showOTP: false,

      msgOTP: {
        message: "",
        nric: "",
        run: 0,
      },

      agreeRequestAssistance: false,
      openAssistanceDialog: false,
      openAssistanceDialog2: false,
      // openConfirmationDialog: false,
      // openErrorMsgDialog: false,

      // Info Popup
      openInfoMsgDialog: false,

      msgINFO: {
        message: "",
        nric: "",
        run: 0,
      },

      msgERR: {
        title: "",
        message: "",
        show: false,
        nric: "",
        run: 0,
      },

      proceedButton: true,
      applicationForm: 1,

      // DateMenu
      userBirthDateMenu: false,
      employmentDateOfServiceDateMenu: false,
      heirBirthDateMenu: false,

      snackbarSignature: {
        visible: false,
        icon: "mdi-information",
        title: "Peringatan",
        text: "Pastikan tandatangan anda diisi sebelum meneruskan proses selajutnya",
        // timeout: 7500
      },

      snackbar: {
        visible: false,
        message: "",
        color: ""
      },

      isLoadingData: false,
      isLoadingEmploymentSession: false,
      isLoadingStep4Session: false,
      isLoadingStep5Session: false,
      openNotificationDialog: false,

      assistDialog: {
        title: "Perlu Bantuan?",
        content: "Anda memerlukan Bantuan Khidmat Pelanggan KOWAMAS untuk melengkapkan permohonan ini?\n\nJawab Ya jika anda mahu dihubungi oleh Pegawai Khidmat Pelanggan KOWAMAS bagi membantu anda melengkapkan permohonan ini.",
        proceedButton: "Ya",
        backButton: "Tidak",
        showProceedButton: true,
        showBackButton: true,
      },

      assistDialog2: {
        title: "Selesai",
        content: "Permohonan anda telah dimaklumkan kepada Pegawai Khidmat Pelanggan KOWAMAS.\n\nAnda akan dihubungi dalam tempoh 24 jam.\n\nTerima kasih.",
        proceedButton: "OK",
        showProceedButton: true,
        showBackButton: false,
      },

      assistDialog3: {
        title: "Selesai",
        content: "Permohonan anda perlu dilengkapkan sebelum dapat dihantar ke KOWAMAS untuk diproses.\n\nSekiranya permohonan anda tidak dihantar sebelum {tarikh_akhir} atau anda tidak memohon bantuan Khidmat Pelanggan KOWAMAS, ianya akan terbatal serta dihapuskan daripada sistem KOWAMAS untuk mengelakkan daripada disalah-guna.\n\nWalaubagaimanapun, anda boleh memohon semula.",
        proceedButton: "OK",
        showProceedButton: true,
        showBackButton: false,
      },

      // confirmDialog: {
      //   title: "Persetujuan",
      //   proceedButton: "Ya",
      //   content: " ",
      //   showProceedButton: true,
      //   showBackButton: true,
      // },

      confirmDialog: {
        show: false,
        title: "",
        content: "",
        showBackButton: false,
        showProceedButton: false,
        backButton: "",
        proceedButton: "",
        cancel: () => { },
        proceed: () => { },
      },

      verifyEmailDialog: {
        title: "Pengesahan E-mel",
        proceedButton: "Ok",
        content: "E-mel berjaya dihantar. Sila semak e-emel anda untuk pengesahan.",
        showProceedButton: true,
        showBackButton: false,
      },

    };
  },

  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventNav);
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "currentUserRoles",
      "formSession",
      "checklistMasters",
      "checklistFull",
      "stepsBypassable",
      "checklistStatStore",
      "checklistStore",
      "checklistDownloadStore",
      // "calculatedResult",
      "appId",
      "appId_dev",
    ]),

    currentId() {
      return this.$route.params.id ?? 0;
    },

    formId() {
      return this.formSession?.id;
    },

    stepperColor() {
      return this.applicationForm > 0 ? "success" : "primary";
    },
    stepperColor1() {
      return this.applicationForm > 1 ? "success" : "primary";
    },
    stepperColor2() {
      return this.applicationForm > 2 ? "success" : "primary";
    },
    stepperColor3() {
      return this.applicationForm > 3 ? "success" : "primary";
    },
    stepperColor4() {
      return this.applicationForm > 4 ? "success" : "primary";
    },
    stepperColor5() {
      return this.applicationForm > 5 ? "success" : "primary";
    },
    stepperColor6() {
      return this.applicationForm > 6 ? "success" : "primary";
    },

    backColorInput() {
      return this.backColorInputCheck;
    },

  },

  async mounted() {
    try {
      // For those who are internal users, cannot go to Form - throw to dashboard
      if (!(this.currentUserRoles.includes(4) || this.currentUserRoles.includes(5) || this.currentUserRoles.includes(6))) {
        this.$router.replace({
          name: "Dashboard"
        })
      }

      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "myE-Wawasan" },
        { title: "Mohon Pembiayaan" },
      ]);

      if (this.$route.query.message) {
        this.snackbar.visible = true;
        this.snackbar.message = $route.query.message;
        this.snackbar.color = "green lighten-1";
      }

      await this.init();
      this.snackbarSignature.visible = true;
      // this.isOTPNotILMS = !!!this.currentUser.phone_verified;
    } catch (err) {
      // this.promptError(err);
    } finally {
      this.closeOverlay();
    }
  },

  methods: {
    async init() {
      //nfh-console.log(' IN: INIT');
      // await this.$store.dispatch(GET_CHECKLIST_MASTERS);
      await this.$store.dispatch(GET_CHECKLIST_FULL);
      await this.$store.dispatch(GET_LATEST_FORM_SESSION);
        // .then((resp) => {
        //   this.formId = this.formSession.id;
        //   this.isLoadingData = false;
        // })
        // .catch((err) => {
        //   this.isLoadingData = false;
        //   this.promptError(err?.message);
        // });
      //nfh-console.log('OUT: INIT');
    },

    toNextForm() {
      this.applicationForm = Math.min(++this.applicationForm, 6);
    },

    toPrevForm() {
      this.applicationForm = Math.max(--this.applicationForm, 1);
    },

    async notifySalesCS() {
      // await this.$store.dispatch(CALCULATE, {
      //   salary: value.salary,
      //   deductions: value.deductions,
      //   employer: value.employerId,
      // })
      //   .then((response) => {
      //     this.$store.commit(SET_CALC_RESULT, response.data);
      //   });
    },

    async doneNotifySalesCS() {
      await this.$router.push({
        name: "EditForm",
        params: {
          id: resp.data?.data?.application?.id,
          tabIndex: 3,
        },
      });
    },

    preventNav(event) {
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = "";
    },

    remindSignature() {
      setTimeout(() => {
        this.snackbarSignature.visible = true;
      }, 2000);
    },

    promptError(message) {
      this.msgERR.message = errorMessages(message);
      this.msgERR.show = true;
      // this.openErrorMsgDialog = true;
    },

    promptInfo(message) {
      this.msgINFO.message = message;
      this.openInfoMsgDialog = true;
    },

    openOverlay() {
      this.overlay = true;
    },

    closeOverlay() {
      setTimeout(() => {
        this.overlay = false;
      }, 2000);
    },

    onSubmitStep4() {
      this.isLoadingStep4Session = true;

      if (this.checklistStatStore.remain > 0) {
        this.promptConfirmation(
          `Terdapat ${this.checklistStatStore.remain} dokumen wajib yang masih belum dimuat-naik. Pegawai Khidmat Pelanggan kami boleh bantu membimbing anda untuk melengkapkan dokumen-dokumen yang diperlukan.

          Anda mahu Pegawai Khidmat Pelanggan KOWAMAS menghubungi anda dalam masa terdekat ?
          `,
          "Bantuan Khidmat Pelanggan",
          this.onSubmitStep4A,
          this.onCancelSubmitStep4(),
        );
      }
      else {
        this.onSubmitStep4B();
      }
    },

    onCancelSubmitStep4() {
      this.isLoadingStep4Session = false;
      const _newStat = Object.assign({}, this.checklistStatStore);
      _newStat.needCS = false;
      this.$store.commit(SET_CHECKLIST_STAT, _newStat);
      this.$store.dispatch(UPDATE_NO_ASSIST, { id: this.formSession.id });
      this.closeConfirmation();
    },

    // this.checklistStatStore.remain > 0
    onSubmitStep4A() {
      // setTimeout(() => {
        KTUtil.scrollTop();
        this.closeConfirmation();
        this.isLoadingStep4Session = false;
        this.agreeRequestAssistance = true;
        const _newStat = Object.assign({}, this.checklistStatStore);
        _newStat.needCS = true;
        this.$store.commit(SET_CHECKLIST_STAT, _newStat);
        this.$store.dispatch(UPDATE_ASSIST, { id: this.formSession.id });
        this.closeOverlay();
        this.toNextForm();
        //nfh-console.log("onSubmitStep4A", this.checklistStatStore, this.checklistStore, this.checklistDownloadStore);
      // }, 500);
    },

    onSubmitStep4B() {
      // setTimeout(() => {
        KTUtil.scrollTop();
        this.isLoadingStep4Session = false;
        this.agreeRequestAssistance = false;
        this.closeOverlay();
        this.toNextForm();
        //nfh-console.log("onSubmitStep4B", this.checklistStatStore, this.checklistStore, this.checklistDownloadStore);
      // }, 500);
    },

    async onSubmitStep5A(value) {
      await this.$store.dispatch(GET_CHECKLIST_FULL);
      await this.$store.dispatch(GET_LATEST_FORM_SESSION)
        .then((resp) => {
          this.toNextForm();
        });
      // this.user.signature = value;
      // this.openAssistanceDialog2 = true;
      //nfh-console.log("onSubmitStep5A");
    },

    movingToEditForm(appId) {
      this.$router.push({
        name: "EditForm",
        params: {
          id: appId,
          tabIndex: 3,
        },
      });
    },

    movingToDashboard(appId) {
      this.$router.push({
        name: "Dashboard",
      });
    },

    async onSubmitStep6() {
      if (this.formSession) {
        this.isLoadingStep5Session = true;
        const _context = {
          form_session_id: this.formSession.id,
          from: this.$route.path,
          next: "/edit-form",
        };

        setTimeout(async () => {
          this.openOverlay();

          await this.$store.dispatch(CREATE_APPLICATION, _context)
            .then(async (resp) => {
              if (resp.data.success == true) {
                // await this.$store.dispatch(NOTIFY_NEW_APPLICATION, {
                //   application_id: resp.data.data.application.id,
                //   from: "EditForm",
                //   next: "EditForm"

                // }).then((response) => {
                //   if(response.data.success == true)  {
                this.closeOverlay();
                this.isLoadingStep5Session = false;
                this.movingToDashboard(resp.data?.data?.id);
                // this.$router.push({
                //   name: "EditForm",
                //   params: {
                //     // id: resp.data.data.application.id,
                //     id: resp.data.data.id,
                //     tabIndex: 3,
                //   },
                // });

                // }
                // })
                // this.$store
                //   .dispatch(DELETE_FORM_SESSION, this.formSession.id)
                //   .then((_resp) => {
                //     console.log(_resp);
                //   });
              } else {
                this.isLoadingStep5Session = false;
                this.promptError(resp?.data?.message);
                this.closeOverlay();
              }
            })
            .catch((err) => {
              this.isLoadingStep5Session = false;
              this.promptError(err?.message);
              this.closeOverlay();
            })
            .finally(() => {
              this.isLoadingStep5Session = false;
              this.closeOverlay();
            });
        }, 500);
      }
    },

    async onSubmitAndRequestAssistance() {
      if (this.formSession.id) {
        this.isLoadingStep5Session = true;

        setTimeout(async () => {
          this.openAssistanceDialog = false;
          this.openOverlay();

          const _context = {
            form_session_id: this.formSession.id,
            from: this.$route.path,
            next: "/edit-form",
          };
          var _appId = 0;

          await this.$store.dispatch(CREATE_APPLICATION, _context)
            .then(async (resp) => {
              //nfh-console.log("[onSubmitAndRequestAssistance] CREATE_APPLICATION resp: ", resp);

              if (resp?.success == true) {
                _appId = resp?.data?.id;
                const _params = {
                  application_id: _appId,
                  from: 'Form',
                  next: 'EditForm',
                };

                if (_appId) {
                  if (this.agreeRequestAssistance) {
                    await this.$store.dispatch(NOTIFY_NEW_ASSISTANCE_REQUEST, _params)
                      .then((resp2) => {
                        //nfh-console.log("[onSubmitAndRequestAssistance] NOTIFY_NEW_ASSISTANCE_REQUEST resp2: ", resp2)

                        if (resp2?.success == true) {
                          this.closeOverlay();
                          this.isLoadingStep5Session = false;
                          this.openAssistanceDialog2 = true;

                          this.promptInfo(`Permohonan anda telah diterima dan bantuan Khidmat Pelanggan akan menghubungi anda dalam masa terdekat.

                          Terima kasih`);

                          setTimeout(() => {
                            this.movingToDashboard(_appId);
                          }, 5000);
                        }
                        // else {
                        //   this.isLoadingStep5Session = false;
                        //   this.promptError(resp2?.message);
                        //   console.log("[onSubmitAndRequestAssistance] NOTIFY_NEW_ASSISTANCE_REQUEST failed: ", resp2?.message);
                        //   this.closeOverlay();
                        //   this.movingToDashboard();
                        // }
                      })
                      // .catch((err) => {
                      //   this.isLoadingStep5Session = false;
                      //   this.promptError(err?.message);
                      //   this.closeOverlay();
                      //   console.log("[onSubmitAndRequestAssistance] NOTIFY_NEW_ASSISTANCE_REQUEST catch Error: ");
                      //   this.movingToDashboard();
                      // })
                      // .finally(() => {
                      //   console.log("[onSubmitAndRequestAssistance] NOTIFY_NEW_ASSISTANCE_REQUEST finally");
                      //   this.isLoadingStep5Session = false;
                      //   this.closeOverlay();
                      //   this.movingToDashboard(_appId);
                      // });
                  }
                  else {
                    this.closeOverlay();
                    this.isLoadingStep5Session = false;
                    this.openAssistanceDialog2 = true;
                    this.promptInfo(resp?.message);
                    //nfh-console.log("[onSubmitAndRequestAssistance] CREATE_APPLICATION Done without notification to CS: ");
                    this.movingToDashboard(_appId);
                  }
                }
                else {
                  // this.promptError("");
                  //nfh-console.log("[onSubmitAndRequestAssistance] CREATE_APPLICATION _appId invalid, resp: ", _appId, resp);
                }
              }
              // else {
              //   switch (resp?.errcode) {
              //     case "application_creation_failed":
              //       this.promptError("Terdapat ganguan terhadap sistem menyebabkan permohonan ada gagal disimpan.\n\nSila cuba semula dalam beberapa minit.");
              //       break;

              //     // case "form_session_not_found":
              //     // case "no_credential_params":
              //     // case "catch_error":
              //     default:
              //       this.promptError(resp?.message);
              //       console.log("[onSubmitAndRequestAssistance] CREATE_APPLICATION failed: ", resp?.message);
              //   }
              //   this.isLoadingStep5Session = false;
              //   this.closeOverlay();
              //   this.movingToDashboard();
              // }
            })
            // .catch((err) => {
            //   this.isLoadingStep5Session = false;
            //   this.promptError(err?.message);
            //   console.log("[onSubmitAndRequestAssistance] CREATE_APPLICATION catch Err: ", err);
            //   this.closeOverlay();
            //   this.movingToDashboard();
            // })
            .finally(() => {
              //nfh-console.log("[onSubmitAndRequestAssistance] CREATE_APPLICATION finally");
              this.isLoadingStep5Session = false;
              this.closeOverlay();
              this.$store.commit(PURGE_ALL_FORM);
              this.$store.commit(PURGE_ALL_CHECKLIST);
              this.movingToDashboard();
            });
        }, 2000);
      }
    },

    promptConfirmation(message, title = "Pengesahan", cbProceed = () => { }, cbCancel = () => { }) {
      this.confirmDialog.title = title;
      this.confirmDialog.content = message;
      this.confirmDialog.cancel = cbCancel;
      this.confirmDialog.proceed = cbProceed;
      this.confirmDialog.backButton = "Tidak";
      this.confirmDialog.proceedButton = "Ya";
      this.confirmDialog.showBackButton = true;
      this.confirmDialog.showProceedButton = true;
      this.confirmDialog.delayOnProceed = false;
      this.confirmDialog.show = true;
      //nfh-console.log("promptConfirmation() confirmDialog: ", this.confirmDialog);
    },

    closeConfirmation() {
      this.confirmDialog.cancel = () => { };
      this.confirmDialog.proceed = () => { };
      this.confirmDialog.title = "";
      this.confirmDialog.content = "";
      this.confirmDialog.backButton = "";
      this.confirmDialog.proceedButton = "";
      this.confirmDialog.showBackButton = false;
      this.confirmDialog.showProceedButton = false;
      this.confirmDialog.show = false;
      //nfh-console.log("closeConfirmation() confirmDialog: ", this.confirmDialog);
    },

    goToError(_ref) {
      setTimeout(() => {
        const errors = Object.entries(_ref.errors)
          .map(([key, value]) => ({ key, value }))
          .filter((error) => error["value"].length);

        _ref.refs[errors[0]["key"]].$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 200);
    },

    updated() {
      //nfh-console.log(" IN:UPDATED");
      //nfh-console.log("OUT:UPDATED");
    },

    activated() {
      //nfh-console.log(" IN:ACTIVATED");
      //nfh-console.log("OUT:ACTIVATED");
    },

    deactivated() {
      //nfh-console.log(" IN:DEACTIVATED");
      //nfh-console.log("OUT:DEACTIVATED");
    },

    unmounted() {
      //nfh-console.log(" IN:UNMOUNTED");
      //nfh-console.log("OUT:UNMOUNTED");
    },

    destroyed() {
      //nfh-console.log(" IN:DESTROYED");
      //nfh-console.log("OUT:DESTROYED");
    },

    beforeCreate() {
      //nfh-console.log(" IN:BEFORECREATE");
      //nfh-console.log("OUT:BEFORECREATE");
    },

    beforeMounted() {
      //nfh-console.log(" IN:BEFOREMOUNTED");
      //nfh-console.log("OUT:BEFOREMOUNTED");
    },

    beforeUpdate() {
      //nfh-console.log(" IN:BEFOREUPDATE");
      //nfh-console.log("OUT:BEFOREUPDATE");
    },

    beforeDestroy() {
      //nfh-console.log(" IN:BEFOREDESTROY");
      //nfh-console.log("OUT:BEFOREDESTROY");
    },

    beforeUnmounted() {
      //nfh-console.log(" IN:BEFOREUNMOUNTED");
      //nfh-console.log("OUT:BEFOREUNMOUNTED");
    },

    async created() {
      //nfh-console.log(" IN:CREATED");
      //nfh-console.log("OUT:CREATED");
    },
  },
};
</script>

<style>
.v-text-field--outlined {
  font-size: 11pt;
}

.v-text-field--outlined label {
  font-size: 11pt;
}

/* .v-text-field--outlined input {
    font-size: 14px;
  } */

/* .v-text-field--outlined .v-text-field__prefix {
    font-size: 14px; */
/* } */

.ucase-text input {
  text-transform: uppercase;
}

.lcase-text input {
  text-transform: lowercase;
}

.checbox-decoration {
  margin: 0px;
}

.checbox-decoration .v-label {
  margin: 0px;
  font-size: 12px;
}

.snackbar-title {
  font-size: 14px;
  font-weight: 600;
}

.snackbar-subtitle {
  font-size: 12px;
}

.input-large {
  font-size: large;
}

#signature {
  border: double 3px transparent;
  border-radius: 10px;
  border-width: 1;
  border-color: #000;
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>
