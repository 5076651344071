<template>
  <div>
    <v-card class="pa-2" height="auto" elevation="0">
      <ValidationObserver ref="employmentFormValidation">
        <v-form class="font-weight-bold font-size-sm">
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="12">
                <h5 class="primary--text font-weight-bold">
                  Maklumat Pekerjaan
                </h5>
                <v-divider class="mt-0"></v-divider>
              </v-col>

              <v-col class="py-0" cols="12" md="8">
                <ValidationProvider v-slot="{ errors }" name="Nama Majikan" rules="required">
                  <v-text-field v-model="localEmployment.employer_name" :error-messages="errors" class="ucase-text"
                    label="Nama Majikan" outlined dense required></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col class="py-0" cols="12" md="4">
                <ValidationProvider v-slot="{ errors }" name="Kategori Majikan" rules="required">
                  <v-select v-model="localEmployment.employer_type_id" :items="employerTypes" item-text="name" item-value="id"
                    :error-messages="errors" label="Kategori Majikan" outlined dense required="18"></v-select>
                </ValidationProvider>
              </v-col>

              <v-col class="py-0" cols="12" md="6">
                <ValidationProvider v-slot="{ errors }" name="Pekerjaan" rules="required">
                  <v-text-field v-model="localEmployment.occupation" :error-messages="errors" label="Pekerjaan"
                    class="ucase-text" outlined dense required></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col class="py-0" cols="12" md="6">
                <ValidationProvider v-slot="{ errors }" name="Kategori Pekerjaan" rules="required">
                  <v-select v-model="localEmployment.occupation_type_id" :items="occupationTypes" item-text="nameMS"
                    item-value="id" :error-messages="errors" label="Kategori Pekerjaan" outlined dense
                    required="18"></v-select>
                </ValidationProvider>
              </v-col>

              <v-col class="py-0" cols="12" md="6">
                <ValidationProvider v-slot="{ errors }" name="Bahagian / Unit" rules="required">
                  <v-text-field v-model="localEmployment.department" :error-messages="errors" class="ucase-text"
                    label="Bahagian/ Unit" outlined dense required></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col class="py-0" cols="12" md="4">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Pendapatan Sebulan"
                  rules="required"
                >
                  <vuetify-money
                    v-model="localEmployment.salary"
                    v-bind:label="salaryLabel"
                    v-bind:placeholder="salaryPlaceholder"
                    v-bind:readonly="moneyReadOnly"
                    v-bind:disabled="salaryFreezed"
                    v-bind:outlined="moneyOutlined"
                    v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                    v-bind:options="salaryOpt"
                    v-bind:properties="moneyProperties"
                    v-bind:clearable="false"
                    :error-messages="errors"
                    :dense="moneyDense"
                  />
                </ValidationProvider>
              </v-col>

              <v-col class="py-0" cols="12" md="4">
                <ValidationProvider v-slot="{ errors }" name="Tarikh Mula Bekerja" rules="required">
                  <v-menu v-model="dateOfServiceMenu" :error-messages="errors" :close-on-content-click="false"
                    :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field :value="computedDateOfService" v-mask="mask_date" label="Tarikh Mula Bekerja" outlined
                        dense readonly clearable v-bind="attrs" v-on="on" required></v-text-field>
                    </template>
                    <v-date-picker locale="ms-my" v-model="localEmployment.commencement_date" :first-day-of-week="1" @input="dateOfServiceMenu = true"
                      :max="maxDateEligibleWork" v-on:change="onCommenceDateChange" :value="maxDateEligibleWork">
                    </v-date-picker>
                  </v-menu>
                </ValidationProvider>
              </v-col>

              <v-col class="py-0" cols="12" md="4">
                <ValidationProvider v-slot="{ errors }" name="Tahun Perkhidmatan" rules="required|numeric">
                  <v-text-field v-model="localEmployment.year_of_service" :error-messages="errors" label="Tahun Perkhidmatan"
                    hint="Dikira berdasarkan dari tarikh mula bekerja" outlined dense filled readonly required>
                  </v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" md="12">
                <h5 class="primary--text font-weight-bold">
                  Alamat Majikan
                </h5>
                <v-divider class="mt-0"></v-divider>
              </v-col>

              <v-col class="py-0" cols="12" md="12">
                <ValidationProvider v-slot="{ errors }" name="Unit/Lot/No./Tingkat" rules="required">
                  <v-text-field hint="Contoh A1-2-3" v-model="localEmployment.office_unit" :error-messages="errors"
                    label="Unit/Lot/No./Tingkat" class="ucase-text" outlined dense required>
                  </v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col class="py-0" cols="12" md="12">
                <ValidationProvider v-slot="{ errors }" name="Bangunan/Jalan" rules="required">
                  <v-text-field hint="Wisma Persekutuan, Jalan Damansara" v-model="localEmployment.office_street"
                    :error-messages="errors" label="Bangunan/Jalan" class="ucase-text" outlined dense required>
                  </v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col class="py-0" cols="12" md="12">
                <ValidationProvider v-slot="{ errors }" name="SButiran Tambahan 1 (O)" rules="">
                  <v-text-field hint="Masukkan butiran alamat sekiranya ada" v-model="localEmployment.office_line3"
                    :error-messages="errors" label="Butiran Tambahan Sekiranya Ada (tidak wajib)" class="ucase-text"
                    outlined dense>
                  </v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col class="py-0" cols="12" md="12">
                <ValidationProvider v-slot="{ errors }" name="Butiran Tambahan 2 (O))" rules="">
                  <v-text-field hint="Masukkan butiran alamat sekiranya ada" v-model="localEmployment.office_line4"
                    :error-messages="errors" label="Butiran Tambahan Sekiranya Ada (tidak wajib)" class="ucase-text"
                    outlined dense></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col class="py-0" cols="12" md="4">
                <ValidationProvider v-slot="{ errors }" name="Poskod" rules="required">
                  <v-text-field v-model="localEmployment.office_postcode" :error-messages="errors" v-mask="mask_postCode"
                    label="Poskod" outlined dense required></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col class="py-0" cols="12" md="8">
                <ValidationProvider v-slot="{ errors }" name="Bandar" rules="required">
                  <v-select v-model="localEmployment.office_city_id" :items="cityOfficeScoped" item-text="display"
                    item-value="id" :error-messages="errors" hint="Bandar mengikut poskod" label="Bandar"
                    class="ucase-text" outlined dense required v-on:change="onOfficeCityChange">
                  </v-select>
                </ValidationProvider>
              </v-col>

              <v-col class="py-0" cols="12" md="8">
                <ValidationProvider v-slot="{ errors }" name="Negeri" rules="required">
                  <v-select v-model="localEmployment.office_state_id" :items="states" item-text="name" item-value="id"
                    :error-messages="errors" label="Negeri" class="ucase-text" outlined dense required
                    v-on:change="onOfficeStateChange">
                  </v-select>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" md="12">
                <h5 class="primary--text font-weight-bold">
                  Telefon & E-Mel (Majikan)
                </h5>
                <v-divider class="mt-0"></v-divider>
              </v-col>

              <v-col class="py-0" cols="12" md="6">
                <ValidationProvider v-slot="{ errors }" name="Telefon Pejabat" rules="required">
                  <vue-tel-input-vuetify v-model="localEmployment.office_num" label="No. Telefon Pejabat"
                    v-mask="mask_phoneLand" placeholder="No. Telefon Pejabat" hint="Contoh: 03-1234 5678 / 04-123 4567"
                    defaultCountry="MY" :onlyCountries="['MY']" :error-messages="errors"
                    :background-color="inputColorOfficeNum" @input="onOfficeNumInput" dense outlined
                    required></vue-tel-input-vuetify>
                </ValidationProvider>
              </v-col>

              <v-col class="py-0" cols="12" md="6">
                <vue-tel-input-vuetify v-model="localEmployment.office_fax" placeholder="No. Faks" label="No. Faks"
                  v-mask="mask_phoneLand" hint="Contoh: 03-1234 5678 / 04-123 4567" defaultCountry="MY"
                  :onlyCountries="['MY']" :error-messages="errors" :background-color="inputColorOfficeFax"
                  @input="onOfficeFaxInput" dense outlined></vue-tel-input-vuetify>
              </v-col>

              <v-col class="py-0" cols="12" md="8">
                <ValidationProvider v-slot="{ errors }" name="E-Mel Majikan" rules="required">
                  <v-text-field v-model="localEmployment.employer_email" label="E-Mel Majikan :"
                    hint="Contoh: jabatan@organisasi.gov.my" placeholder="jabatan@organisasi.gov.my"
                    :error-messages="errors" outlined dense required></v-text-field>
                </ValidationProvider>
              </v-col>

            </v-row>
          </v-container>
        </v-form>
      </ValidationObserver>
    </v-card>

    <div align="right" class="pr-4 pl-2 pb-2 pt-10">
      <v-btn text color="grey darken-1" @click="backStep">
        <v-icon left dark> mdi-chevron-left </v-icon>
        Kembali
      </v-btn>
      <v-btn class="ml-4" color="primary" :loading="isLoading" @click="proceedStep()">
        Teruskan
        <v-icon right dark> mdi-chevron-right </v-icon>
      </v-btn>
    </div>

    <!-- <ErrorMsgDialog
      v-model="flagErrMsgDlg"
      title="Ralat"
      :content="msgERR.message"
      :proceedButton="'OK'"
      :showProceedButton=true
      :showBackButton=false
      :delayOnProceed="false"
      :isTypeError="true"
    ></ErrorMsgDialog>

    <InfoMsgDialog
      v-model="flagInfoMsgDlg"
      title="Info"
      :content="msgINFO.message"
      :proceedButton="'OK'"
      :showProceedButton=true
      :showBackButton=false
      :delayOnProceed="false"
    ></InfoMsgDialog> -->

    <InfoMsgDialog
      v-model="messageDialog.show"
      :title="messageDialog.title"
      :content="messageDialog.content"
      :backButton="messageDialog.backButton"
      :proceedButton="messageDialog.proceedButton"
      :showBackButton="messageDialog.showBackButton"
      :showProceedButton="messageDialog.showProceedButton"
      :delayOnProceed=false
      :isTypeError="false"
      @onBacking="messageDialog.cancel"
      @onProceed="messageDialog.proceed"
    ></InfoMsgDialog>

    <ErrorMsgDialog
      v-model="errorDialog.show"
      :title="errorDialog.title"
      :content="errorDialog.content"
      :backButton="errorDialog.backButton"
      :proceedButton="errorDialog.proceedButton"
      :showBackButton="errorDialog.showBackButton"
      :showProceedButton="errorDialog.showProceedButton"
      :delayOnProceed=false
      :isTypeError="true"
      @onBacking="errorDialog.cancel"
      @onProceed="errorDialog.proceed"
    ></ErrorMsgDialog>

    <v-overlay
      :value="overlay"
    >
      <v-progress-circular
        indeterminate size="64"
      ></v-progress-circular>
    </v-overlay>

  </div>
</template>

<script>
import {
  mapGetters,
} from "vuex";

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

import {
  required,
  email,
  max,
  numeric,
} from "vee-validate/dist/rules";

import {
  convUpperCase,
  errorMessages,
  formatAddress,
  blankEmployment,
  emailFormatCheck,
} from "@/core/services/helper.service";

import {
  CREATE_EMPLOYMENT_SESSION,
  GET_LATEST_EMPLOYMENT_FORM_SESSION,
} from "@/core/services/store/form.module";

import dayjs from "dayjs";
import KTUtil from "@/assets/js/components/util";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} tidak boleh dibiarkan kosong",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("email", {
  ...email,
  message: "E-mel mestilah sah",
});

extend("numeric", {
  ...numeric,
  message: "Nombor sahaja dibenarkan",
});

export default {
  name: "EmploymentDetails",

  components: {
    ValidationObserver,
    ValidationProvider,
  },

  watch: {
    propSignature: function (newValue, oldValue) {
      this.signature = newValue;
    },

    employmentFormSession: function (newValue, oldValue) {
      //nfh-console.log("[WATCH] employmentFormSession: newValue, oldValue: ", newValue, oldValue);
      if (newValue?.id) {
        this.formId = newValue?.id;
        this.localEmployment = newValue;
        // this.localEmployment.employer_name      = newValue?.employer_name;
        // this.localEmployment.employer_email     = newValue?.employer_email;
        // this.localEmployment.employer_type_id   = newValue?.employer_type_id;
        // this.localEmployment.occupation         = newValue?.occupation;
        // this.localEmployment.occupation_type_id = newValue?.occupation_type_id;
        // this.localEmployment.department         = newValue?.department;
        // this.localEmployment.office_unit        = newValue?.office_unit;
        // this.localEmployment.office_street      = newValue?.office_street;
        // this.localEmployment.office_line3       = newValue?.office_line3;
        // this.localEmployment.office_line4       = newValue?.office_line4;
        // this.localEmployment.office_postcode    = newValue?.office_postcode;
        // this.localEmployment.office_city_id     = newValue?.office_city_id;
        // this.localEmployment.office_state_id    = newValue?.office_state_id;
        // this.localEmployment.office_num         = newValue?.office_num;
        // this.localEmployment.office_fax         = newValue?.office_fax;
        // this.localEmployment.salary             = newValue?.salary;
        // this.localEmployment.commencement_date  = newValue?.commencement_date;
        // this.localEmployment.year_of_service    = newValue?.year_of_service;
        //nfh-console.log("[WATCH] employmentFormSession: this.localEmployment: ", this.localEmployment);
      }
    },
  },

  props: {
    propFormId: {
      type: Number,
      default: 0,
    },

    propSignature: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      stepNo: 0,
      errors: "",

      localEmployment: blankEmployment(),
      city: null,
      race: [],
      banks: [],
      gender: [],
      salute: [],
      states: [],
      religion: [],
      relations: [],
      employerTypes: [],
      maritalStatus: [],
      houseOwnership: [],
      educationLevel: [],
      occupationTypes: [],
      cityHeirScoped: [],
      cityHomeScoped: [],
      cityOfficeScoped: [],
      cityMailingScoped: [],

      overlay: true,
      isLoading: false,
      proceedButton: false,
      // flagInfoMsgDlg: false,
      // flagErrMsgDlg: false,
      dateOfServiceMenu: false,
      moneyDense: true,
      moneyReadOnly: false,
      moneyDisabled: false,
      moneyOutlined: true,

      reqMoneyWhenEmpty: "",
      moneyWhenEmpty: "0.00",
      salaryLabel: "Pendapatan Sebulan",
      salaryPlaceholder: "Gaji Kasar",
      salaryHint: "Masukkan Nilai Gaji Kasar Sebelum Penolakan",

      messageDialog: {
        show: false,
        title: "Info",
        content: "",
        showBackButton: false,
        showProceedButton: true,
        backButton: "",
        proceedButton: "OK",
        isTypeError: false,
        cancel: () => { },
        proceed: () => { },
      },

      errorDialog: {
        show: false,
        title: "Ralat",
        content: "",
        showBackButton: false,
        showProceedButton: true,
        backButton: "",
        proceedButton: "OK",
        isTypeError: true,
        cancel: () => { },
        proceed: () => { },
      },

      salaryOpt: {
        locale: "ms-MY",
        prefix: "RM",
        suffix: "",
        length: 9,
        precision: 2,
        min: 500,
        max: 1000000,
      },

      moneyProperties: {
        error: true,
        rules: [
          "required"
        ]
      },

      phone: {
        office_num: {
          number: "",
          valid: false,
          mobile: false,
          country: undefined,
          color: "white",
        },

        office_fax: {
          number: "",
          valid: false,
          mobile: false,
          country: undefined,
          color: "white",
        },
      },

      snackbarSignature: {
        visible: false,
        icon: "mdi-information",
        title: "Peringatan",
        text: "Pastikan tandatangan anda diisi sebelum meneruskan proses selajutnya",
      },

      msgERR: {
        run: 0,
        nric: "",
        message: "",
      },

      msgINFO: {
        run: 0,
        nric: "",
        message: "",
      },

      mask_postCode: "#####",
      mask_phoneLand: "##-########",
      mask_phoneMobile: "###-########",
      mask_yearServing: "##",
      mask_date: "##/##/####",

      inputColorOK: "cyan lighten-5",
      inputColorNG: "red lighten-4",
      backColorInputCheck: "white",
      exceptionList: ["email", "employer_email", "financing_calc", "signature"],

    };
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "currentUserRoles",
      "employmentFormSession",
      "formSession",
    ]),

    salaryFreezed() {
      return !!(this.formSession.financing_req_amount > 0);
    },

    stateProceed() {
      return !(this.agree_tnc && this.signature);
    },

    computedDateOfService() {
      return this?.localEmployment?.commencement_date
        ? dayjs(this.localEmployment.commencement_date).format("DD/MM/YYYY")
        : "";
    },

    maxDateEligibleApply() {
      return dayjs().subtract(18, "year").format("YYYY-MM-DD");
    },

    maxDateEligibleWork() {
      return dayjs().subtract(2, "month").endOf("month").format("YYYY-MM-DD");
    },

    inputColorOfficeNum() {
      return this.phone.office_num.color;
    },

    inputColorOfficeFax() {
      return this.phone.office_fax.color;
    },

  },

  methods: {
    async init() {
      //nfh-console.log(' IN: INIT');
      await this.$store
        .dispatch(GET_LATEST_EMPLOYMENT_FORM_SESSION)
        .then((resp) => {
          if (this.employmentFormSession.id) {
            this.formId                             = this.employmentFormSession.id;
            this.localEmployment.employer_name      = this.employmentFormSession.employer_name;
            this.localEmployment.employer_email     = this.employmentFormSession.employer_email;
            this.localEmployment.employer_type_id   = this.employmentFormSession.employer_type_id;
            this.localEmployment.occupation         = this.employmentFormSession.occupation;
            this.localEmployment.occupation_type_id = this.employmentFormSession.occupation_type_id;
            this.localEmployment.department         = this.employmentFormSession.department;
            this.localEmployment.office_unit        = this.employmentFormSession.office_unit;
            this.localEmployment.office_street      = this.employmentFormSession.office_street;
            this.localEmployment.office_line3       = this.employmentFormSession.office_line3;
            this.localEmployment.office_line4       = this.employmentFormSession.office_line4;
            this.localEmployment.office_postcode    = this.employmentFormSession.office_postcode;
            this.localEmployment.office_city_id     = this.employmentFormSession.office_city_id;
            this.localEmployment.office_state_id    = this.employmentFormSession.office_state_id;
            this.localEmployment.office_num         = this.employmentFormSession.office_num;
            this.localEmployment.office_fax         = this.employmentFormSession.office_fax;
            this.localEmployment.salary             = this.employmentFormSession.salary;
            this.localEmployment.commencement_date  = this.employmentFormSession.commencement_date;
            this.localEmployment.year_of_service    = this.employmentFormSession.year_of_service;
          }
          else {
            this.localEmployment = blankEmployment();
          }

          this.onCommenceDateChange();
          this.closeOverlay();
        })
        .catch((err) => {
          this.closeOverlay();
          this.promptError(err?.message);
        })
        .finally(() => {
          this.closeOverlay();
        });
      //nfh-console.log('OUT: INIT');
    },

    proceedButtonEnabler() {
      this.proceedButton = (this.agree_tnc && this.signature);
    },

    backStep() {
      this.$emit("onBack");
    },

    async proceedStep() {
      this.$refs.employmentFormValidation.validate()
        .then((success) => {
          if (success) {
            this.isLoading = true;
            this.openOverlay();
            convUpperCase(this.localEmployment, this.exceptionList);

            const _context = {
              id: this.employmentFormSession.id,
              employer_name: this.localEmployment.employer_name,
              employer_email: this.localEmployment.employer_email,
              employer_type_id: this.localEmployment.employer_type_id,
              occupation: this.localEmployment.occupation,
              occupation_type_id: this.localEmployment.occupation_type_id,
              department: this.localEmployment.department,
              office_unit: this.localEmployment.office_unit,
              office_street: this.localEmployment.office_street,
              office_line3: this.localEmployment.office_line3,
              office_line4: this.localEmployment.office_line4,
              office_postcode: this.localEmployment.office_postcode,
              office_city_id: this.localEmployment.office_city_id,
              office_state_id: this.localEmployment.office_state_id,
              office_num: this.localEmployment.office_num,
              office_fax: this.localEmployment.office_fax,
              salary: this.localEmployment.salary,
              commencement_date: this.localEmployment.commencement_date,
              year_of_service: this.localEmployment.year_of_service,
            };

            this.$store.dispatch(CREATE_EMPLOYMENT_SESSION, _context)
              .then((resp) => {
                this.closeOverlay();

                // if (resp.data.success == true) {
                if (resp.success == true) {
                  setTimeout(() => {
                    KTUtil.scrollTop();
                    // this.formId = resp?.data?.data?.id;
                    this.formId = resp?.data?.id;
                    this.isLoading = false;
                    // this.employmentFormSession.application_form = resp.data.data.application_form;
                    this.$emit("successProceed");
                  }, 500);
                }
                else {
                  this.isLoading = false;
                  // this.promptError(resp?.data?.message);
                  this.promptError(resp?.message);
                }
              })
              .catch((err) => {
                this.isLoading = false;
                this.closeOverlay();
                this.promptError(err?.message);
              })
              .finally(() => {
                this.isLoading = false;
                this.closeOverlay();
              });
          } else {
            this.$nextTick(() => {
              this.$el.querySelector(".v-messages.error--text:first-of-type")?.scrollIntoView(false);
              return;
            });
          }
        });

      this.$emit("onProceed", this.signature);
    },

    onOfficeCityChange(value) {
      if (
        this.city.find((ct) => {
          if (ct.id == value) {
            this.localEmployment.office_state_id = ct.state_id;
            this.cityOfficeScoped = this.city.slice().filter((ct) =>
              ct.state_id === this.city.find((c) => c.id === value).state_id
            );
          }
        })
      );
    },

    onCommenceDateChange() {
      this.localEmployment.year_of_service = (this.localEmployment.commencement_date)
                                        ? dayjs().diff(dayjs(this.localEmployment.commencement_date), 'years')
                                        : null;
      this.dateOfServiceMenu = false;
    },

    onOfficeStateChange(value) {
      this.cityOfficeScoped = value
        ? this.city.slice().filter((ct) => ct.state_id === value)
        : this.city.slice();
    },

    onOfficeNumInput(formattedNumber, { number, valid, country }) {
      //nfh-console.log('onOfficeFaxInput: ', formattedNumber, { number, valid, country });
      this.phone.office_num.country = country;
      this.phone.office_num.number  = number.national;
      this.phone.office_num.mobile  = parseInt(this.phone.office_num?.number?.split('-')[0]) > 9;
      this.phone.office_num.valid   = valid && !this.phone.office_num?.mobile;
      this.phone.office_num.color   = (this.phone.office_num.valid) ? this.inputColorOK : this.inputColorNG;
    },

    onOfficeFaxInput(formattedNumber, { number, valid, country }) {
      //nfh-console.log('onOfficeFaxInput: ', formattedNumber, { number, valid, country });
      this.phone.office_fax.country = country;
      this.phone.office_fax.number  = number.national;
      this.phone.office_fax.mobile  = parseInt(this.phone.office_fax?.number?.split('-')[0]) > 9;
      this.phone.office_fax.valid   = valid && !this.phone.office_fax?.mobile;
      this.phone.office_fax.color   = (this.phone.office_fax.valid) ? this.inputColorOK : this.inputColorNG;
    },

    // promptError(message) {
    //   this.msgERR.message = errorMessages(message);
    //   this.openErrMsgDialog();
    // },

    // promptInfo(message) {
    //   this.msgINFO.message = message;
    //   this.openInfoMsgDialog();
    // },

    // openInfoMsgDialog() {
    //   this.flagInfoMsgDlg = true;
    // },

    // openErrMsgDialog() {
    //   this.flagErrMsgDlg = true;
    // },

    promptMessage(message, title = "Makluman", cbProceed = () => { }) {
      this.messageDialog.title             = title;
      this.messageDialog.content           = message;
      this.messageDialog.cancel            = () => { };
      this.messageDialog.proceed           = cbProceed;
      this.messageDialog.backButton        = "";
      this.messageDialog.proceedButton     = "OK";
      this.messageDialog.showBackButton    = false;
      this.messageDialog.showProceedButton = true;
      this.messageDialog.show              = true;
      //nfh-console.log("promptMessage() messageDialog: ", this.messageDialog);
    },

    closeMessage() {
      this.messageDialog.cancel            = () => { };
      this.messageDialog.proceed           = () => { };
      this.messageDialog.title             = "Makluman";
      this.messageDialog.content           = "";
      this.messageDialog.backButton        = "";
      this.messageDialog.proceedButton     = "OK";
      this.messageDialog.showBackButton    = false;
      this.messageDialog.showProceedButton = true;
      this.messageDialog.show              = false;
      //nfh-console.log("closeMessage() messageDialog: ", this.messageDialog);
    },

    promptError(message, title = "Ralat", cbProceed = () => { }) {
      this.errorDialog.title             = title;
      this.errorDialog.content           = errorMessages(message);
      this.errorDialog.cancel            = () => { };
      this.errorDialog.proceed           = cbProceed;
      this.errorDialog.backButton        = "";
      this.errorDialog.proceedButton     = "OK";
      this.errorDialog.showBackButton    = false;
      this.errorDialog.showProceedButton = true;
      this.errorDialog.show              = true;
      //nfh-console.log("promptError() errorDialog: ", this.errorDialog);
    },

    closeError() {
      this.errorDialog.cancel            = () => { };
      this.errorDialog.proceed           = () => { };
      this.errorDialog.title             = "Ralat";
      this.errorDialog.content           = "";
      this.errorDialog.backButton        = "";
      this.errorDialog.proceedButton     = "OK";
      this.errorDialog.showBackButton    = false;
      this.errorDialog.showProceedButton = true;
      this.errorDialog.show              = false;
      //nfh-console.log("closeError() errorDialog: ", this.errorDialog);
    },

    openOverlay() {
      this.overlay = true;
    },

    closeOverlay() {
      setTimeout(() => {
        this.overlay = false;
      }, 2000);
    },

    goToError(_ref) {
      setTimeout(() => {
        const errors = Object.entries(_ref.errors)
          .map(([key, value]) => ({ key, value }))
          .filter((error) => error["value"].length);

        _ref.refs[errors[0]["key"]].$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 200);
    },

  },

  async mounted() {
    //nfh-console.log(" IN:MOUNTED")
    this.city              = await JSON.parse(localStorage.getItem("cty")); //
    this.race              = await JSON.parse(localStorage.getItem("rce"));
    this.banks             = await JSON.parse(localStorage.getItem("bnk"));
    this.gender            = await JSON.parse(localStorage.getItem("gdr"));
    this.salute            = await JSON.parse(localStorage.getItem("sal"));
    this.states            = await JSON.parse(localStorage.getItem("stt")); //
    this.religion          = await JSON.parse(localStorage.getItem("rgn")); //
    this.relations         = await JSON.parse(localStorage.getItem("rel"));
    this.employerTypes     = await JSON.parse(localStorage.getItem("emp")); //
    this.maritalStatus     = await JSON.parse(localStorage.getItem("mry"));
    this.houseOwnership    = await JSON.parse(localStorage.getItem("own"));
    this.educationLevel    = await JSON.parse(localStorage.getItem("edu"));
    this.occupationTypes   = await JSON.parse(localStorage.getItem("occ")); //
    this.cityHeirScoped    = this.city.slice();
    this.cityHomeScoped    = this.city.slice();
    this.cityOfficeScoped  = this.city.slice();
    this.cityMailingScoped = this.city.slice();
    this.init();
    //nfh-console.log("OUT:MOUNTED")
  },
}
</script>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";

.v-text-field--outlined {
  font-size: 11pt;
}

.v-text-field--outlined label {
  font-size: 11pt;
}

.ucase-text input {
  text-transform: uppercase;
}

.lcase-text input {
  text-transform: lowercase;
}

.checbox-decoration {
  margin: 0px;
}

.checbox-decoration .v-label {
  margin: 0px;
  font-size: 12px;
}

.snackbar-title {
  font-size: 14px;
  font-weight: 600;
}

.snackbar-subtitle {
  font-size: 12px;
}

.input-large {
  font-size: large;
}

#signaturePad {
  border: double 3px transparent;
  border-radius: 10px;
  border-width: 1;
  border-color: #000;
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>