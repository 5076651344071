var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "pa-2", attrs: { height: "auto", elevation: "0" } },
        [
          _c(
            "ValidationObserver",
            { ref: "employmentFormValidation" },
            [
              _c(
                "v-form",
                { staticClass: "font-weight-bold font-size-sm" },
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "12" } },
                            [
                              _c(
                                "h5",
                                {
                                  staticClass: "primary--text font-weight-bold",
                                },
                                [_vm._v(" Maklumat Pekerjaan ")]
                              ),
                              _c("v-divider", { staticClass: "mt-0" }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", md: "8" },
                            },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "Nama Majikan",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("v-text-field", {
                                          staticClass: "ucase-text",
                                          attrs: {
                                            "error-messages": errors,
                                            label: "Nama Majikan",
                                            outlined: "",
                                            dense: "",
                                            required: "",
                                          },
                                          model: {
                                            value:
                                              _vm.localEmployment.employer_name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.localEmployment,
                                                "employer_name",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "localEmployment.employer_name",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "Kategori Majikan",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            items: _vm.employerTypes,
                                            "item-text": "name",
                                            "item-value": "id",
                                            "error-messages": errors,
                                            label: "Kategori Majikan",
                                            outlined: "",
                                            dense: "",
                                            required: "18",
                                          },
                                          model: {
                                            value:
                                              _vm.localEmployment
                                                .employer_type_id,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.localEmployment,
                                                "employer_type_id",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "localEmployment.employer_type_id",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", md: "6" },
                            },
                            [
                              _c("ValidationProvider", {
                                attrs: { name: "Pekerjaan", rules: "required" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("v-text-field", {
                                          staticClass: "ucase-text",
                                          attrs: {
                                            "error-messages": errors,
                                            label: "Pekerjaan",
                                            outlined: "",
                                            dense: "",
                                            required: "",
                                          },
                                          model: {
                                            value:
                                              _vm.localEmployment.occupation,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.localEmployment,
                                                "occupation",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "localEmployment.occupation",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", md: "6" },
                            },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "Kategori Pekerjaan",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("v-select", {
                                          attrs: {
                                            items: _vm.occupationTypes,
                                            "item-text": "nameMS",
                                            "item-value": "id",
                                            "error-messages": errors,
                                            label: "Kategori Pekerjaan",
                                            outlined: "",
                                            dense: "",
                                            required: "18",
                                          },
                                          model: {
                                            value:
                                              _vm.localEmployment
                                                .occupation_type_id,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.localEmployment,
                                                "occupation_type_id",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "localEmployment.occupation_type_id",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", md: "6" },
                            },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "Bahagian / Unit",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("v-text-field", {
                                          staticClass: "ucase-text",
                                          attrs: {
                                            "error-messages": errors,
                                            label: "Bahagian/ Unit",
                                            outlined: "",
                                            dense: "",
                                            required: "",
                                          },
                                          model: {
                                            value:
                                              _vm.localEmployment.department,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.localEmployment,
                                                "department",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "localEmployment.department",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "Pendapatan Sebulan",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("vuetify-money", {
                                          attrs: {
                                            label: _vm.salaryLabel,
                                            placeholder: _vm.salaryPlaceholder,
                                            readonly: _vm.moneyReadOnly,
                                            disabled: _vm.salaryFreezed,
                                            outlined: _vm.moneyOutlined,
                                            valueWhenIsEmpty:
                                              _vm.moneyWhenEmpty,
                                            options: _vm.salaryOpt,
                                            properties: _vm.moneyProperties,
                                            clearable: false,
                                            "error-messages": errors,
                                            dense: _vm.moneyDense,
                                          },
                                          model: {
                                            value: _vm.localEmployment.salary,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.localEmployment,
                                                "salary",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "localEmployment.salary",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "Tarikh Mula Bekerja",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c(
                                          "v-menu",
                                          {
                                            attrs: {
                                              "error-messages": errors,
                                              "close-on-content-click": false,
                                              "nudge-right": 40,
                                              transition: "scale-transition",
                                              "offset-y": "",
                                              "min-width": "290px",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function (ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "v-text-field",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "mask",
                                                                  rawName:
                                                                    "v-mask",
                                                                  value:
                                                                    _vm.mask_date,
                                                                  expression:
                                                                    "mask_date",
                                                                },
                                                              ],
                                                              attrs: {
                                                                value:
                                                                  _vm.computedDateOfService,
                                                                label:
                                                                  "Tarikh Mula Bekerja",
                                                                outlined: "",
                                                                dense: "",
                                                                readonly: "",
                                                                clearable: "",
                                                                required: "",
                                                              },
                                                            },
                                                            "v-text-field",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        )
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: _vm.dateOfServiceMenu,
                                              callback: function ($$v) {
                                                _vm.dateOfServiceMenu = $$v
                                              },
                                              expression: "dateOfServiceMenu",
                                            },
                                          },
                                          [
                                            _c("v-date-picker", {
                                              attrs: {
                                                locale: "ms-my",
                                                "first-day-of-week": 1,
                                                max: _vm.maxDateEligibleWork,
                                                value: _vm.maxDateEligibleWork,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  _vm.dateOfServiceMenu = true
                                                },
                                                change:
                                                  _vm.onCommenceDateChange,
                                              },
                                              model: {
                                                value:
                                                  _vm.localEmployment
                                                    .commencement_date,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.localEmployment,
                                                    "commencement_date",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "localEmployment.commencement_date",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "Tahun Perkhidmatan",
                                  rules: "required|numeric",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("v-text-field", {
                                          attrs: {
                                            "error-messages": errors,
                                            label: "Tahun Perkhidmatan",
                                            hint: "Dikira berdasarkan dari tarikh mula bekerja",
                                            outlined: "",
                                            dense: "",
                                            filled: "",
                                            readonly: "",
                                            required: "",
                                          },
                                          model: {
                                            value:
                                              _vm.localEmployment
                                                .year_of_service,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.localEmployment,
                                                "year_of_service",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "localEmployment.year_of_service",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "12" } },
                            [
                              _c(
                                "h5",
                                {
                                  staticClass: "primary--text font-weight-bold",
                                },
                                [_vm._v(" Alamat Majikan ")]
                              ),
                              _c("v-divider", { staticClass: "mt-0" }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", md: "12" },
                            },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "Unit/Lot/No./Tingkat",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("v-text-field", {
                                          staticClass: "ucase-text",
                                          attrs: {
                                            hint: "Contoh A1-2-3",
                                            "error-messages": errors,
                                            label: "Unit/Lot/No./Tingkat",
                                            outlined: "",
                                            dense: "",
                                            required: "",
                                          },
                                          model: {
                                            value:
                                              _vm.localEmployment.office_unit,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.localEmployment,
                                                "office_unit",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "localEmployment.office_unit",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", md: "12" },
                            },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "Bangunan/Jalan",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("v-text-field", {
                                          staticClass: "ucase-text",
                                          attrs: {
                                            hint: "Wisma Persekutuan, Jalan Damansara",
                                            "error-messages": errors,
                                            label: "Bangunan/Jalan",
                                            outlined: "",
                                            dense: "",
                                            required: "",
                                          },
                                          model: {
                                            value:
                                              _vm.localEmployment.office_street,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.localEmployment,
                                                "office_street",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "localEmployment.office_street",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", md: "12" },
                            },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "SButiran Tambahan 1 (O)",
                                  rules: "",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("v-text-field", {
                                          staticClass: "ucase-text",
                                          attrs: {
                                            hint: "Masukkan butiran alamat sekiranya ada",
                                            "error-messages": errors,
                                            label:
                                              "Butiran Tambahan Sekiranya Ada (tidak wajib)",
                                            outlined: "",
                                            dense: "",
                                          },
                                          model: {
                                            value:
                                              _vm.localEmployment.office_line3,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.localEmployment,
                                                "office_line3",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "localEmployment.office_line3",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", md: "12" },
                            },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "Butiran Tambahan 2 (O))",
                                  rules: "",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("v-text-field", {
                                          staticClass: "ucase-text",
                                          attrs: {
                                            hint: "Masukkan butiran alamat sekiranya ada",
                                            "error-messages": errors,
                                            label:
                                              "Butiran Tambahan Sekiranya Ada (tidak wajib)",
                                            outlined: "",
                                            dense: "",
                                          },
                                          model: {
                                            value:
                                              _vm.localEmployment.office_line4,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.localEmployment,
                                                "office_line4",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "localEmployment.office_line4",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", md: "4" },
                            },
                            [
                              _c("ValidationProvider", {
                                attrs: { name: "Poskod", rules: "required" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("v-text-field", {
                                          directives: [
                                            {
                                              name: "mask",
                                              rawName: "v-mask",
                                              value: _vm.mask_postCode,
                                              expression: "mask_postCode",
                                            },
                                          ],
                                          attrs: {
                                            "error-messages": errors,
                                            label: "Poskod",
                                            outlined: "",
                                            dense: "",
                                            required: "",
                                          },
                                          model: {
                                            value:
                                              _vm.localEmployment
                                                .office_postcode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.localEmployment,
                                                "office_postcode",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "localEmployment.office_postcode",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", md: "8" },
                            },
                            [
                              _c("ValidationProvider", {
                                attrs: { name: "Bandar", rules: "required" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("v-select", {
                                          staticClass: "ucase-text",
                                          attrs: {
                                            items: _vm.cityOfficeScoped,
                                            "item-text": "display",
                                            "item-value": "id",
                                            "error-messages": errors,
                                            hint: "Bandar mengikut poskod",
                                            label: "Bandar",
                                            outlined: "",
                                            dense: "",
                                            required: "",
                                          },
                                          on: {
                                            change: _vm.onOfficeCityChange,
                                          },
                                          model: {
                                            value:
                                              _vm.localEmployment
                                                .office_city_id,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.localEmployment,
                                                "office_city_id",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "localEmployment.office_city_id",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", md: "8" },
                            },
                            [
                              _c("ValidationProvider", {
                                attrs: { name: "Negeri", rules: "required" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("v-select", {
                                          staticClass: "ucase-text",
                                          attrs: {
                                            items: _vm.states,
                                            "item-text": "name",
                                            "item-value": "id",
                                            "error-messages": errors,
                                            label: "Negeri",
                                            outlined: "",
                                            dense: "",
                                            required: "",
                                          },
                                          on: {
                                            change: _vm.onOfficeStateChange,
                                          },
                                          model: {
                                            value:
                                              _vm.localEmployment
                                                .office_state_id,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.localEmployment,
                                                "office_state_id",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "localEmployment.office_state_id",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "12" } },
                            [
                              _c(
                                "h5",
                                {
                                  staticClass: "primary--text font-weight-bold",
                                },
                                [_vm._v(" Telefon & E-Mel (Majikan) ")]
                              ),
                              _c("v-divider", { staticClass: "mt-0" }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", md: "6" },
                            },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "Telefon Pejabat",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("vue-tel-input-vuetify", {
                                          directives: [
                                            {
                                              name: "mask",
                                              rawName: "v-mask",
                                              value: _vm.mask_phoneLand,
                                              expression: "mask_phoneLand",
                                            },
                                          ],
                                          attrs: {
                                            label: "No. Telefon Pejabat",
                                            placeholder: "No. Telefon Pejabat",
                                            hint: "Contoh: 03-1234 5678 / 04-123 4567",
                                            defaultCountry: "MY",
                                            onlyCountries: ["MY"],
                                            "error-messages": errors,
                                            "background-color":
                                              _vm.inputColorOfficeNum,
                                            dense: "",
                                            outlined: "",
                                            required: "",
                                          },
                                          on: { input: _vm.onOfficeNumInput },
                                          model: {
                                            value:
                                              _vm.localEmployment.office_num,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.localEmployment,
                                                "office_num",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "localEmployment.office_num",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", md: "6" },
                            },
                            [
                              _c("vue-tel-input-vuetify", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: _vm.mask_phoneLand,
                                    expression: "mask_phoneLand",
                                  },
                                ],
                                attrs: {
                                  placeholder: "No. Faks",
                                  label: "No. Faks",
                                  hint: "Contoh: 03-1234 5678 / 04-123 4567",
                                  defaultCountry: "MY",
                                  onlyCountries: ["MY"],
                                  "error-messages": _vm.errors,
                                  "background-color": _vm.inputColorOfficeFax,
                                  dense: "",
                                  outlined: "",
                                },
                                on: { input: _vm.onOfficeFaxInput },
                                model: {
                                  value: _vm.localEmployment.office_fax,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.localEmployment,
                                      "office_fax",
                                      $$v
                                    )
                                  },
                                  expression: "localEmployment.office_fax",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", md: "8" },
                            },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  name: "E-Mel Majikan",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "E-Mel Majikan :",
                                            hint: "Contoh: jabatan@organisasi.gov.my",
                                            placeholder:
                                              "jabatan@organisasi.gov.my",
                                            "error-messages": errors,
                                            outlined: "",
                                            dense: "",
                                            required: "",
                                          },
                                          model: {
                                            value:
                                              _vm.localEmployment
                                                .employer_email,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.localEmployment,
                                                "employer_email",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "localEmployment.employer_email",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pr-4 pl-2 pb-2 pt-10", attrs: { align: "right" } },
        [
          _c(
            "v-btn",
            {
              attrs: { text: "", color: "grey darken-1" },
              on: { click: _vm.backStep },
            },
            [
              _c("v-icon", { attrs: { left: "", dark: "" } }, [
                _vm._v(" mdi-chevron-left "),
              ]),
              _vm._v(" Kembali "),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "ml-4",
              attrs: { color: "primary", loading: _vm.isLoading },
              on: {
                click: function ($event) {
                  return _vm.proceedStep()
                },
              },
            },
            [
              _vm._v(" Teruskan "),
              _c("v-icon", { attrs: { right: "", dark: "" } }, [
                _vm._v(" mdi-chevron-right "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("InfoMsgDialog", {
        attrs: {
          title: _vm.messageDialog.title,
          content: _vm.messageDialog.content,
          backButton: _vm.messageDialog.backButton,
          proceedButton: _vm.messageDialog.proceedButton,
          showBackButton: _vm.messageDialog.showBackButton,
          showProceedButton: _vm.messageDialog.showProceedButton,
          delayOnProceed: false,
          isTypeError: false,
        },
        on: {
          onBacking: _vm.messageDialog.cancel,
          onProceed: _vm.messageDialog.proceed,
        },
        model: {
          value: _vm.messageDialog.show,
          callback: function ($$v) {
            _vm.$set(_vm.messageDialog, "show", $$v)
          },
          expression: "messageDialog.show",
        },
      }),
      _c("ErrorMsgDialog", {
        attrs: {
          title: _vm.errorDialog.title,
          content: _vm.errorDialog.content,
          backButton: _vm.errorDialog.backButton,
          proceedButton: _vm.errorDialog.proceedButton,
          showBackButton: _vm.errorDialog.showBackButton,
          showProceedButton: _vm.errorDialog.showProceedButton,
          delayOnProceed: false,
          isTypeError: true,
        },
        on: {
          onBacking: _vm.errorDialog.cancel,
          onProceed: _vm.errorDialog.proceed,
        },
        model: {
          value: _vm.errorDialog.show,
          callback: function ($$v) {
            _vm.$set(_vm.errorDialog, "show", $$v)
          },
          expression: "errorDialog.show",
        },
      }),
      _c(
        "v-overlay",
        { attrs: { value: _vm.overlay } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }