var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pb-6" },
    [
      _c(
        "v-card",
        { staticClass: "pa-2", attrs: { height: "auto", elevation: "0" } },
        [
          _c("AlertActionable", {
            attrs: {
              alertMessage: _vm.alert1.message,
              alertHtml: _vm.alert1.html,
              alertType: _vm.alert1.type,
              outlined: _vm.alert1.outlined,
              dismissable: _vm.alert1.dismissable,
              button1Caption: _vm.alert1.button1Caption,
              button2Caption: _vm.alert1.button2Caption,
              button3Caption: _vm.alert1.button3Caption,
            },
            on: {
              onAction1: _vm.alert1.onAction1,
              onAction2: _vm.alert1.onAction2,
              onAction3: _vm.alert1.onAction3,
            },
            model: {
              value: _vm.alert1.show,
              callback: function ($$v) {
                _vm.$set(_vm.alert1, "show", $$v)
              },
              expression: "alert1.show",
            },
          }),
          _c(
            "ValidationObserver",
            { ref: "validateFormUserInfo" },
            [
              _c(
                "v-form",
                { staticClass: "font-weight-bold font-size-sm" },
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                lg: "12",
                                md: "12",
                                sm: "12",
                              },
                            },
                            [
                              _c(
                                "h5",
                                {
                                  staticClass: "primary--text font-weight-bold",
                                },
                                [
                                  _vm._v(
                                    " Maklumat Identifikasi Bagi Tujuan Pengesahan "
                                  ),
                                ]
                              ),
                              _c("v-divider", { staticClass: "mt-0" }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                lg: "12",
                                md: "12",
                                sm: "12",
                              },
                            },
                            [
                              _c("v-text-field", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: _vm.mask_icNum,
                                    expression: "mask_icNum",
                                  },
                                ],
                                attrs: {
                                  value: _vm.currentUser.nric_display,
                                  label: "No. Kad Pengenalan :",
                                  required: "1",
                                  dense: "",
                                  filled: "",
                                  readonly: "",
                                  "hide-details": "",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c("strong", [
                                          _vm._v(
                                            " No. Kad Pengenalan MyKAD : "
                                          ),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          !this.currentUser.phone_verified
                            ? _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12",
                                    lg: "6",
                                    md: "6",
                                    sm: "6",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c("ValidationProvider", {
                                        attrs: {
                                          name: "Telefon Bimbit",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("vue-tel-input-vuetify", {
                                                    attrs: {
                                                      label:
                                                        "Telefon Bimbit Utama",
                                                      placeholder:
                                                        "Telefon Bimbit",
                                                      hint: "Contoh: 010-123 4567 / 011-1234 5678",
                                                      defaultCountry: "MY",
                                                      maxlength: 13,
                                                      "background-color":
                                                        _vm.inputColorPhoneNum,
                                                      onlyCountries: ["MY"],
                                                      "error-message":
                                                        errors[0],
                                                      readonly:
                                                        _vm.user.phone_num ==
                                                        null,
                                                      autofocus: false,
                                                      translations: {
                                                        countrySelectorLabel:
                                                          "",
                                                        countrySelectorError:
                                                          "",
                                                        phoneNumberLabel:
                                                          "Telefon Bimbit",
                                                        example: "Contoh :",
                                                      },
                                                      dense: "",
                                                      outlined: "",
                                                      required: "",
                                                    },
                                                    on: {
                                                      input:
                                                        _vm.onPhoneNumInput,
                                                    },
                                                    model: {
                                                      value: _vm.user.phone_num,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.user,
                                                          "phone_num",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "user.phone_num",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1541828899
                                        ),
                                      }),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ml-2",
                                          attrs: {
                                            color: "primary",
                                            disabled: !_vm.inputValidPhoneNum,
                                            loading:
                                              _vm.isLoadingVerifyPhoneNum,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.verifyOTP()
                                            },
                                          },
                                        },
                                        [_vm._v(" Sahkan ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12",
                                    lg: "6",
                                    md: "6",
                                    sm: "6",
                                  },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "No. Telefon Bimbit :",
                                      hint: "Nombor telefon ini telah disahkan",
                                      "append-icon": "mdi-check",
                                      "background-color": "green accent-1",
                                      persistentHint: true,
                                      readonly: "",
                                      outlined: "",
                                      dense: "",
                                    },
                                    model: {
                                      value: _vm.currentUser.phone_num_display,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.currentUser,
                                          "phone_num_display",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "currentUser.phone_num_display",
                                    },
                                  }),
                                ],
                                1
                              ),
                          !this.currentUser.email_verified
                            ? _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12",
                                    lg: "6",
                                    md: "6",
                                    sm: "6",
                                  },
                                },
                                [
                                  _c("ValidationProvider", {
                                    attrs: { name: "E-mel", rules: "required" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var errors = ref.errors
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "d-flex" },
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "lcase-text",
                                                    attrs: {
                                                      label: "E-Mel :",
                                                      placeholder:
                                                        "nama@email.com",
                                                      hint: "Contoh: nama@email.com",
                                                      "background-color":
                                                        _vm.inputColorEmailUser,
                                                      "error-messages": errors,
                                                      dense: "",
                                                      outlined: "",
                                                      required: "",
                                                    },
                                                    model: {
                                                      value: _vm.user.email,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.user,
                                                          "email",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "user.email",
                                                    },
                                                  }),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "ml-2",
                                                      attrs: {
                                                        color: "primary",
                                                        loading:
                                                          _vm.isLoadingVerifyEmail,
                                                        disabled:
                                                          !_vm.inputValidEmailUser,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.verifyEmail()
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("Sahkan")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3770264026
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "v-col",
                                { attrs: { cols: "12", lg: "6", md: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "E-Mel :",
                                      hint: "Alamat E-mel ini telah disahkan",
                                      "background-color": "green accent-1",
                                      "append-icon": "mdi-check",
                                      persistentHint: true,
                                      dense: "",
                                      outlined: "",
                                      readonly: "",
                                    },
                                    model: {
                                      value: this.currentUser.email,
                                      callback: function ($$v) {
                                        _vm.$set(this.currentUser, "email", $$v)
                                      },
                                      expression: "this.currentUser.email",
                                    },
                                  }),
                                ],
                                1
                              ),
                          this.currentUser.phone_verified
                            ? [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "12" } },
                                  [
                                    _c("div", [
                                      _c(
                                        "h6",
                                        { staticClass: "font-weight-bold" },
                                        [
                                          _vm._v(
                                            "No. Rujukan: " +
                                              _vm._s(this.formSession.refno)
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "12" } },
                                  [
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "primary--text font-weight-bold",
                                      },
                                      [_vm._v(" Maklumat Diri ")]
                                    ),
                                    _c("v-divider", { staticClass: "mt-0" }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0",
                                    attrs: { cols: "12", md: "12" },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Nama Pemohon",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("v-text-field", {
                                                  staticClass: "ucase-text",
                                                  attrs: {
                                                    hint: "Nama Pemohon Seperti Tercatat dalam MyKad / Kad Pengenalan",
                                                    label:
                                                      "Nama Pemohon Seperti Tercatat dalam MyKad / Kad Pengenalan",
                                                    maxLength: "40",
                                                    "error-messages": errors,
                                                    dense: "",
                                                    outlined: "",
                                                    required: "",
                                                  },
                                                  on: {
                                                    focus: function ($event) {
                                                      return $event.target.select()
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.user.full_name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "full_name",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "user.full_name",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3325864809
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0",
                                    attrs: { cols: "12", md: "3" },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Gelaran",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("v-select", {
                                                  attrs: {
                                                    items: _vm.salute,
                                                    "item-text": "nameMS",
                                                    "item-value": "id",
                                                    "error-messages": errors,
                                                    label: "Gelaran",
                                                    outlined: "",
                                                    dense: "",
                                                    required: "",
                                                  },
                                                  model: {
                                                    value: _vm.user.salute_id,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "salute_id",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "user.salute_id",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2843385212
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0",
                                    attrs: { cols: "12", md: "4" },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Tarikh Lahir",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    value:
                                                      _vm.computedUserBirthDate,
                                                    label: "Tarikh Lahir",
                                                    filled: "",
                                                    outlined: "",
                                                    readonly: "",
                                                    disable: "",
                                                    dense: "",
                                                    hint: "Tarikh Lahir diperoleh daripada Nombor Kad Pengenalan",
                                                    "error-messages": errors,
                                                    "prepend-icon":
                                                      "mdi-calendar",
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      _vm.date = _vm.parseDate(
                                                        _vm.computedUserBirthDate
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        794619361
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0",
                                    attrs: { cols: "12", md: "5" },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Jantina",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("v-select", {
                                                  attrs: {
                                                    items: _vm.gender,
                                                    "item-text": "nameMS",
                                                    "item-value": "id",
                                                    hint: "Jantina diperoleh daripada Nombor Kad Pengenalan",
                                                    "error-messages": errors,
                                                    label: "Jantina",
                                                    filled: "",
                                                    outlined: "",
                                                    readonly: "",
                                                    disable: "",
                                                    dense: "",
                                                    required: "",
                                                  },
                                                  model: {
                                                    value: _vm.user.gender_id,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "gender_id",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "user.gender_id",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1872779407
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0",
                                    attrs: { cols: "12", md: "3" },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Bangsa",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("v-select", {
                                                  attrs: {
                                                    items: _vm.race,
                                                    "item-text": "nameMS",
                                                    "item-value": "id",
                                                    "error-messages": errors,
                                                    label: "Bangsa",
                                                    outlined: "",
                                                    dense: "",
                                                    required: "",
                                                  },
                                                  model: {
                                                    value: _vm.user.race_id,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "race_id",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "user.race_id",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3482622966
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0",
                                    attrs: { cols: "12", md: "3" },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Agama",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("v-select", {
                                                  attrs: {
                                                    items: _vm.religion,
                                                    "item-text": "nameMS",
                                                    "item-value": "id",
                                                    "error-messages": errors,
                                                    label: "Agama",
                                                    outlined: "",
                                                    dense: "",
                                                    required: "",
                                                  },
                                                  model: {
                                                    value: _vm.user.religion_id,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "religion_id",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "user.religion_id",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1587677189
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0",
                                    attrs: { cols: "12", md: "3" },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Status Perkahwinan",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("v-select", {
                                                  attrs: {
                                                    items: _vm.maritalStatus,
                                                    "item-text": "nameMS",
                                                    "item-value": "id",
                                                    "error-messages": errors,
                                                    label: "Status Perkahwinan",
                                                    outlined: "",
                                                    dense: "",
                                                    required: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.user
                                                        .marital_status_id,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "marital_status_id",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "user.marital_status_id",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3286938015
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0",
                                    attrs: { cols: "12", md: "3" },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: { name: "Bilangan Anak" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    "error-messages": errors,
                                                    label:
                                                      "Bilangan Anak (jika ada)",
                                                    outlined: "",
                                                    dense: "",
                                                  },
                                                  model: {
                                                    value: _vm.user.children,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "children",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "user.children",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1407755249
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0",
                                    attrs: { cols: "12", md: "6" },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Tahap Pendidikan",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("v-select", {
                                                  attrs: {
                                                    items: _vm.educationLevel,
                                                    "item-text": "nameMS",
                                                    "item-value": "id",
                                                    "error-messages": errors,
                                                    label: "Tahap Pendidikan",
                                                    outlined: "",
                                                    dense: "",
                                                    required: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.user
                                                        .education_level_id,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "education_level_id",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "user.education_level_id",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3386410594
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0",
                                    attrs: { cols: "12", md: "6" },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Nama Ibu",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("v-text-field", {
                                                  staticClass: "ucase-text",
                                                  attrs: {
                                                    "error-messages": errors,
                                                    label: "Nama Ibu",
                                                    outlined: "",
                                                    dense: "",
                                                    required: "",
                                                  },
                                                  model: {
                                                    value: _vm.user.mother_name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "mother_name",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "user.mother_name",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2562875817
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "12" } },
                                  [
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "primary--text font-weight-bold",
                                      },
                                      [_vm._v(" Maklumat Bank ")]
                                    ),
                                    _c("v-divider", { staticClass: "mt-0" }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0",
                                    attrs: { cols: "12", md: "6" },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Nama Bank",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("v-select", {
                                                  attrs: {
                                                    value: _vm.getBank,
                                                    items: _vm.banks,
                                                    "error-messages": errors,
                                                    "item-text": "name",
                                                    "item-value": "id",
                                                    label: "Nama Bank",
                                                    "return-object": "",
                                                    outlined: "",
                                                    dense: "",
                                                    required: "",
                                                  },
                                                  on: {
                                                    change: _vm.onBankAccChange,
                                                    input: _vm.onBankAccInput,
                                                  },
                                                  model: {
                                                    value: _vm.user.bankInfo,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "bankInfo",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "user.bankInfo",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        4054336654
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0",
                                    attrs: { cols: "12", md: "6" },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "No. Akaun Bank",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    counter:
                                                      _vm.selectedBank.max,
                                                    maxlength:
                                                      _vm.selectedBank.max,
                                                    "error-messages": errors,
                                                    readonly:
                                                      _vm.isBankAccReadOnly,
                                                    placeholder:
                                                      _vm.isBankAccReadOnly
                                                        ? "Pilih Nama Bank Dahulu"
                                                        : "Masukkan No Akaun bank",
                                                    label: "No. Akaun Bank",
                                                    outlined: "",
                                                    dense: "",
                                                    required: "",
                                                  },
                                                  on: {
                                                    keydown:
                                                      _vm.filterNonNumeric,
                                                    keyup: _vm.filterNonNumeric,
                                                  },
                                                  model: {
                                                    value: _vm.user.bank_acc,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "bank_acc",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "user.bank_acc",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3725437985
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "12" } },
                                  [
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "primary--text font-weight-bold",
                                      },
                                      [
                                        _vm._v(
                                          " Alamat Rumah (Tempat Tinggal Sekarang) "
                                        ),
                                      ]
                                    ),
                                    _c("v-divider", { staticClass: "mt-0" }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0",
                                    attrs: { cols: "12", md: "12" },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Unit / Lot / No. / Blok",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("v-text-field", {
                                                  staticClass: "ucase-text",
                                                  attrs: {
                                                    hint: "Contoh: A1-2-3, Blok A, Apartment Villa",
                                                    label:
                                                      "Unit / Lot / No. / Blok",
                                                    "error-messages": errors,
                                                    outlined: "",
                                                    dense: "",
                                                    required: "",
                                                  },
                                                  model: {
                                                    value: _vm.user.home_unit,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "home_unit",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "user.home_unit",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2034867790
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0",
                                    attrs: { cols: "12", md: "12" },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Taman / Apartment / Jalan / Lorong",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("v-text-field", {
                                                  staticClass: "ucase-text",
                                                  attrs: {
                                                    hint: "Contoh: Jalan Bahagia 1, Kampung Sentosa",
                                                    label:
                                                      "Taman / Apartment / Jalan / Lorong / Seksyen / Presint / Kawasan",
                                                    "error-messages": errors,
                                                    outlined: "",
                                                    dense: "",
                                                    required: "",
                                                  },
                                                  model: {
                                                    value: _vm.user.home_street,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "home_street",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "user.home_street",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2990541871
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0",
                                    attrs: { cols: "12", md: "12" },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Butiran Tambahan 1 (R)",
                                        rules: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("v-text-field", {
                                                  staticClass: "ucase-text",
                                                  attrs: {
                                                    hint: "Masukkan butiran alamat sekiranya ada",
                                                    label:
                                                      "Butiran Tambahan Sekiranya Ada (tidak wajib)",
                                                    "error-messages": errors,
                                                    outlined: "",
                                                    dense: "",
                                                  },
                                                  model: {
                                                    value: _vm.user.home_line3,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "home_line3",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "user.home_line3",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3854695634
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0",
                                    attrs: { cols: "12", md: "12" },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Butiran Tambahan 2 (R)",
                                        rules: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("v-text-field", {
                                                  staticClass: "ucase-text",
                                                  attrs: {
                                                    hint: "Masukkan butiran alamat sekiranya ada",
                                                    "error-messages": errors,
                                                    label:
                                                      "Butiran Tambahan Sekiranya Ada (tidak wajib)",
                                                    outlined: "",
                                                    dense: "",
                                                  },
                                                  model: {
                                                    value: _vm.user.home_line4,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "home_line4",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "user.home_line4",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3137038549
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0",
                                    attrs: { cols: "12", md: "4" },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Poskod",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("v-text-field", {
                                                  directives: [
                                                    {
                                                      name: "mask",
                                                      rawName: "v-mask",
                                                      value: _vm.mask_postCode,
                                                      expression:
                                                        "mask_postCode",
                                                    },
                                                  ],
                                                  attrs: {
                                                    hint: "55100",
                                                    label: "Poskod",
                                                    "error-messages": errors,
                                                    outlined: "",
                                                    dense: "",
                                                    required: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.user.home_postcode,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "home_postcode",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "user.home_postcode",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        239810744
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0",
                                    attrs: { cols: "12", md: "8" },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Bandar",
                                        rules: "required|max:30",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("v-select", {
                                                  staticClass: "ucase-text",
                                                  attrs: {
                                                    items: _vm.cityHomeScoped,
                                                    "item-text": "display",
                                                    "item-value": "id",
                                                    "item-color": "primary",
                                                    "error-messages": errors,
                                                    hint: "Bandar (mengikut poskod)",
                                                    label:
                                                      "Bandar (mengikut poskod)",
                                                    outlined: "",
                                                    dense: "",
                                                    required: "",
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.onHomeCityChange,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.user.home_city_id,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "home_city_id",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "user.home_city_id",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3859454045
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0",
                                    attrs: { cols: "12", md: "8" },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Negeri",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("v-select", {
                                                  staticClass: "ucase-text",
                                                  attrs: {
                                                    items: _vm.states,
                                                    "item-text": "name",
                                                    "item-value": "id",
                                                    "error-messages": errors,
                                                    label: "Negeri",
                                                    outlined: "",
                                                    dense: "",
                                                    required: "",
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.onHomeStateChange,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.user.home_state_id,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "home_state_id",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "user.home_state_id",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1603802498
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0",
                                    attrs: { cols: "12", md: "8" },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Status Pemilikan Rumah (H)",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("v-select", {
                                                  attrs: {
                                                    items: _vm.houseOwnership,
                                                    "item-text": "nameMS",
                                                    "item-value": "id",
                                                    "error-messages": errors,
                                                    label:
                                                      "Status Pemilikan Rumah",
                                                    outlined: "",
                                                    dense: "",
                                                    required: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.user
                                                        .home_ownership_id,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "home_ownership_id",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "user.home_ownership_id",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2976167261
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0",
                                    staticStyle: { "padding-top": "0px" },
                                    attrs: { cols: "12", md: "12" },
                                  },
                                  [
                                    _c("v-checkbox", {
                                      staticClass: "checbox-decoration",
                                      attrs: {
                                        label:
                                          "ALAMAT SURAT MENYURAT SAMA SEPERTI ALAMAT RUMAH DI ATAS",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.useMainAddress()
                                        },
                                      },
                                      model: {
                                        value: _vm.user.mailing_same_home,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.user,
                                            "mailing_same_home",
                                            $$v
                                          )
                                        },
                                        expression: "user.mailing_same_home",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showMailingAddress,
                                        expression: "showMailingAddress",
                                      },
                                    ],
                                    attrs: { cols: "12", md: "12" },
                                  },
                                  [
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "primary--text font-weight-bold",
                                      },
                                      [_vm._v(" Alamat Surat Menyurat ")]
                                    ),
                                    _c("v-divider", { staticClass: "mt-0" }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showMailingAddress,
                                        expression: "showMailingAddress",
                                      },
                                    ],
                                    staticClass: "py-0",
                                    attrs: { cols: "12", md: "12" },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Unit/Lot/No./Tingkat SM",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("v-text-field", {
                                                  staticClass: "ucase-text",
                                                  attrs: {
                                                    label:
                                                      "Unit/Lot/No./Tingkat",
                                                    hint: "Contoh A1-2-3",
                                                    "error-messages": errors,
                                                    outlined: "",
                                                    dense: "",
                                                    required: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.user.mailing_unit,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "mailing_unit",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "user.mailing_unit",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1025394179
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showMailingAddress,
                                        expression: "showMailingAddress",
                                      },
                                    ],
                                    staticClass: "py-0",
                                    attrs: { cols: "12", md: "12" },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Jalan/Apartment/Taman SM",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("v-text-field", {
                                                  staticClass: "ucase-text",
                                                  attrs: {
                                                    hint: "Jalan Bahagia, Kampung Sentosa",
                                                    "error-messages": errors,
                                                    label:
                                                      "Jalan / Apartment / Taman / Seksyen / Presint / Kawasan",
                                                    outlined: "",
                                                    dense: "",
                                                    required: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.user.mailing_street,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "mailing_street",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "user.mailing_street",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2685935243
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showMailingAddress,
                                        expression: "showMailingAddress",
                                      },
                                    ],
                                    staticClass: "py-0",
                                    attrs: { cols: "12", md: "12" },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Butiran Tambahan 1 (S)",
                                        rules: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("v-text-field", {
                                                  staticClass: "ucase-text",
                                                  attrs: {
                                                    hint: "Masukkan butiran alamat sekiranya ada",
                                                    "error-messages": errors,
                                                    label:
                                                      "Butiran Tambahan Sekiranya Ada (tidak wajib)",
                                                    outlined: "",
                                                    dense: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.user.mailing_line3,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "mailing_line3",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "user.mailing_line3",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3370202100
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showMailingAddress,
                                        expression: "showMailingAddress",
                                      },
                                    ],
                                    staticClass: "py-0",
                                    attrs: { cols: "12", md: "12" },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Butiran Tambahan 2 (S)",
                                        rules: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("v-text-field", {
                                                  staticClass: "ucase-text",
                                                  attrs: {
                                                    hint: "Masukkan butiran alamat sekiranya ada",
                                                    "error-messages": errors,
                                                    label:
                                                      "Butiran Tambahan Sekiranya Ada (tidak wajib)",
                                                    outlined: "",
                                                    dense: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.user.mailing_line4,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "mailing_line4",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "user.mailing_line4",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        439432947
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showMailingAddress,
                                        expression: "showMailingAddress",
                                      },
                                    ],
                                    staticClass: "py-0",
                                    attrs: { cols: "12", md: "4" },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Poskod SM",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("v-text-field", {
                                                  directives: [
                                                    {
                                                      name: "mask",
                                                      rawName: "v-mask",
                                                      value: _vm.mask_postCode,
                                                      expression:
                                                        "mask_postCode",
                                                    },
                                                  ],
                                                  attrs: {
                                                    hint: "55100",
                                                    "error-messages": errors,
                                                    label: "Poskod",
                                                    outlined: "",
                                                    dense: "",
                                                    required: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.user.mailing_postcode,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "mailing_postcode",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "user.mailing_postcode",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        869672926
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showMailingAddress,
                                        expression: "showMailingAddress",
                                      },
                                    ],
                                    staticClass: "py-0",
                                    attrs: { cols: "12", md: "8" },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Bandar SM",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("v-select", {
                                                  staticClass: "ucase-text",
                                                  attrs: {
                                                    items:
                                                      _vm.cityMailingScoped,
                                                    "item-text": "display",
                                                    "item-value": "id",
                                                    "error-messages": errors,
                                                    hint: "Bandar mengikut poskod",
                                                    label: "Bandar",
                                                    outlined: "",
                                                    dense: "",
                                                    required: "",
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.onMailingCityChange,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.user.mailing_city_id,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "mailing_city_id",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "user.mailing_city_id",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        805778378
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showMailingAddress,
                                        expression: "showMailingAddress",
                                      },
                                    ],
                                    staticClass: "py-0",
                                    attrs: { cols: "12", md: "8" },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Negeri SM",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("v-select", {
                                                  staticClass: "ucase-text",
                                                  attrs: {
                                                    label: "Negeri",
                                                    "item-text": "name",
                                                    "item-value": "id",
                                                    items: _vm.states,
                                                    "error-messages": errors,
                                                    dense: "",
                                                    outlined: "",
                                                    required: "",
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.onMailingStateChange,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.user.mailing_state_id,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "mailing_state_id",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "user.mailing_state_id",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2131559010
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showMailingAddress,
                                        expression: "showMailingAddress",
                                      },
                                    ],
                                    staticClass: "py-0",
                                    attrs: { cols: "12", md: "8" },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Status Pemilikan Rumah (M)",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("v-select", {
                                                  attrs: {
                                                    "item-text": "nameMS",
                                                    "item-value": "id",
                                                    items: _vm.houseOwnership,
                                                    "error-messages": errors,
                                                    label:
                                                      "Status Pemilikan Rumah",
                                                    outlined: "",
                                                    dense: "",
                                                    required: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.user
                                                        .mailing_ownership_id,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "mailing_ownership_id",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "user.mailing_ownership_id",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2226857819
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "12" } },
                                  [
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "primary--text font-weight-bold",
                                      },
                                      [_vm._v(" Telefon Untuk Dihubungi ")]
                                    ),
                                    _c("v-divider", { staticClass: "mt-0" }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0",
                                    attrs: { cols: "12", md: "6" },
                                  },
                                  [
                                    _c("vue-tel-input-vuetify", {
                                      attrs: {
                                        label: "Telefon Rumah",
                                        placeholder: "Telefon Rumah",
                                        hint: "Contoh: 03-1234 5678 / 04-123 4567",
                                        defaultCountry: "MY",
                                        maxlength: 13,
                                        onlyCountries: ["MY"],
                                        translations: {
                                          countrySelectorLabel: "",
                                          countrySelectorError: "",
                                          phoneNumberLabel: "Telefon Rumah",
                                          example: "Contoh :",
                                        },
                                        "background-color":
                                          _vm.inputColorHomeNum,
                                        dense: "",
                                        outlined: "",
                                      },
                                      on: { input: _vm.onHomeNumInput },
                                      model: {
                                        value: _vm.user.home_num,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.user, "home_num", $$v)
                                        },
                                        expression: "user.home_num",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0",
                                    attrs: { cols: "12", md: "6" },
                                  },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "Telefon Pejabat",
                                        rules: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("vue-tel-input-vuetify", {
                                                  directives: [
                                                    {
                                                      name: "mask",
                                                      rawName: "v-mask",
                                                      value: _vm.mask_phoneLand,
                                                      expression:
                                                        "mask_phoneLand",
                                                    },
                                                  ],
                                                  attrs: {
                                                    label: "Telefon Pejabat",
                                                    placeholder:
                                                      "Telefon Pejabat",
                                                    hint: "Contoh: 03-1234 5678 / 04-123 4567",
                                                    defaultCountry: "MY",
                                                    onlyCountries: ["MY"],
                                                    "error-messages": errors,
                                                    translations: {
                                                      countrySelectorLabel: "",
                                                      countrySelectorError: "",
                                                      phoneNumberLabel:
                                                        "Telefon Pejabat",
                                                      example: "Contoh :",
                                                    },
                                                    "background-color":
                                                      _vm.inputColorUserOfficeNum,
                                                    dense: "",
                                                    outlined: "",
                                                    required: "",
                                                  },
                                                  on: {
                                                    input:
                                                      _vm.onUserOfficeNumInput,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.user.user_office_num,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "user_office_num",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "user.user_office_num",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1944966722
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "12" } },
                                  [
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "primary--text font-weight-bold",
                                      },
                                      [
                                        _vm._v(
                                          " Pembiayaan Yang Ingin Dipohon "
                                        ),
                                      ]
                                    ),
                                    _c("v-divider", { staticClass: "mt-0" }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0",
                                    attrs: {
                                      cols: "12",
                                      md: "6",
                                      sm: "6",
                                      xs: "2",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex" },
                                      [
                                        _c("ValidationProvider", {
                                          attrs: {
                                            name: "Jumlah Pembiayaan Dipohon",
                                            rules: "required",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (ref) {
                                                  var errors = ref.errors
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c("vuetify-money", {
                                                      attrs: {
                                                        label:
                                                          "Jumlah Pembiayaan Dipohon",
                                                        placeholder:
                                                          _vm.loanAmountPlaceholder,
                                                        valueWhenIsEmpty:
                                                          _vm.reqMoneyWhenEmpty,
                                                        properties:
                                                          _vm.moneyProperties,
                                                        options: _vm.loanOpt,
                                                        "error-messages":
                                                          errors,
                                                        disabled:
                                                          _vm.moneyDisabled,
                                                        outlined:
                                                          _vm.moneyOutlined,
                                                        required:
                                                          _vm.moneyDense,
                                                        readonly: true,
                                                        dense: _vm.moneyDense,
                                                        errorMessages: errors,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.user
                                                            .financing_req_amount,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.user,
                                                            "financing_req_amount",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "user.financing_req_amount",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            216572674
                                          ),
                                        }),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "ml-2",
                                            attrs: { color: "primary" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openCalculator()
                                              },
                                            },
                                          },
                                          [_vm._v(" Kalkulator ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "py-0",
                                    attrs: {
                                      cols: "12",
                                      md: "12",
                                      sm: "12",
                                      xs: "12",
                                    },
                                  },
                                  [
                                    _c(
                                      "h6",
                                      {
                                        staticClass:
                                          "primary--text font-weight-bold",
                                      },
                                      [
                                        _c("i", [
                                          _vm._v(
                                            " (Pengiraan Kasar - tertakluk kepada perubahan) "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "d-flex" },
                                      [
                                        _c("vuetify-money", {
                                          staticClass: "mr-2",
                                          attrs: {
                                            dense: _vm.moneyDense,
                                            "error-messages": _vm.errors,
                                            label: _vm.loanInstLabel,
                                            placeholder:
                                              _vm.loanAmountPlaceholder,
                                            valueWhenIsEmpty:
                                              _vm.moneyWhenEmpty,
                                            options: _vm.loanOpt2,
                                            properties: _vm.moneyProperties,
                                            disabled: _vm.moneyDisabled,
                                            outlined: _vm.moneyOutlined,
                                            readonly: true,
                                          },
                                          model: {
                                            value: _vm.user.financing_req_inst,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.user,
                                                "financing_req_inst",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "user.financing_req_inst",
                                          },
                                        }),
                                        _c("vuetify-money", {
                                          staticClass: "mr-2",
                                          attrs: {
                                            dense: _vm.moneyDense,
                                            "error-messages": _vm.errors,
                                            label: _vm.loanTenureLabel,
                                            placeholder:
                                              _vm.loanAmountPlaceholder,
                                            valueWhenIsEmpty:
                                              _vm.moneyWhenEmpty,
                                            options: _vm.tenure,
                                            properties: _vm.moneyProperties,
                                            disabled: _vm.moneyDisabled,
                                            outlined: _vm.moneyOutlined,
                                            readonly: true,
                                          },
                                          model: {
                                            value:
                                              _vm.user.financing_req_tenure,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.user,
                                                "financing_req_tenure",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "user.financing_req_tenure",
                                          },
                                        }),
                                        _c("vuetify-money", {
                                          staticClass: "mr-2",
                                          attrs: {
                                            dense: _vm.moneyDense,
                                            "error-messages": _vm.errors,
                                            label: _vm.loanRateLabel,
                                            placeholder:
                                              _vm.loanAmountPlaceholder,
                                            valueWhenIsEmpty:
                                              _vm.moneyWhenEmpty,
                                            options: _vm.percent,
                                            properties: _vm.moneyProperties,
                                            disabled: _vm.moneyDisabled,
                                            outlined: _vm.moneyOutlined,
                                            readonly: true,
                                          },
                                          model: {
                                            value: _vm.user.financing_req_rate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.user,
                                                "financing_req_rate",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "user.financing_req_rate",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pr-4 pl-2 pb-2 pt-10", attrs: { align: "right" } },
        [
          this.currentUser.phone_verified
            ? _c(
                "v-btn",
                {
                  staticClass: "ml-4",
                  attrs: { color: "primary", loading: _vm.isLoading },
                  on: {
                    click: function ($event) {
                      return _vm.proceedStep()
                    },
                  },
                },
                [
                  _vm._v(" Teruskan "),
                  _c("v-icon", { attrs: { right: "", dark: "" } }, [
                    _vm._v(" mdi-chevron-right "),
                  ]),
                ],
                1
              )
            : _c(
                "v-btn",
                { staticClass: "ml-4", attrs: { disabled: "" } },
                [
                  _vm._v(" Teruskan "),
                  _c("v-icon", { attrs: { right: "", dark: "" } }, [
                    _vm._v(" mdi-chevron-right "),
                  ]),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: _vm.snackbar.color, top: "" },
          model: {
            value: _vm.snackbar.visible,
            callback: function ($$v) {
              _vm.$set(_vm.snackbar, "visible", $$v)
            },
            expression: "snackbar.visible",
          },
        },
        [
          _c("p", { staticClass: "snackbar-subtitle mb-0" }, [
            _vm._v(" " + _vm._s(_vm.snackbar.message) + " "),
          ]),
        ]
      ),
      _c("OTPPrompt", {
        attrs: { params: _vm.msgOTP },
        on: { onFinishOTP: _vm.onFinishOTP },
        model: {
          value: _vm.msgOTP.show,
          callback: function ($$v) {
            _vm.$set(_vm.msgOTP, "show", $$v)
          },
          expression: "msgOTP.show",
        },
      }),
      _c("CalculatorDialog", {
        attrs: {
          title: "Kira Jumlah Layak Memohon",
          calculatedResult: _vm.calculatedResult,
          formId: _vm.calcParamFormID,
          salary: _vm.calcParamSalary,
          deductions: _vm.calcParamDeductions,
          employerTypesId: _vm.calcParamEmpTypeId,
          employerTypes: _vm.calcParamEmpType,
          preCalc: _vm.calcParamPreCalc,
          preData: _vm.financingData,
        },
        on: { onGetResult: _vm.saveCalcResult, calculate: _vm.calculate },
        model: {
          value: _vm.openCalculatorDialog,
          callback: function ($$v) {
            _vm.openCalculatorDialog = $$v
          },
          expression: "openCalculatorDialog",
        },
      }),
      _c("InfoMsgDialog", {
        attrs: {
          title: _vm.messageDialog.title,
          modal: _vm.messageDialog.modal,
          content: _vm.messageDialog.content,
          backButton: _vm.messageDialog.backButton,
          proceedButton: _vm.messageDialog.proceedButton,
          showBackButton: _vm.messageDialog.showBackButton,
          showProceedButton: _vm.messageDialog.showProceedButton,
          delayOnProceed: false,
        },
        on: {
          onBacking: _vm.messageDialog.cancel,
          onProceed: _vm.messageDialog.proceed,
        },
        model: {
          value: _vm.messageDialog.show,
          callback: function ($$v) {
            _vm.$set(_vm.messageDialog, "show", $$v)
          },
          expression: "messageDialog.show",
        },
      }),
      _c("ConfirmationDialog", {
        attrs: {
          title: _vm.confirmDialog.title,
          content: _vm.confirmDialog.content,
          backButton: _vm.confirmDialog.backButton,
          proceedButton: _vm.confirmDialog.proceedButton,
          showBackButton: _vm.confirmDialog.showBackButton,
          showProceedButton: _vm.confirmDialog.showProceedButton,
        },
        on: {
          onCancel: _vm.confirmDialog.cancel,
          onProceed: _vm.confirmDialog.proceed,
        },
        model: {
          value: _vm.confirmDialog.show,
          callback: function ($$v) {
            _vm.$set(_vm.confirmDialog, "show", $$v)
          },
          expression: "confirmDialog.show",
        },
      }),
      _c("ErrorMsgDialog", {
        attrs: {
          title: "Ralat",
          proceedButton: "OK",
          content: _vm.msgERR.message,
          showProceedButton: true,
          showBackButton: false,
          delayOnProceed: false,
          isTypeError: true,
        },
        model: {
          value: _vm.flagErrMsgDlg,
          callback: function ($$v) {
            _vm.flagErrMsgDlg = $$v
          },
          expression: "flagErrMsgDlg",
        },
      }),
      _c(
        "v-overlay",
        { attrs: { value: _vm.overlay } },
        [
          _c("v-progress-circular", {
            attrs: { size: "64", indeterminate: "" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }