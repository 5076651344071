<template>
  <div>
    <v-card class="pa-2" height="auto" elevation="0">
      <form method="post" enctype="multipart/form-data">
        <div>
          <h5 class="primary--text font-weight-bold">Pengakuan</h5>
          <v-divider class="mt-0"></v-divider>
        </div>

        <v-row>
          <v-flex xs2 xm2 md1>
            <v-col cols="1" class="pt-1">
              <v-checkbox
                v-model="agree_tnc"
                @click="proceedButtonEnabler()"
                dense
              ></v-checkbox>
            </v-col>
          </v-flex>
          <v-flex xs10 xm10 md11>
            <v-col cols="11">
              <p class="subtitle-1">
                Saya bersetuju untuk memberi kebenaran kepada KOWAMAS mengatur/mengurus dan menyelesaikan mana-mana hutang
                sedia ada termasuk baki tertunggak kad kredit untuk tujuan kelulusan permohonan ini dan mengaku:
                <br />
              <ol>
                <li>
                  Bahawa segala maklumat yang diberikan dalam permohonan ini adalah benar dan betul dan saya tidak
                  menyembunyikan sebarang fakta penting.
                </li>
                <li>
                  Bahawa saya bukan seorang yang bankrap (muflis) dan tiada sebarang tindakan undang-undang ke atas diri
                  saya.
                </li>
                <li>
                  Bahawa KOWAMAS mempunyai hak mutlak untuk tidak meluluskan permohonan ini tanpa sebarang sebab.
                </li>
                <li>
                  Dan sekiranya didapati sebarang maklumat yang tidak betul di dalam permohonan ini setelah pembiayaan ini
                  diluluskan, KOWAMAS mempunyai hak untuk mengambil sebarang tindakan yang difikirkan patut dan sesuai ke
                  atas diri saya.
                </li>
              </ol>
              </p>

              <div>
                <v-card class="pa-4" height="150" width="200">
                  <v-img class="ma-auto" :src="signature" contain />
                </v-card>
              </div>

              <div class="pt-4">
                <v-dialog v-model="signaturePadDlg" width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="subtitle-2 font-weight-bold" color="success" v-bind="attrs" v-on="on">
                      <v-icon left> mdi-pencil </v-icon>
                      Tandatangan Pemohon
                    </v-btn>
                  </template>
                  <v-card>
                    <div class="container">
                      <div class="col-12 pa-2 mb-4">
                        <VueSignaturePad id="signaturePad" width="100%" height="300px" ref="signaturePad" :options="{
                          onBegin: () => {
                            $refs.signaturePad.resizeCanvas();
                          },
                        }" />
                      </div>
                      <v-row align="end" justify="end">
                        <v-btn text class="mr-4 mb-4" @click="undo">Undur</v-btn>
                        <v-btn class="mr-4 mb-4" color="primary" @click="save">Simpan</v-btn>
                      </v-row>
                    </div>
                  </v-card>
                </v-dialog>
              </div>

            </v-col>
          </v-flex>
        </v-row>

        <v-snackbar v-model="snackbarSignature.visible" color="red lighten-1" top>
          <v-row no-gutters align="center" justify="center">
            <v-col cols="1">
              <v-icon>{{
                snackbarSignature.icon
              }}</v-icon>
            </v-col>
            <v-col cols="10">
              <div class="mx-3">
                <p class="snackbar-title">
                  {{ snackbarSignature.title }}
                </p>
                <p class="snackbar-subtitle mb-0">
                  {{ snackbarSignature.text }}
                </p>
              </div>
            </v-col>
            <v-col cols="1">
              <v-btn icon @click="snackbarSignature.visible = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-snackbar>

      </form>
    </v-card>

    <div align="right" class="pl-2 pb-2 pt-10">
      <v-btn text @click="backStep()">
        <v-icon left dark>mdi-chevron-left</v-icon>Kembali
      </v-btn>
      <v-btn class="ml-4" color="primary" :loading="isLoading" :disabled="stateProceed" @click="saveSignature()">
        Teruskan
        <v-icon right dark>mdi-chevron-right</v-icon>
      </v-btn>
    </div>

    <!-- <ConfirmationDialog v-model="openConfirmationDialog" title="Persetujuan" proceedButton="Ya" content=" "
      :showProceedButton="true" :showBackButton="true"></ConfirmationDialog> -->

    <ErrorMsgDialog v-model="flagErrMsgDlg" title="Ralat" proceedButton="OK" :content="msgERR.message"
      :showProceedButton="true" :showBackButton="false" :delayOnProceed="false" :isTypeError="true">
    </ErrorMsgDialog>

    <InfoMsgDialog v-model="flagInfoMsgDlg" title="Info" proceedButton="OK" :content="msgINFO.message"
      :showProceedButton="true" :showBackButton="false" :delayOnProceed="false"></InfoMsgDialog>

    <v-overlay :value="overlay"><v-progress-circular indeterminate size="64"></v-progress-circular></v-overlay>

  </div>
</template>

<script>
import {
  errorMessages,
  formatAddress,
} from "@/core/services/helper.service";

import {
  UPDATE_AGREE,
  CREATE_SIGNATURE_SESSION,
} from "@/core/services/store/form.module";

import {
  mapGetters,
} from "vuex";

export default {
  name: "Summary",

  components: {
    //
  },

  watch: {
    propSignature: function (newValue, oldValue) {
      this.signature = newValue;
    },
  },

  props: {
    propFormId: {
      type: Number,
      default: 0,
    },

    propSignature: {
      type: String,
      default: "",
    },

    propSignatureOptional: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      stepNo: 0,

      overlay: false,
      isLoading: false,
      proceedButton: false,
      flagInfoMsgDlg: false,
      flagErrMsgDlg: false,
      signaturePadDlg: false,
      // agree_tnc: false,
      signature: "",

      snackbarSignature: {
        visible: false,
        icon: "mdi-information",
        title: "Peringatan",
        text: "Pastikan tandatangan anda diisi sebelum meneruskan proses selajutnya",
      },

      msgERR: {
        run: 0,
        nric: "",
        message: "",
      },

      msgINFO: {
        run: 0,
        nric: "",
        message: "",
      },
    };
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "currentUserRoles",
      "formSession",
    ]),

    agree_tnc: {
      get: function () {
        return this.formSession?.sign?.agree_tnc ?? false;
      },

      set: function (val) {
        this.formSession.sign.agree_tnc = val;
        //nfh-console.log('COMPUTED this.formSession.sign.agree_tnc, val: ', this.formSession.sign.agree_tnc, val);
      }
    },

    stateProceed() {
      // return !(this.agree_tnc && this.signature);
      return !(this.agree_tnc && (this.signatureOptional || this.signature));
    },

    signatureOptional() {
      return !!(this.propSignatureOptional);
    },
  },

  methods: {
    proceedButtonEnabler() {
      // this.proceedButton = (this.agree_tnc && this.signature);
      this.proceedButton = (this.agree_tnc && (this.signatureOptional || this.signature));
    },

    undo() {
      this.$refs.signaturePad.undoSignature();
    },

    save() {
      const { data } = this.$refs.signaturePad.saveSignature();
      this.signature = data;
      this.proceedButtonEnabler();
      this.signaturePadDlg = false;
      // this.$store.dispatch(SET_FORM_SESSION, this.signature)
      //   .then((resp) => {
      //   });
    },

    backStep() {
      this.$emit("onBack");
    },

    async proceedStep() {
      this.$emit("onProceed", this.signature);
    },

    async saveSignature() {
      await this.$store.dispatch(UPDATE_AGREE, { id: this.propFormId, });

      if (this.signature) {
        const _context = {
          id: this.propFormId,
          token: this.signature,
        };

        this.isLoading = true;
        this.openOverlay();

        setTimeout(() => {
          this.$store.dispatch(CREATE_SIGNATURE_SESSION, _context)
            .then(async (resp) => {
              if (resp.success == true) {
                this.proceedStep();
              }
              else {
                this.promptError(resp?.message);
                this.isLoading = false;
              }

              this.closeOverlay();
            })
            .catch((err) => {
              this.isLoading = false;
              this.promptError(err?.message);
              this.closeOverlay();
            })
            .finally(() => {
              this.isLoading = false;
              this.closeOverlay();
            });
        }, 500);
      }
      else if (this.signatureOptional) {
        this.proceedStep();
      }
      else {
        this.closeOverlay();
        this.snackbarSignature.visible = true;
      }
    },

    promptError(message) {
      this.msgERR.message = errorMessages(message);
      this.openErrMsgDialog();
    },

    promptInfo(message) {
      this.msgINFO.message = message;
      this.openInfoMsgDialog();
    },

    openInfoMsgDialog() {
      this.flagInfoMsgDlg = true;
    },

    openErrMsgDialog() {
      this.flagErrMsgDlg = true;
    },

    openOverlay() {
      this.overlay = true;
    },

    closeOverlay() {
      setTimeout(() => {
        this.overlay = false;
      }, 2000);
    },
  },

  async mounted() {
    //nfh-console.log(" IN:MOUNTED");
    this.closeOverlay();
    //nfh-console.log("OUT:MOUNTED");
  },
}
</script>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";

.v-text-field--outlined {
  font-size: 11pt;
}

.v-text-field--outlined label {
  font-size: 11pt;
}

.ucase-text input {
  text-transform: uppercase;
}

.lcase-text input {
  text-transform: lowercase;
}

.checbox-decoration {
  margin: 0px;
}

.checbox-decoration .v-label {
  margin: 0px;
  font-size: 12px;
}

.snackbar-title {
  font-size: 14px;
  font-weight: 600;
}

.snackbar-subtitle {
  font-size: 12px;
}

.input-large {
  font-size: large;
}

#signaturePad {
  border: double 3px transparent;
  border-radius: 10px;
  border-width: 1;
  border-color: #000;
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>