var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-col",
        {
          staticClass: "py-0",
          attrs: { cols: "12", sm: "12", md: "12", lg: "12", xl: "12" },
        },
        [
          _c("div", { staticClass: "card card-custom" }, [
            _c(
              "div",
              { staticClass: "stepper" },
              [
                _c(
                  "v-stepper",
                  {
                    attrs: {
                      rounded: "lg",
                      elevation: "2",
                      "alt-labels": "",
                      tile: "",
                    },
                    model: {
                      value: _vm.applicationForm,
                      callback: function ($$v) {
                        _vm.applicationForm = $$v
                      },
                      expression: "applicationForm",
                    },
                  },
                  [
                    _c(
                      "v-stepper-header",
                      [
                        _c(
                          "v-stepper-step",
                          {
                            attrs: {
                              step: "1",
                              complete: _vm.applicationForm > 1,
                              editable: _vm.stepsBypassable,
                              color: _vm.stepperColor1,
                            },
                          },
                          [
                            _c(
                              "p",
                              { staticClass: "font-weight-bold font-size-sm" },
                              [_vm._v(" PERIBADI ")]
                            ),
                          ]
                        ),
                        _c("v-divider", { staticClass: "pt-4" }),
                        _c(
                          "v-stepper-step",
                          {
                            attrs: {
                              step: "2",
                              complete: _vm.applicationForm > 2,
                              editable: _vm.stepsBypassable,
                              color: _vm.stepperColor2,
                            },
                          },
                          [
                            _c(
                              "p",
                              { staticClass: "font-weight-bold font-size-sm" },
                              [_vm._v(" PEKERJAAN ")]
                            ),
                          ]
                        ),
                        _c("v-divider", { staticClass: "pt-4" }),
                        _c(
                          "v-stepper-step",
                          {
                            attrs: {
                              step: "3",
                              complete: _vm.applicationForm > 3,
                              editable: _vm.stepsBypassable,
                              color: _vm.stepperColor3,
                            },
                          },
                          [
                            _c(
                              "p",
                              { staticClass: "font-weight-bold font-size-sm" },
                              [_vm._v(" WARIS ")]
                            ),
                          ]
                        ),
                        _c("v-divider", { staticClass: "pt-4" }),
                        _c(
                          "v-stepper-step",
                          {
                            attrs: {
                              step: "4",
                              complete: _vm.applicationForm > 4,
                              editable: _vm.stepsBypassable,
                              color: _vm.stepperColor4,
                            },
                          },
                          [
                            _c(
                              "p",
                              { staticClass: "font-weight-bold font-size-sm" },
                              [_vm._v(" DOKUMEN ")]
                            ),
                          ]
                        ),
                        _c("v-divider", { staticClass: "pt-4" }),
                        _c(
                          "v-stepper-step",
                          {
                            attrs: {
                              step: "5",
                              complete: _vm.applicationForm > 5,
                              editable: _vm.stepsBypassable,
                              color: _vm.stepperColor5,
                            },
                          },
                          [
                            _c(
                              "p",
                              { staticClass: "font-weight-bold font-size-sm" },
                              [_vm._v(" TANDATANGAN ")]
                            ),
                          ]
                        ),
                        _c("v-divider", { staticClass: "pt-4" }),
                        _c(
                          "v-stepper-step",
                          {
                            attrs: {
                              step: "6",
                              complete: _vm.applicationForm > 6,
                              color: _vm.stepperColor6,
                            },
                          },
                          [
                            _c(
                              "p",
                              { staticClass: "font-weight-bold font-size-sm" },
                              [_vm._v(" SEMAK ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-stepper-items",
                      [
                        _c(
                          "v-stepper-content",
                          { attrs: { step: "1" } },
                          [
                            _c("PersonalDetails", {
                              on: {
                                successProceed: function ($event) {
                                  return _vm.toNextForm()
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-stepper-content",
                          { attrs: { step: "2" } },
                          [
                            _c("EmploymentDetails", {
                              on: {
                                onBack: function ($event) {
                                  return _vm.toPrevForm()
                                },
                                successProceed: function ($event) {
                                  return _vm.toNextForm()
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-stepper-content",
                          { attrs: { step: "3" } },
                          [
                            _c("HeirDetails", {
                              on: {
                                onBack: function ($event) {
                                  return _vm.toPrevForm()
                                },
                                successProceed: function ($event) {
                                  return _vm.toNextForm()
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-stepper-content",
                          { attrs: { step: "4" } },
                          [
                            _c("DocumentForm", {
                              attrs: {
                                propId: _vm.currentId,
                                propForm: _vm.formSession,
                                propRoles: _vm.currentUserRoles,
                                propDocList: _vm.checklistFull,
                                propUseForm: true,
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "pr-4 pl-2 pb-2 pt-10",
                                attrs: { align: "right" },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { text: "", color: "grey darken-1" },
                                    on: { click: _vm.toPrevForm },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { left: "", dark: "" } },
                                      [_vm._v(" mdi-chevron-left ")]
                                    ),
                                    _vm._v(" Kembali "),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ml-4",
                                    attrs: {
                                      color: "primary",
                                      loading: _vm.isLoadingStep4Session,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onSubmitStep4()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(" Teruskan "),
                                    _c(
                                      "v-icon",
                                      { attrs: { right: "", dark: "" } },
                                      [_vm._v(" mdi-chevron-right ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-stepper-content",
                          { attrs: { step: "5" } },
                          [
                            _c("Signature", {
                              attrs: {
                                propFormId: _vm.formId,
                                propSignatureOptional: false,
                              },
                              on: {
                                onBack: function ($event) {
                                  return _vm.toPrevForm()
                                },
                                onProceed: function ($event) {
                                  return _vm.onSubmitStep5A()
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-stepper-content",
                          { attrs: { step: "6" } },
                          [
                            _c("Summary", {
                              on: {
                                onBack: function ($event) {
                                  return _vm.toPrevForm()
                                },
                                onProceed: function ($event) {
                                  return _vm.onSubmitAndRequestAssistance()
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("AssistanceDialog", {
                  attrs: {
                    title: _vm.assistDialog.title,
                    content: _vm.assistDialog.content,
                    backButton: _vm.assistDialog.backButton,
                    proceedButton: _vm.assistDialog.proceedButton,
                    showBackButton: _vm.assistDialog.showBackButton,
                    showProceedButton: _vm.assistDialog.showProceedButton,
                  },
                  on: { onProceed: _vm.onSubmitAndRequestAssistance },
                  model: {
                    value: _vm.openAssistanceDialog,
                    callback: function ($$v) {
                      _vm.openAssistanceDialog = $$v
                    },
                    expression: "openAssistanceDialog",
                  },
                }),
                _c("AssistanceDialog", {
                  attrs: {
                    title: _vm.assistDialog2.title,
                    content: _vm.assistDialog2.content,
                    backButton: _vm.assistDialog2.backButton,
                    proceedButton: _vm.assistDialog2.proceedButton,
                    showBackButton: _vm.assistDialog2.showBackButton,
                    showProceedButton: _vm.assistDialog2.showProceedButton,
                  },
                  on: { onProceed: _vm.doneNotifySalesCS },
                  model: {
                    value: _vm.openAssistanceDialog2,
                    callback: function ($$v) {
                      _vm.openAssistanceDialog2 = $$v
                    },
                    expression: "openAssistanceDialog2",
                  },
                }),
                _c("ConfirmationDialog", {
                  attrs: {
                    title: _vm.confirmDialog.title,
                    content: _vm.confirmDialog.content,
                    backButton: _vm.confirmDialog.backButton,
                    proceedButton: _vm.confirmDialog.proceedButton,
                    delayOnProceed: _vm.confirmDialog.delayOnProceed,
                    showBackButton: _vm.confirmDialog.showBackButton,
                    showProceedButton: _vm.confirmDialog.showProceedButton,
                  },
                  on: {
                    onCancel: _vm.confirmDialog.cancel,
                    onProceed: _vm.confirmDialog.proceed,
                  },
                  model: {
                    value: _vm.confirmDialog.show,
                    callback: function ($$v) {
                      _vm.$set(_vm.confirmDialog, "show", $$v)
                    },
                    expression: "confirmDialog.show",
                  },
                }),
                _c("ErrorMsgDialog", {
                  attrs: {
                    title: "Ralat",
                    proceedButton: "OK",
                    content: _vm.msgERR.message,
                    showProceedButton: true,
                    showBackButton: false,
                    delayOnProceed: false,
                    isTypeError: true,
                  },
                  on: {
                    onProceed: function ($event) {
                      _vm.msgERR.show = false
                    },
                  },
                  model: {
                    value: _vm.msgERR.show,
                    callback: function ($$v) {
                      _vm.$set(_vm.msgERR, "show", $$v)
                    },
                    expression: "msgERR.show",
                  },
                }),
                _c("InfoMsgDialog", {
                  attrs: {
                    title: "Info",
                    proceedButton: "OK",
                    content: _vm.msgINFO.message,
                    showProceedButton: true,
                    showBackButton: false,
                    delayOnProceed: false,
                  },
                  model: {
                    value: _vm.openInfoMsgDialog,
                    callback: function ($$v) {
                      _vm.openInfoMsgDialog = $$v
                    },
                    expression: "openInfoMsgDialog",
                  },
                }),
                _c("NotificationDialog", {
                  attrs: {
                    title: "Permohonan Baru",
                    backButton: "Tolak",
                    proceedButton: "Terima",
                    showBackButton: true,
                  },
                  model: {
                    value: _vm.openNotificationDialog,
                    callback: function ($$v) {
                      _vm.openNotificationDialog = $$v
                    },
                    expression: "openNotificationDialog",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "v-overlay",
            { attrs: { value: _vm.overlay } },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", size: "64" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }