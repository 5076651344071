<template
>
  <div
  >
    <v-card
      class="pa-2"
      height="auto"
      elevation="0"
    >
      <v-container
        fluid
      >
        <div
          v-for="(head) in summaryData"
          :key="head.id"
        >
          <v-divider/>
          <div
            class="mb-10"
          >
            <h4
              class="primary--text font-weight-bold"
            >
              {{ head.header }}
            </h4>
          </div>

          <v-row
            v-for="(content) in head.data"
            :key="content.id"
          >
            <v-flex
              xs4 sm4 md3
            >
              <v-col
                class="col pt-0 pb-0"
              >
                <p
                  class="subtitle-1"
                >
                  {{ content.label }}
                </p>
              </v-col>
            </v-flex>

            <v-flex
              xs8
              sm8
              md9
            >
              <v-col
                class="col pt-0 pb-0"
              >
                <div
                  v-if="String(content.value).substring(0, 10) == 'data:image'"
                >
                  <v-card
                    max-width="300"
                    elevation="0"
                    tile
                  >
                    <v-img
                      contain
                      :src="content.value"
                      height="150"
                      width="180"
                    />
                  </v-card>
                </div>
                <div
                  v-else-if="Array.isArray(content.value)"
                >
                  <ol>
                    <li
                      v-for="(listing) in content.value"
                      :key="listing.value"
                    >
                      {{ listing.value }}
                    </li>
                  </ol>
                </div>
                <div
                  v-else
                >
                  <p
                    class="subtitle-1 font-weight-bold mb-0"
                    style="white-space: pre-line; padding-bottom: 12px;"
                  >
                    {{ content.value }}
                  </p>
                </div>
              </v-col>
            </v-flex>
          </v-row>
        </div>
      </v-container>
    </v-card>

    <div
      class="pl-2 pb-2 pt-10"
      align="right"
    >
      <v-btn
        text
        @click="backStep()"
      >
        <v-icon
          left
          dark
        >
          mdi-chevron-left
        </v-icon>
        Kembali
      </v-btn>
      <v-btn
        class="ml-4"
        color="primary"
        :loading="isLoading"
        :disabled="proceedState"
        @click="proceedStep()"
      >
        Hantar
        <v-icon
          right dark
        >
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </div>

    <v-overlay
      :value="overlay"
    >
      <v-progress-circular
        indeterminate size="64"
      ></v-progress-circular>
    </v-overlay>

  </div>
</template>

<script>
import {
  errorMessages,
  formatAddress,
  formatNumber,
  formatAmount,
  formatPercentage,
} from "@/core/services/helper.service";

import {
  mapGetters,
} from "vuex";

import dayjs from "dayjs";
import KTUtil from "@/assets/js/components/util";

export default {
  name: "Summary",

  watch: {
    //
  },

  props: {
    propData: {
      type: Array,
      default: () => {
        return [];
      },
    }
  },

  data() {
    return {
      stepNo: 0,

      city: [],
      race: [],
      banks: [],
      gender: [],
      salute: [],
      states: [],
      religion: [],
      relations: [],
      employerTypes: [],
      maritalStatus: [],
      houseOwnership: [],
      educationLevel: [],
      occupationTypes: [],

      overlay: false,
      isLoading: false,
      proceedButton: true,
      flagInfoMsgDlg: false,

      msgERR: {
        run: 0,
        nric: "",
        message: "",
      },

      msgINFO: {
        run: 0,
        nric: "",
        message: "",
      },
    };
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "currentUserRoles",
      "formSession",
      "checklistMasters",
      "checklistFull",
      "calculatedResult",
      "checklistStore",
      "checklistStatStore",
      "appId",
      "appId_dev",
    ]),

    proceedState() {
      return !this.proceedButton;
    },

    currentStep() {
      return this.stepNo;
    },

    userAddress1() {
      return formatAddress(
        this?.formSession?.home_unit,
        this?.formSession?.home_street,
        this?.formSession?.home_line3,
        this?.formSession?.home_line4,
        this?.formSession?.home_postcode,
        this?.formSession?.home_city_id,
        this?.formSession?.home_state_id,
      );
    },

    mailingAddress1() {
      return formatAddress(
        this?.formSession?.mailing_unit,
        this?.formSession?.mailing_street,
        this?.formSession?.mailing_line3,
        this?.formSession?.mailing_line4,
        this?.formSession?.mailing_postcode,
        this?.formSession?.mailing_city_id,
        this?.formSession?.mailing_state_id,
      );
    },

    employmentAddress1() {
      return formatAddress(
        this?.formSession?.emp?.office_unit,
        this?.formSession?.emp?.office_street,
        this?.formSession?.emp?.office_line3,
        this?.formSession?.emp?.office_line4,
        this?.formSession?.emp?.office_postcode,
        this?.formSession?.emp?.office_city_id,
        this?.formSession?.emp?.office_state_id,
      );
    },

    heirAddress1() {
      return formatAddress(
        this?.formSession?.heir?.heir_unit,
        this?.formSession?.heir?.heir_street,
        this?.formSession?.heir?.heir_line3,
        this?.formSession?.heir?.heir_line4,
        this?.formSession?.heir?.heir_postcode,
        this?.formSession?.heir?.heir_city_id,
        this?.formSession?.heir?.heir_state_id,
      );
    },

    listDocUploadDone() {
      const _ret = [];

      this.checklistStore.forEach(_el => {
        if (_el.media_url) {
          _ret.push({
            label: "",
            value: _el.name,
          });
        }
      });
      return _ret;
    },

    listDocUploadPending() {
      const _ret = [];

      this.checklistStore.forEach(_el => {
        if (_el.mandatory && _el.media_url == "") {
          _ret.push({
            label: "",
            value: _el.name,
          });
        }
      });
      return _ret;
    },

    summaryData() {
      return [
        {
          header: "Maklumat Diri",
          data: [
            {
              label: "No. Rujukan Permohonan",
              value: this?.formSession?.refno ?? "",
            }, {
              label: "No. Kad Pengenalan",
              value: this?.currentUser?.nric_display ?? "",
            }, {
              label: "Nama Pemohon",
              value: this?.formSession?.full_name ?? "",
            }, {
              label: "Gelaran",
              value: this?.formSession?.salute_id ? this?.salute?.find((_) => _.id == this.formSession.salute_id)?.nameMS : "",
            }, {
              label: "E-mel",
              value: this?.formSession?.email ?? "",
            }, {
              label: "Tarikh Lahir",
              value: this.formatDate(this?.formSession?.birth_date) ?? "",
            }, {
              label: "Jantina",
              value: this?.formSession?.gender_id ? this?.gender?.find((_) => _.id == this?.formSession?.gender_id)?.nameMS : "-- TIDAK DINYATAKAN --",
            }, {
              label: "Bangsa",
              value: this?.formSession?.race_id ? this?.race?.find((_) => _.id == this?.formSession?.race_id)?.nameMS : "-- TIDAK DINYATAKAN --",
            }, {
              label: "Agama",
              value: this?.formSession?.religion_id ? this?.religion?.find((_) => _.id == this?.formSession?.religion_id)?.nameMS : "-- TIDAK DINYATAKAN --",
            }, {
              label: "Taraf Pendidikan",
              value: this?.formSession?.education_level_id ? this?.educationLevel?.find((_) => _.id == this?.formSession?.education_level_id)?.nameMS : "-- TIDAK DINYATAKAN --"
            }, {
              label: "Nama Ibu",
              value: this?.formSession?.mother_name ?? "-- TIDAK DINYATAKAN --",
            }, {
              label: "Status Perkahwinan",
              value: this?.formSession?.marital_status_id ? this?.maritalStatus?.find((_) => _.id == this?.formSession?.marital_status_id)?.nameMS : "-- TIDAK DINYATAKAN --",
            }, {
              label: "Bilangan Anak",
              value: this?.formSession?.children > 0 ? `${this?.formSession?.children} orang` : 'Tiada',
            }, {
              label: "Bank",
              value: this?.formSession?.banks_id ? this?.banks?.find((_) => _.id == this?.formSession?.banks_id)?.name : "-- TIDAK DINYATAKAN --",
            }, {
              label: "No. Akaun Bank",
              value: this?.formSession?.bank_acc ?? "-- TIDAK DINYATAKAN --",
            }, {
              label: "Alamat Rumah",
              value: this?.userAddress1 ?? "-- TIDAK DINYATAKAN --",
            }, {
              label: "Alamat Surat Menyurat" ?? "",
              value: this?.mailingAddress1 ?? "-- TIDAK DINYATAKAN --",
            }, {
              label: "Telefon Bimbit",
              value: this?.formSession?.phone_num ?? "-- TIDAK DINYATAKAN --",
            }, {
              label: "Telefon Rumah",
              value: this?.formSession?.home_num ?? "-- TIDAK DINYATAKAN --",
            }, {
              label: "Telefon Pejabat",
              value: this?.formSession?.user_office_num ?? "-- TIDAK DINYATAKAN --",
            },
          ],
        },
        {
          header: "Maklumat Pekerjaan",
          data: [
            {
              label: "Nama Majikan",
              value: this?.formSession?.emp?.employer_name ?? "-- TIDAK DINYATAKAN --",
            }, {
              label: "Pekerjaan / Jawatan",
              value: this?.formSession?.emp?.occupation ?? "-- TIDAK DINYATAKAN --",
            }, {
              label: "Bahagian / Unit",
              value: this?.formSession?.emp?.department ?? "-- TIDAK DINYATAKAN --",
            }, {
              label: "Alamat Majikan",
              value: this?.employmentAddress1 ?? "-- TIDAK DINYATAKAN --",
            }, {
              label: "No. Telefon Pejabat",
              value: this?.formSession?.emp?.office_num ?? "-- TIDAK DINYATAKAN --",
            }, {
              label: "No. Faks",
              value: this?.formSession?.emp?.office_fax ?? "-- TIDAK DINYATAKAN --",
            }, {
              label: "E-mel Majikan",
              value: this?.formSession?.emp?.employer_email ?? "-- TIDAK DINYATAKAN --",
            }, {
              label: "Pendapatan Sebulan",
              value: formatAmount(this?.formSession?.emp?.salary) ?? "-- TIDAK DINYATAKAN --",
            }, {
              label: "Tarikh Mula Bekerja",
              value: this.formatDate(this?.formSession?.emp?.commencement_date) ?? "-- TIDAK DINYATAKAN --",
            }, {
              label: "Tahun Perkhidmatan",
              value: this?.formSession?.emp?.year_of_service > 0 ? `${this?.formSession?.emp?.year_of_service} tahun` : '0',
            },
          ],
        },
        {
          header: "Maklumat Waris",
          data: [
            {
              label: "Nama Waris",
              value: this?.formSession?.heir?.heir_name ?? "-- TIDAK DINYATAKAN --",
            }, {
              label: "No. Kad Pengenalan",
              value: this?.formSession?.heir?.heir_nric ?? "-- TIDAK DINYATAKAN --",
            }, {
              label: "Tarikh Lahir",
              value: this.formatDate(this?.formSession?.heir?.heir_birth_date) ?? "-- TIDAK DINYATAKAN --",
            }, {
              label: "Jantina",
              value: this?.formSession?.heir?.heir_gender_id ? this?.gender?.find((_) => _.id == this?.formSession?.heir?.heir_gender_id)?.nameMS : "-- TIDAK DINYATAKAN --",
            }, {
              label: "Bangsa",
              value: this?.formSession?.heir?.heir_race_id ? this?.race?.find((_) => _.id == this?.formSession?.heir?.heir_race_id)?.nameMS : "-- TIDAK DINYATAKAN --",
            }, {
              label: "Agama",
              value: this?.formSession?.heir?.heir_religion_id ? this?.religion?.find((_) => _.id == this?.formSession?.heir?.heir_religion_id)?.nameMS : "-- TIDAK DINYATAKAN --",
            }, {
              label: "Alamat Waris",
              value: this?.heirAddress1 ?? "-- TIDAK DINYATAKAN --",
            }, {
              label: "Hubungan",
              value: this?.formSession?.heir?.relationship_id ? this?.relations?.find((_) => _.id == this?.formSession?.heir?.relationship_id)?.nameMS : "-- TIDAK DINYATAKAN --",
            }, {
              label: "Telefon Bimbit",
              value: this?.formSession?.heir?.heir_phone_num ?? "-- TIDAK DINYATAKAN --",
            }, {
              label: "Telefon Rumah",
              value: this?.formSession?.heir?.heir_home_num ?? "-- TIDAK DINYATAKAN --",
            }, {
              label: "Telefon Pejabat",
              value: this?.formSession?.heir?.heir_office_num ?? "-- TIDAK DINYATAKAN --",
            },
          ],
        },
        {
          header: "Maklumat Permohonan Pembiayaan",
          data: [
            {
              label: "Jumlah Pembiayaan Dipohon",
              value: formatAmount(this?.formSession?.financing_req_amount) ?? "-- TIDAK DINYATAKAN --",
            },
            {
              label: "Tempoh Pembiayaan Dipilih",
              value: (this?.formSession?.financing_req_tenure) ? formatNumber(this?.formSession?.financing_req_tenure) + " bulan" : "-- TIDAK DIKIRA --",
            },
            {
              label: "Kadar Keuntungan Pembiayaan",
              value: formatPercentage(this?.formSession?.financing_req_rate) ?? "-- TIDAK DIKIRA --",
            },
            {
              label: "Anggaran Amaun Ansuran Bulanan",
              value: formatAmount(this?.formSession?.financing_req_inst) ?? "-- TIDAK DIKIRA --",
            },
          ],
        },
        {
          header: "Dokumen",
          data: [
            {
              label: "Mohon Bantuan Khidmat Pelanggan",
              value: (this?.formSession?.sign?.assistable) ? "Ya" : "Tidak",
            },
            {
              label: "Dokumen Telah Dimuat-naik",
              value: (this?.checklistStatStore?.filled < 1) ? "Tiada" : this.listDocUploadDone,
            },
            {
              label: "Dokumen Belum Dimuat-naik",
              value: (this?.checklistStatStore?.remain < 1) ? "Tiada" : this.listDocUploadPending,
            },
          ],
        },
        {
          header: "Persetujuan",
          data: [
            {
              label: "Pengakuan",
              value: "Saya bersetuju untuk memberi kebenaran kepada KOWAMAS mengatur/mengurus dan menyelesaikan mana-mana hutang sedia ada termasuk baki tertunggak kad kredit untuk tujuan kelulusan permohonan ini dan mengaku:",
            },
            {
              label: "",
              value: [
                {
                  label: "",
                  value: "Bahawa segala maklumat yang diberikan dalam permohonan ini adalah benar dan betul dan saya tidak menyembunyikan sebarang fakta penting.",
                },
                {
                  label: "",
                  value: "Bahawa saya bukan seorang yang bankrap (muflis) dan tiada sebarang tindakan undang-undang ke atas diri saya.",
                },
                {
                  label: "",
                  value: "Bahawa KOWAMAS mempunyai hak mutlak untuk tidak meluluskan permohonan ini tanpa sebarang sebab.",
                },
                {
                  label: "",
                  value: "Dan sekiranya didapati sebarang maklumat yang tidak betul di dalam permohonan ini setelah pembiayaan ini diluluskan, KOWAMAS mempunyai hak untuk mengambil sebarang tindakan yang difikirkan patut dan sesuai ke atas diri saya.",
                },
              ],
            },
            {
              label: "Persetujuan",
              value: (this?.formSession?.sign?.agree_tnc ? "SETUJU" : "TIDAK SETUJU") ?? "-- TIDAK DITANDA --",
            },
            {
              label: "Tandatangan",
              value: this?.formSession?.sign?.signature ?? "-- TIDAK DITANDATANGANI --",
            },
          ],
        },
      ];
    },

  },

  methods: {
    formatDate(date) {
      if (date) {
        return dayjs(date).format('DD/MM/YYYY')
      }
    },

    backStep() {
      this.$emit("onBack");
    },

    async proceedStep() {
      this.$emit("onProceed");
    },

    // async create_app() {
    //   if (this.formSession) {
    //     this.openOverlay();
    //     this.isLoading = true;
    //     const _context = {
    //       form_session_id: this.formSession.id,
    //       from: this.$route.path,
    //       next: "/edit-form",
    //     };

    //     await this.$store.dispatch(CREATE_APPLICATION, _context)
    //       .then(async (resp) => {
    //         if (resp.data.success == true) {
    //           this.closeOverlay();
    //           this.isLoading = false;
    //           this.$router.push({
    //             name: "EditForm",
    //             params: {
    //               id: resp.data.data.application.id,
    //               tabIndex: 3,
    //             },
    //           });
    //         } else {
    //           this.isLoading = false;
    //           this.promptError(resp?.data?.message);
    //           this.closeOverlay();
    //         }
    //       })
    //       .catch((err) => {
    //         this.isLoading = false;
    //         this.promptError(err?.message);
    //         this.closeOverlay();
    //       })
    //       .finally(() => {
    //         this.isLoading = false;
    //         this.closeOverlay();
    //       });
    //   }
    // },

    openInfoMsgDialog() {
      this.flagInfoMsgDlg = true;
    },

    promptError(message) {
      this.msgERR.message = errorMessages(message);
      this.openErrorMsgDialog = true;
    },

    promptInfo(message) {
      this.msgINFO.message = message;
      this.openInfoMsgDialog();
    },

    openOverlay() {
      this.overlay = true;
    },

    closeOverlay() {
      setTimeout(() => {
        this.overlay = false;
      }, 2000);
    },
  },

  async beforeCreate() {
    //nfh-console.log(" IN:BEFORECREATE");
    //nfh-console.log('BEFORECREATE formSession: ', this.formSession);
    //nfh-console.log("OUT:BEFORECREATE");
  },

  async created() {
    //nfh-console.log(" IN:CREATED");
    //nfh-console.log('CREATED formSession: ', this.formSession);
    this.city            = await JSON.parse(localStorage.getItem("cty"));
    this.race            = await JSON.parse(localStorage.getItem("rce"));
    this.banks           = await JSON.parse(localStorage.getItem("bnk"));
    this.gender          = await JSON.parse(localStorage.getItem("gdr"));
    this.salute          = await JSON.parse(localStorage.getItem("sal"));
    this.states          = await JSON.parse(localStorage.getItem("stt"));
    this.religion        = await JSON.parse(localStorage.getItem("rgn"));
    this.relations       = await JSON.parse(localStorage.getItem("rel"));
    this.employerTypes   = await JSON.parse(localStorage.getItem("emp"));
    this.maritalStatus   = await JSON.parse(localStorage.getItem("mry"));
    this.houseOwnership  = await JSON.parse(localStorage.getItem("own"));
    this.educationLevel  = await JSON.parse(localStorage.getItem("edu"));
    this.occupationTypes = await JSON.parse(localStorage.getItem("occ"));
    //nfh-console.log('CREATED formSession: ', this.formSession);
    //nfh-console.log("OUT:CREATED");
  },

  async mounted() {
    //nfh-console.log(" IN:MOUNTED");
    this.closeOverlay();
    //nfh-console.log("OUT:MOUNTED");
  },
}
</script>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";

.v-text-field--outlined {
  font-size: 11pt;
}

.v-text-field--outlined label {
  font-size: 11pt;
}

.ucase-text input {
  text-transform: uppercase;
}

.lcase-text input {
  text-transform: lowercase;
}

.checbox-decoration {
  margin: 0px;
}

.checbox-decoration .v-label {
  margin: 0px;
  font-size: 12px;
}

.snackbar-title {
  font-size: 14px;
  font-weight: 600;
}

.snackbar-subtitle {
  font-size: 12px;
}

.input-large {
  font-size: large;
}

#signature {
  border: double 3px transparent;
  border-radius: 10px;
  border-width: 1;
  border-color: #000;
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>